<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        closeOnSelect?: boolean;
        multiple?: boolean;
        formKey?: string;
        collection: _VTI_TYPE_TimeActivityCollection | null;
    }
    import TimeActivity from '../../../app/planning/models/TimeActivity'
    
    import { FormInputData } from '~~/composables/useFormInput'
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  closeOnSelect: { type: Boolean, default: true },
  multiple: { type: Boolean, default: false },
  formKey: { default: 'timeActivities' },
  collection: null
});
    const inputData: FormInputData = useFormInput(props);
    const select = ref(null);
    const setValue = (value: TimeActivity | Array<TimeActivity>) => {
        inputData.form.set(props.formKey, value);
    };
</script>

<template>
    <FormElement
        :input-data="inputData"
        @label-clicked="select?.show()"
    >
        <SelectTimeActivity
            ref="select"
            :initial-value="inputData.model.value"
            :collection="props.collection"
            :close-on-select="closeOnSelect"
            :multiple="multiple"
            @selected="setValue"
        ></SelectTimeActivity>
    </FormElement>
</template>
