import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoiceTemplateRelationships = {};

type InvoiceTemplateAttributes = {
    title: string;
    default: boolean;
    locale: string;
    documentType: string;
    logoStyle: object;
    tableColumns: object;
    tableStyle: object;
    headerImageUrl: string | null;
    headerImageHeight: number | null;
    headerImageWidth: number | null;
    footerImageUrl: string | null;
    footerImageHeight: number | null;
    footerImageWidth: number | null;
    footerText: string | null;
};

type InvoiceTemplateMeta = {
    companyLogo: {
        width: number;
        height: number;
    };
    tableColumns: {
        keys: string[];
        attributes: {
            [key: string]: {
                label: string;
                width: number;
                align: string;
            };
        };
    };
};

export default class InvoiceTemplateSchema extends BaseSchema<InvoiceTemplateAttributes, InvoiceTemplateMeta, InvoiceTemplateRelationships> {
    protected type: ModelType = ModelType.INVOICE_TEMPLATES;
}
