<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import TenantEndpoint from '~~/app/auth/endpoints/TenantEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';

    const { modules } = useModules();
    const { setTenants } = useAuthStore();
    const { authed, tenant, tenants, isImpersonating } = storeToRefs(useAuthStore());
    const logout = useLogout();
    const themeStore = useThemeStore();
    const { theme } = storeToRefs(themeStore);
    const userSubmenu = ref(null);

    const saveLocale = (newLocale: string) => {
        useSaveLocale(newLocale);
        userSubmenu.value?.hide();
    };

    onMounted(async () => {
        if (!tenants.value) {
            const endpoint = EndpointFactory.make(ModelType.TENANTS) as TenantEndpoint;
            const response = await endpoint.index();
            if (response.data) {
                setTenants(response.data);
            }
        }
    });
</script>

<template>
    <Submenu
        v-if="authed"
        ref="userSubmenu"
        position="right-0"
        without-max-height
        overlay
    >
        <template #default="slotProps">
            <div
                class="relative flex items-center space-x-3 transition-all rounded-full cursor-pointer bg-neutral-100 dark:bg-black dark:hover:bg-blue-900 md:py-2 md:pl-2 md:pr-4 hover:bg-neutral-200"
                @click.prevent="slotProps.toggle()"
            >
                <div
                    class="w-8 h-8 bg-blue-400 bg-center bg-cover rounded-full"
                    :style="`background-image: url(${authed?.avatar})`"
                ></div>
                <div class="relative flex-col hidden space-y-1 md:flex">
                    <span class="text-sm leading-none dark:text-gray-200">
                        {{ authed?.fullName }}
                    </span>
                    <div class="flex items-center space-x-1">
                        <div
                            v-if="isImpersonating"
                            class="h-[10px] w-[10px] bg-red-600 rounded-full"
                        ></div>
                        <span class="text-xs leading-none text-gray-600 dark:text-gray-400">
                            {{ $t(`auth.user_role.${authed?.role}`) }}
                        </span>
                    </div>
                </div>
                <div class="hidden md:block">
                    <i
                        class="ml-2 text-sm text-gray-400 transition duration-100 rotate-0 fa-solid dark:text-gray-300 fa-chevron-down"
                        :class="{ 'rotate-180': slotProps.isDisplayed }"
                    />
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <div :class="$theme('submenu.items_container')">
                    <div :class="$theme('submenu.items')">
                        <!-- TODO: Re-activate once the dark mode is done. -->
                        <!-- <a href="#" :class="$theme('submenu.item')" @click.prevent="themeStore.toggleTheme(); userSubmenu?.hide()">
                            <i class="fa-solid" :class="[theme == 'dark' ? 'fa-moon' : 'fa-sun-bright', $theme('submenu.item_icon')]" />
                            <span>{{ $t(`layout.header.user_submenu.switch_theme_${theme}`) }}</span>
                        </a> -->
                        <LanguageSwitcher @locale-changed="saveLocale">
                            <template #default="slotProps">
                                <a
                                    href="#"
                                    :class="$theme('submenu.item')"
                                    @click.prevent="slotProps.toggle()"
                                >
                                    <i
                                        class="fa-regular fa-globe"
                                        :class="$theme('submenu.item_icon')"
                                    />
                                    <span>{{ $t('layout.header.user_submenu.change_locale') }}</span>
                                </a>
                            </template>
                        </LanguageSwitcher>
                        <a
                            href="#"
                            :class="$theme('submenu.item')"
                            @click.prevent="
                                useEvent('auth:login-qrcode-modal:open');
                                userSubmenu?.hide();
                            "
                        >
                            <i
                                class="fa-regular fa-qrcode"
                                :class="$theme('submenu.item_icon')"
                            />
                            <span>{{ $t('layout.header.user_submenu.login-qrcode') }}</span>
                        </a>
                        <a
                            href="#"
                            :class="$theme('submenu.item')"
                            @click.prevent="
                                useEvent('app:search:open');
                                userSubmenu?.hide();
                            "
                        >
                            <i
                                class="fa-regular fa-search"
                                :class="$theme('submenu.item_icon')"
                            />
                            <span>{{ $t('layout.header.user_submenu.search') }}</span>
                        </a>
                        <a
                            href="#"
                            :class="$theme('submenu.item')"
                            @click.prevent="logout()"
                        >
                            <i
                                class="fa-regular fa-arrow-right-from-bracket"
                                :class="$theme('submenu.item_icon')"
                            />
                            <span>{{ $t('layout.header.user_submenu.logout') }}</span>
                        </a>
                    </div>

                    <div :class="$theme('submenu.items')">
                        <div
                            v-for="availableModule in modules"
                            :key="availableModule.key"
                        >
                            <NuxtLink
                                v-can="availableModule.checkPermission ? `${availableModule.key}:access` : null"
                                :to="`/${availableModule.key}/${availableModule.defaultPath()}`"
                                :class="[
                                    $theme('submenu.item'),
                                    {
                                        'bg-neutral-100': availableModule.current,
                                    },
                                ]"
                                @click.native="userSubmenu?.hide()"
                            >
                                <i :class="$theme('submenu.item_icon', `fa-regular fa-${availableModule.icon}`)" />
                                <span>{{ $t(`modules.${availableModule.key}`) }}</span>
                            </NuxtLink>
                        </div>
                    </div>

                    <div
                        v-if="tenants && tenants.count() > 1"
                        :class="$theme('submenu.items')"
                    >
                        <div :class="$theme('submenu.item_without_icon', 'cursor-default flex items-center justify-between')">
                            <div class="flex items-center space-x-2">
                                <div
                                    class="w-6 h-6 bg-center bg-cover rounded-lg"
                                    :class="{ 'bg-neutral-200': !tenant!.logoUrl }"
                                    :style="`background-image: url(${tenant!.logoUrl})`"
                                ></div>
                                <span>{{ tenant!.name }}</span>
                            </div>
                            <span>
                                <i
                                    class="text-sm fa-regular fa-circle-check"
                                    :class="$theme('submenu.item_icon')"
                                />
                            </span>
                        </div>
                        <Submenu
                            container="absolute z-[100] p-4 shadow-xl bg-white dark:bg-black whitespace-nowrap border border-gray-100 dark:border-black rounded-lg outline-none"
                            alignment="top-0"
                            position="right-full"
                        >
                            <template #default="slotProps">
                                <a
                                    href="#"
                                    :class="$theme('submenu.item')"
                                    @click.prevent="slotProps.toggle()"
                                >
                                    <div class="w-6 text-center">
                                        <i :class="$theme('submenu.item_icon', 'fa-regular fa-building-user')" />
                                    </div>
                                    <span>{{ $t('layout.header.user_submenu.change_account') }}</span>
                                </a>
                            </template>
                            <template #submenu>
                                <div class="w-full min-w-[200px] h-full">
                                    <template v-for="availableTenant in tenants">
                                        <NuxtLink
                                            v-if="availableTenant.getId() !== tenant.getId()"
                                            :key="availableTenant.getInternalId()"
                                            :to="`/switch-tenant?tenant=${availableTenant.getId()}`"
                                            :class="$theme('submenu.item_without_icon', 'flex items-center justify-between')"
                                        >
                                            <div class="flex items-center space-x-2">
                                                <div
                                                    class="w-6 h-6 bg-center bg-cover rounded-lg"
                                                    :class="{ 'bg-neutral-200': !availableTenant.logoUrl }"
                                                    :style="`background-image: url(${availableTenant.logoUrl})`"
                                                ></div>
                                                <span>{{ availableTenant.name }}</span>
                                            </div>
                                        </NuxtLink>
                                    </template>
                                </div>
                            </template>
                        </Submenu>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
