<script lang="ts" setup>
    useModal('app:coupons', () => cleanUp());
    const cleanUp = () => {
        coupon.value = [];
    };
    const coupon = ref<string[]>([]);
    const modal = ref(null);
    const applyCoupon = () => {
        useEvent('app:coupons:close', { coupons: coupon.value });
    };
</script>

<template>
    <Modal
        ref="modal"
        name="app:coupons"
        modal-style="centered"
        :show-close-icon="false"
        scrollable
    >
        <div class="items-center justify-center p-6 mt-8 space-y-4 text-center rounded-md">
            <h3 class="text-sm">
                {{ $t('settings.subscription.code.title') }}
            </h3>
            <input
                v-model="coupon"
                type="text"
                :placeholder="`${$t('settings.subscription.code')}`"
                :class="$theme('form.input')"
            />
            <div class="space-x-2">
                <LoadingButton
                    :class-name="$theme('button.style.default')"
                    @clicked="useEvent('app:coupons:close')"
                >
                    {{ $t(`actions.continue`) }}
                </LoadingButton>
                <LoadingButton
                    :class-name="$theme('button.style.green')"
                    @clicked="applyCoupon"
                >
                    {{ $t(`settings.subscription.apply_coupon`) }}
                </LoadingButton>
            </div>
        </div>
    </Modal>
</template>
