import WasteType from '../../worksite/enums/WasteType';
import { BaseSchema, ModelType } from '~~/app/base/schemas/BaseSchema';

type WasteCentereAttributes = {
    title: string;
    address?: Address | null;
    type?: WasteType;
};

type WasteCentereMeta = {};
type WasteCentereRelationships = {};

export default class WasteCentereSchema extends BaseSchema<WasteCentereAttributes, WasteCentereMeta, WasteCentereRelationships> {
    protected type: ModelType = ModelType.WASTE_CENTER;
}
