import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ProductUnitRelationships = {};

type ProductUnitAttributes = {
    title: string;
};

type ProductUnitMeta = {};

export default class ProductUnitSchema extends BaseSchema<ProductUnitAttributes, ProductUnitMeta, ProductUnitRelationships> {
    protected type: ModelType = ModelType.PRODUCT_UNITS;
}
