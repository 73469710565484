<script setup lang="ts">
    import { useToasteo } from '@deegital/vue-trustup-io-toasteo';
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';
    import InvoiceForm from '~/app/invoicing/classes/InvoiceForm';
    import InvoiceEndpoint from '~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
        form: InvoiceForm<any>;
    }

    const { t } = useTranslation();
    const toasteo = useToasteo();
    const loading = ref(true);
    const generatingPdf = ref(false);
    const payload = ref<PayloadInterface>();
    const html = ref('');
    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;

    useModal(
        'invoicing:invoice:preview-html',
        async (p: PayloadInterface) => {
            payload.value = p;
            const response = await endpoint.previewHtml(payload.value.form.toSchema(), payload.value.invoice?.getId());
            html.value = response.html;
            loading.value = false;
        },
        () => {
            loading.value = true;
            html.value = '';
        }
    );

    const generatePdf = async (p: LoadingButtonClickedEventPayload) => {
        const response = await p.loadUntil(endpoint.preview(payload.value.form.toSchema()));
        if (response.data && response.data.url) {
            window.open(response.data.url, '_blank');
            // TODO: Add a onClick callback to the toast
            toasteo.success({
                title: t('toasts.preview_generated'),
                text: t('toasts.popup_helper'),
                // text: 'Click here if it does not open. (t)',
                // showUndo: false,
                // callback: () => {
                //     window.open(response.pdf, '_blank');
                // }
            });
        }
    };
</script>

<template>
    <Modal
        ref="modal"
        modal-style="right_panel_pdf"
        name="invoicing:invoice:preview-html"
        :width="800"
    >
        <Loader v-if="loading" />
        <template v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('invoicing.invoice_preview_html_modal.title') }}
                </h2>
            </div>
            <div :class="$theme('modal.padding')">
                <div class="mb-12">
                    <div class="flex justify-center">
                        <iframe
                            :srcdoc="html"
                            class="w-[794px] h-[1122px] mb-4 border border-gray-200 rounded bg-white"
                        ></iframe>
                    </div>
                    <p class="text-xs text-gray-600">
                        {{ $t('invoicing.invoice_preview_html_modal.notes') }}
                        <span v-if="payload.invoice?.frenchVatCertificateUrl">
                            {{ $t('invoicing.invoice_preview_html_modal.french_vat_certificate_notes') }}
                        </span>
                    </p>
                </div>
                <div class="flex justify-center mb-8">
                    <LoadingButton
                        :loading="generatingPdf"
                        @clicked="generatePdf"
                    >
                        {{ $t('invoicing.invoice_preview_html_modal.generate_pdf') }}
                    </LoadingButton>
                </div>
            </div>
        </template>
    </Modal>
</template>
