<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import { onClickOutside } from '@vueuse/core'
    import { FormInputData } from '~~/composables/useFormInput'
    const { $theme } = useNuxtApp();
    const { tenant } = useAuthStore();
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean }
});
    const inputData: FormInputData = useFormInput(props);
    const focused = ref(false);
    const prefixInput = ref(null);
    onClickOutside(prefixInput, (event) => (focused.value = false));
    const suggestions = computed<string[]>(() => {
        if (!focused.value || !inputData.model.value || inputData.model.value.length < 1) {
            return [];
        }
        console.log('SUGGESTIONS', inputData.model.value);
        const providers = ['Mr', 'M', 'Mr-M', 'M-Mr', 'Dr', 'Prof', 'Master', 'mr', 'm', 'mr-m', 'm-mr', 'dr', 'prof', 'master'];
        return providers.filter((prefix) => prefix.startsWith(inputData.model.value) && prefix !== inputData.model.value);
    });
</script>

<template>
    <FormElement :input-data="inputData">
        <div
            ref="prefixInput"
            class="relative"
        >
            <input
                :id="inputData.inputId.value"
                v-model="inputData.model.value"
                :class="[inputData.className.value]"
                :name="inputData.props.inputName"
                :placeholder="inputData.placeholder.value"
                type="text"
                :disabled="inputData.props.disabled"
                @focus="focused = true"
            />
            <div
                v-if="suggestions.length > 0"
                :class="$theme('submenu.container.default', 'w-full')"
            >
                <div :class="$theme('submenu.items')">
                    <a
                        v-for="suggestion in suggestions"
                        :key="suggestion"
                        href="#"
                        :class="$theme('submenu.item')"
                        @click.prevent="inputData.model.value = suggestion"
                    >
                        {{ suggestion }}
                    </a>
                </div>
            </div>
        </div>
    </FormElement>
</template>
