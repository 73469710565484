<script setup lang="ts">
    import VueSlider from 'vue-slider-component';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceCollection from '~~/app/invoicing/collections/InvoiceCollection';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';
    import 'vue-slider-component/theme/antd.css';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const depositInvoice = reactiveModel<Invoice>(null);
    const percentage = ref(30);
    const { loading, collection, load, filters } = useCollectionLoader<Invoice, InvoiceCollection>(ModelType.INVOICES, [], () => {
        return {
            filter: {
                statusNot: ['cancelled'],
                documentType: 'invoice',
                depositInvoiceOf: invoice.value!.getId(),
            },
        };
    });
    const maxPercentage = computed(() => {
        const used = collection.value?.sum('depositInvoicePercentage') ?? 0;
        return 100 - used;
    });
    const maxDepositInvoiceTotal = computed(() => {
        return invoice.value!.total * (maxPercentage.value / 100);
    });

    const success = ref(false);
    const form = useForm(
        {
            percentage: 0,
            amount: 0,
        },
        (field, value) => {
            if (field == 'amount') {
                form.setSilently('percentage', (value / invoice.value!.total) * 100);
            } else {
                form.setSilently('amount', invoice.value?.total * (value / 100));
            }

            percentage.value = form.get('percentage');
        }
    );

    useModal(
        'invoicing:invoice:deposit-invoice',
        async (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            await load();
            form.set('percentage', maxPercentage.value / 2);
        },
        () => {
            success.value = false;
            invoice.value = depositInvoice.value = null;
            form.reset();
        }
    );

    const submit = async () => {
        const response = await form.loadUntil(endpoint.storeDepositInvoice(invoice.value!.getId(), percentage.value));

        if (response.data) {
            depositInvoice.value = response.data;
            success.value = true;
        }
    };

    const openDepositInvoice = () => {
        depositInvoice.value!.edit();
        useEvent('invoicing:invoice:deposit-invoice:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:deposit-invoice"
    >
        <ModalLoader v-if="loading" />
        <template v-else>
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoicing.invoice_deposit_invoice_modal.success.title')"
                    :subtitle="$t('invoicing.invoice_deposit_invoice_modal.success.text')"
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice:deposit-invoice:close')"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            prevent-default
                            @clicked="openDepositInvoice"
                            >{{ $t('actions.open') }}</LoadingButton
                        >
                    </div>
                </ModalLottie>
            </template>
            <template v-else>
                <template v-if="maxPercentage == 0">
                    <div class="p-12">
                        <div class="mb-12">
                            <h2 :class="$theme('modal.title.text', 'mb-4')">{{ $t('invoicing.invoice_deposit_invoice_modal.max_percentage.title') }}</h2>
                            <div
                                v-if="collection?.filterByProperty('status', 'draft').length > 0"
                                class="flex items-center p-4 mb-6 space-x-8 text-sm text-blue-800 bg-blue-100 border border-blue-600 rounded-lg"
                            >
                                <div>
                                    <i class="text-2xl fa-regular fa-info-circle"></i>
                                </div>
                                <div>
                                    <p>
                                        {{ $t('invoicing.invoice_deposit_invoice_modal.max_percentage.text') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <InvoicingInvoiceDepositInvoiceTable
                            :key="collection.getInternalId()"
                            :collection="collection"
                        />
                    </div>
                </template>
                <template v-else>
                    <div :class="$theme('modal.title.container')">
                        <h2 :class="$theme('modal.title.text')">{{ $t('actions.create_deposit_invoice') }}</h2>
                    </div>
                    <form @submit.prevent="submit">
                        <div :class="$theme('modal.padding')">
                            <div class="mb-8">
                                <div class="mb-4 text-center">
                                    <Submenu
                                        overlay
                                        position="center-x"
                                    >
                                        <template #default="{ toggle }">
                                            <div
                                                class="relative text-center group"
                                                @click.prevent="toggle"
                                            >
                                                <span class="relative text-4xl font-thin text-gray-600 cursor-pointer">
                                                    {{ $rounded2(form.get('percentage')) }}%
                                                    <span class="absolute top-0 bottom-0 flex items-center hidden ml-2 group-hover:flex left-full">
                                                        <i class="text-sm text-gray-600 fa-regular fa-pencil" />
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                        <template #submenu>
                                            <div class="w-[200px] p-2">
                                                <p class="mb-2 text-center">
                                                    {{ $t('invoicing.deposit_invoice_modal.set_percentage') }}
                                                </p>
                                                <FormPercentage
                                                    element-class-name=" "
                                                    :form="form"
                                                    input-name="percentage"
                                                    min="0"
                                                    :max="maxPercentage"
                                                    :display-label="false"
                                                />
                                            </div>
                                        </template>
                                    </Submenu>
                                    <Submenu
                                        overlay
                                        position="center-x"
                                    >
                                        <template #default="{ toggle }">
                                            <div
                                                class="relative text-center group"
                                                @click.prevent="toggle"
                                            >
                                                <span class="relative text-lg cursor-pointer">
                                                    {{ $price(form.get('amount')) }}
                                                    <span class="absolute top-0 bottom-0 flex items-center hidden ml-2 group-hover:flex left-full">
                                                        <i class="text-sm text-gray-600 fa-regular fa-pencil" />
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                        <template #submenu>
                                            <div class="w-[200px] p-2">
                                                <p class="mb-2 text-center">
                                                    {{ $t('invoicing.deposit_invoice_modal.set_percentage') }}
                                                </p>
                                                <FormPrice
                                                    element-class-name=" "
                                                    :form="form"
                                                    input-name="amount"
                                                    min="0"
                                                    :max="maxDepositInvoiceTotal"
                                                    :display-label="false"
                                                />
                                            </div>
                                        </template>
                                    </Submenu>
                                </div>
                                <div class="flex items-center w-full">
                                    <div
                                        v-for="depositInvoice in collection?.get()"
                                        :key="depositInvoice.getInternalId()"
                                        :style="`width: ${depositInvoice?.depositInvoicePercentage}%`"
                                        :data-tooltip="`${depositInvoice.numberOrDraft} - ${$price(depositInvoice.total)}`"
                                        data-tooltip-location="top"
                                        :class="`h-1 ${useStatusAccentBackgroundColour(depositInvoice.status)}`"
                                    ></div>
                                    <div class="flex-1">
                                        <vue-slider
                                            v-model="percentage"
                                            :min="1"
                                            :max="maxPercentage"
                                            @change="(value) => form.set('percentage', value)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="collection?.isNotEmpty()">
                                <p class="mb-2 text-sm text-gray-700">{{ $t('invoicing.invoice_deposit_invoice_modal.existing_deposit_invoices.title') }}</p>
                                <p class="mb-2 text-xs text-gray-500">{{ $t('invoicing.invoice_deposit_invoice_modal.existing_deposit_invoices.text') }}</p>
                                <InvoicingInvoiceDepositInvoiceTable
                                    :key="collection.getInternalId()"
                                    :collection="collection"
                                />
                            </div>
                            <div :class="$theme('modal.footer.button_container')">
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :class-name="$theme('button.style.cancel')"
                                    prevent-default
                                    @clicked="useEvent('invoicing:invoice:deposit-invoice:close')"
                                    >{{ $t('actions.cancel') }}</LoadingButton
                                >
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :loading="form.isLoading"
                                    type="submit"
                                    >{{ $t('actions.create_deposit_invoice') }}</LoadingButton
                                >
                            </div>
                        </div>
                    </form>
                </template>
            </template>
        </template>
    </Modal>
</template>
