<script setup lang="ts">
    import WorksiteClosureReportRoute from '../../../classes/WorksiteClosureReportRoute';
    import WorksiteClosureReportStatus from '~/app/worksite/enums/WorksiteClosureReportStatus';
    import WorksiteClosureReport from '@/app/worksite/models/WorksiteClosureReport';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    const endpoint = EndpointFactory.make(ModelType.WORKSITES_CLOSURE_REPORT);
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);
    const downloadButton = ref(null);

    const destroy = async (model: WorksiteClosureReport) => {
        await deleteButton.value?.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };

    const download = async (model: WorksiteClosureReport) => {
        await window.open(model.pdf?.url, '_blank');
    };

    const showLink = (closureReport: WorksiteClosureReport) => new WorksiteClosureReportRoute(closureReport.worksite?.getId() as string).show(closureReport.getId());

    const send = async (model: WorksiteClosureReport) => {
        useEvent('worksite:worksite:closure-report:send-email:open', { model });
        useEvent('worksite:worksite:closure-reports:context-menu:close');
    };

    const loading = ref(false);
    const sign = async (model: WorksiteClosureReport) => {
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.WORKSITES_CLOSURE_REPORT);
        const response = await endpoint.getPublicLink(model.getId());
        if (!response) {
            useToasteoError();
            loading.value = false;
        }
        loading.value = false;
        window.open(response.data.attributes.url, '_blank');
    };

    const navigateToWorksite = (model: WorksiteClosureReport) => {
        navigateTo(`/worksite/worksite/${model.worksite?.getId()}`);
        useEvent('worksite:worksite:closure-reports:context-menu:close');
    };

    const navigateToQuote = (model: WorksiteClosureReport) => {
        navigateTo(`/invoicing/quotes/${model.quote?.getId()}/edit`);
        useEvent('worksite:worksite:closure-reports:context-menu:close');
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="WorksiteClosureReport"
        :menu-height="200"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => navigateTo(showLink(slotProps.model)))"
        >
            <i
                class="fa-arrow-right"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open') }}</span>
        </a>

        <a
            v-if="slotProps.model.worksite"
            href="#"
            :class="$theme('contextmenu.link')"
            @click="navigateToWorksite(slotProps.model)"
        >
            <i :class="[$theme('contextmenu.icon'), 'fa-helmet-safety']"></i>
            <span>{{ $t('actions.open_worksite') }}</span>
        </a>

        <a
            v-if="slotProps.model.quote"
            href="#"
            :class="$theme('contextmenu.link')"
            @click="navigateToQuote(slotProps.model)"
        >
            <i :class="[$theme('contextmenu.icon'), 'fa-file-invoice']"></i>
            <span>{{ $t('actions.open_quote') }}</span>
        </a>

        <!-- <a
      href="#"
      :class="$theme('contextmenu.link')"
      @click.prevent="onAction(() => slotProps.model?.edit())"
    >
      <i class="fa-pencil" :class="$theme('contextmenu.icon')"></i>
      <span>{{ $t("actions.edit") }}</span>
    </a> -->
        <LoadingButton
            v-if="slotProps.model.pdf?.url"
            ref="downloadButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="download(slotProps.model)"
        >
            <i
                class="fa-download"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.download') }}</span>
        </LoadingButton>
        <LoadingButton
            ref="sendButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="send(slotProps.model)"
        >
            <i
                class="fa-paper-plane"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.send') }}</span>
        </LoadingButton>

        <LoadingButton
            v-if="
                slotProps.model.status === WorksiteClosureReportStatus.COMPLETED_AWAITING_TENANT_SIGNATURE ||
                slotProps.model.status === WorksiteClosureReportStatus.RESERVES_RAISED_AWAITING_TENANT_SIGNATURE
            "
            ref="signButton"
            :loading="loading"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="sign(slotProps.model)"
        >
            <i
                class="fa-regular fa-signature"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.sign') }}</span>
        </LoadingButton>
        <LoadingButton
            v-if="slotProps.model.status === 'draft'"
            ref="deleteButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
