<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import CalendarEventEndpoint from '~~/app/planning/endpoints/CalendarEventEndpoint';
    import CalendarEvent from '~~/app/planning/models/CalendarEvent';

    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const endpoint = EndpointFactory.make(ModelType.CALENDAR_EVENTS) as CalendarEventEndpoint;

    const duplicateNextDay = async (payload: LoadingButtonClickedEventPayload, model: CalendarEvent) => {
        await payload.loadUntil(onPromise(endpoint.duplicateNextDay(model)));
    };

    const duplicateEndOfWeek = async (payload: LoadingButtonClickedEventPayload, model: CalendarEvent) => {
        await payload.loadUntil(onPromise(endpoint.duplicateEndOfWeek(model)));
    };

    const destroy = async (payload: LoadingButtonClickedEventPayload, model: CalendarEvent) => {
        await payload.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="CalendarEvent"
        v-slot="slotProps"
        ref="contextMenu"
        :menu-height="250"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.preview())"
        >
            <i
                class="fa-eye"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.preview') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="(payload) => duplicateNextDay(payload, slotProps.model)"
            prevent-default
        >
            <i
                class="fa-step-forward"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.duplicate_to_next_day') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="(payload) => duplicateEndOfWeek(payload, slotProps.model)"
            prevent-default
        >
            <i
                class="fa-fast-forward"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.duplicate_to_end_of_week') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="(payload) => destroy(payload, slotProps.model)"
            prevent-default
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
