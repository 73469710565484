<script lang="ts" setup>
    import { storeToRefs } from 'pinia';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import WorksiteClosureReport from '@/app/worksite/models/WorksiteClosureReport';

    const { tenant } = storeToRefs(useAuthStore());

    const closureReport = ref<WorksiteClosureReport | null>(null);
    export interface closureReportMail {
        sendTo: string;
        subject: string;
        title: string;
        text: string;
    }
    const form = useForm<{
        sendTo: string;
        subject: string;
        title: string;
        text: string;
    }>({
        sendTo: '',
        subject: '',
        title: '',
        text: '',
    });

    const { t } = useTranslation();
    useModal('worksite:worksite:closure-report:send-email', (payload: any) => {
        success.value = false;
        closureReport.value = payload.model;
        form.set('sendTo', closureReport.value?.contact?.email ? closureReport.value?.contact?.email : '');
        form.set('subject', t('emails.closure-report_created.subject', { company: tenant.value!.name }));
        form.set('text', t('emails.closure-report.info'));
        form.set(
            'title',
            t('emails.closure-report_created.title', {
                worksite: closureReport.value?.worksite?.title ?? '',
                closureReport: closureReport.value?.internalNumber,
            })
        );
    });

    const endpoint = EndpointFactory.make(ModelType.WORKSITES_CLOSURE_REPORT);
    const success = ref(false);

    const submit = async () => {
        const response = ref();
        form.set('to', form.get('sendTo'));
        response.value = await form.loadUntil(endpoint.notify(closureReport.value?.getId() as string, 'email', form.fields));

        if (response.value.error) return useToasteoError();
        useEvent('worksite:worksite:closure-reports:updated', response.value.data);
        success.value = true;
        useToasteoSuccess();
    };
    const editor = ref();
    onMounted(() => {
        editor.value?.focus();
    });
</script>

<template>
    <div>
        <Modal
            ref="modal"
            name="worksite:worksite:closure-report:send-email"
        >
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoicing.invoice.send_modal.success.title')"
                    :subtitle="
                        $t('invoicing.invoice.send_modal.success.text', {
                            to: form.get('sendTo'),
                        })
                    "
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('worksite:worksite:closure-report:send-email:close')"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>

            <div v-if="!success">
                <div :class="$theme('modal.title.container')">
                    <h2
                        :class="$theme('modal.title.text')"
                        class="first-letter:capitalize"
                    >
                        {{ $t('worksite.closure_report.send_modal.draft.title') }}
                    </h2>
                </div>
                <form
                    class="mb-4"
                    @submit.prevent="submit"
                >
                    <div
                        :class="$theme('modal.padding')"
                        class="space-y-4"
                    >
                        <div>
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="wpbrvfm_email"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.recipient') }}
                                </label>
                                <div class="w-full md:flex-auto">
                                    <FormEmailBadge
                                        id="wpbrvfm_email"
                                        :value="form.get('sendTo')"
                                        :placeholder="$t('form.labels.email')"
                                        :multiple="false"
                                        @changed="(value) => form.set('sendTo', value)"
                                    />
                                </div>
                            </div>
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="invoice-send-form-subject"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.email_subject') }}
                                </label>
                                <div class="flex-1">
                                    <input
                                        id="invoice-send-form-subject"
                                        name="subject"
                                        :value="form.get('subject')"
                                        class="w-full p-2 text-sm text-gray-600 outline-none focus:text-black"
                                        @change="form.set('subject', $event.target.value)"
                                    />
                                </div>
                            </div>
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label
                                    for="invoice-send-form-title"
                                    class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap"
                                >
                                    {{ $t('form.labels.email_title') }}
                                </label>
                                <div class="flex-1">
                                    <input
                                        id="invoice-send-form-title"
                                        name="title"
                                        :value="form.get('title')"
                                        class="w-full p-2 text-sm text-gray-600 outline-none focus:text-black"
                                        @change="form.set('title', $event.target.value)"
                                    />
                                </div>
                            </div>
                            <div class="py-4">
                                <FormEditor
                                    ref="editor"
                                    :form="form"
                                    :autofocus="true"
                                    element-class-name="w-full"
                                    input-name="text"
                                    without-label
                                    class-name="w-full text-gray-600 text-sm font-thin focus:text-black outline-none h-[100px]"
                                />
                            </div>
                        </div>

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('worksite:worksite:closure-report:send-email:close')"
                                >{{ $t('actions.cancel') }}</LoadingButton
                            >
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :loading="form.isLoading"
                                type="submit"
                            >
                                <i class="mr-2 fa-solid fa-paper-plane"></i>
                                {{ $t('actions.validate') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
