<script setup lang="ts"></script>

<template>
    <div
        class="relative items-center hidden space-x-2 text-sm text-gray-600 transition-all bg-neutral-100 rounded-full cursor-pointer md:flex hover:text-primary dark:text-gray-400 dark:hover:text-gray-200 dark:bg-black dark:hover:bg-blue-900 md:py-2 md:px-4 hover:bg-neutral-200"
        @click.prevent="useEvent('app:search:open')"
    >
        <i class="text-base fa-regular fa-search" />
        <span>
            {{ $t('global_search.label') }}
        </span>
        <span class="pl-4 text-gray-400"> ⌘K </span>
    </div>
</template>
