<script setup lang="ts">
    import WasteCentereSchema from '../../../app/common/schemas/WasteCenterSchema';
    import WasteCenter from '../../../app/common/models/WasteCenter';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import WasteCenterType from '@/app/worksite/enums/WasteCenterType';

    interface Fields {
        title: String | null;
        centerType: WasteCenterType | null;
        address: Address | null;
    }

    const form = useForm<Fields>({
        title: null,
        centerType: null,
        address: null,
    });
    const { modalName } = useModal('common:waste-center:form-modal', (payload?: any) => {
        form.reset();
    });

    const submit = async () => {
        const schema = WasteCentereSchema.make({
            attributes: form.fields,
        });

        const endpoint = EndpointFactory.make(ModelType.WASTE_CENTER);

        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));
        if (response.error) return useToasteoError();

        useToasteoSuccess();
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`common:waste-center:form-modal:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="WasteCenter"
        :scrollable="true"
        :name="modalName"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('worksite.worksite.waste_center.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                />
                <FormWasteCenterTypeSelect
                    :form="form"
                    input-name="centerType"
                    :with-null-option="true"
                />

                <FormAddressAutocomplete
                    :form="form"
                    input-name="address"
                />

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
