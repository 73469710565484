<script setup lang="ts">
interface Props {
        modelType: typeof BaseModel;
        menuHeight?: number;
    }
    import { onClickOutside } from '@vueuse/core'
    import BaseModel from '~~/app/base/models/BaseModel'
    
    const props = defineProps({
  modelType: null,
  menuHeight: { default: 150 }
});
    const localModel: Ref<typeof props.modelType | null> = ref(null);
    const { id, show, hide, options, onUpdate } = useContextMenuEvent(
        props.modelType.prefix,
        (payload) => {
            localModel.value = payload.model;
        },
        () => {
            localModel.value = null;
        }
    );
    const contextMenu = ref(null);
    onClickOutside(contextMenu, (event) => {
        event.preventDefault();
        hide();
    });
    defineExpose({
        hide,
    });
</script>

<template>
    <div
        v-if="show"
        :id="id"
        ref="contextMenu"
        class="absolute z-[2000]"
        :style="`top: ${options.y}px; left: ${options.x}px;`"
    >
        <transition
            :enter-active-class="$theme('modal.centered.transition.enter-active-class')"
            :enter-from-class="$theme('modal.centered.transition.enter-from-class')"
            :enter-to-class="$theme('modal.centered.transition.enter-to-class')"
            :leave-active-class="$theme('modal.centered.transition.leave-active-class')"
            :leave-from-class="$theme('modal.centered.transition.leave-from-class')"
            :leave-to-class="$theme('modal.centered.transition.leave-to-class')"
        >
            <div
                v-if="localModel"
                :class="[$theme('contextmenu.container', 'h-full'), { 'nice-scrollbar overflow-y-scroll': options.scrollable }]"
                :style="`max-height: ${options.maxHeight ?? '1000'}px;`"
            >
                <slot :model="localModel" />
            </div>
        </transition>
    </div>
</template>
