import { useToasteo } from '@deegital/vue-trustup-io-toasteo';

export default function useToasteoBasic(
    translationKeyOrTitle: string = 'toasts.error',
    translationKeyOrText: string = 'toasts.error',
    raw: boolean = false,
    icon: string = 'fa-solid fa-triangle-exclamation'
) {
    const { t } = useTranslation();
    const toasteo = useToasteo();

    return toasteo.basic({
        title: translationKeyOrTitle,
        text: translationKeyOrText,
        icon: icon,
    });
}
