<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksitePhaseEndpoint from '~~/app/worksite/endpoints/WorksitePhaseEndpoint';
    import WorksitePhase from '~~/app/worksite/models/WorksitePhase';

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PHASES) as WorksitePhaseEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: WorksitePhase) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="WorksitePhase"
        :menu-height="200"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            v-if="slotProps.model?.worksite"
            @click.prevent="
                slotProps.model.worksite.open();
                onAction();
            "
        >
            <i
                class="fa-hard-hat"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open_worksite') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
            ref="deleteButton"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
