<script setup lang="ts">
    import Contact from '@/app/contact/models/Contact';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ContactEndpoint from '~~/app/contact/endpoints/ContactEndpoint';

    const { model } = useModelModal<Contact>(
        Contact,
        (data: ModalPayload<Contact>) => retrieve(data),
        () => (contact.value = null)
    );
    const loading: Ref<boolean> = ref(false);
    const contact: Ref<Contact | null> = ref(null);

    const retrieve = async (data: ModalPayload<Contact>) => {
        loading.value = true;
        let endpoint = EndpointFactory.make(data.model.type) as ContactEndpoint;
        let response = await endpoint.setInclude('tasks').retrieve(data.model.getId());
        contact.value = response.data;
        loading.value = false;
    };
</script>

<template>
    <ModelModal
        :model="Contact"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            :class="$theme('modal.right_panel.content')"
            v-if="!loading && contact"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ contact.name }}
                    <span class="block text-sm">
                        {{ $t(`contact.contactType.${contact.contactType}`) }}
                    </span>
                </h2>
            </div>

            <!-- Main section -->
            <div :class="$theme('modal.right_panel.section.container')">
                <div>
                    <template v-if="contact.isProfessional">
                        <InfoText :label="$t('form.labels.companyName')">
                            {{ contact.companyName ?? '-' }}
                        </InfoText>
                        <InfoText :label="$t('form.labels.vatNumber')">
                            {{ contact.vatNumber ?? '-' }}
                        </InfoText>
                        <InfoText :label="$t('form.labels.siret')">
                            {{ contact.siret ?? '-' }}
                        </InfoText>
                    </template>
                    <InfoText :label="$t('form.labels.name')">
                        {{ contact.name ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.email')">
                        {{ contact.email ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.phone')">
                        {{ contact.phone ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.address')">
                        {{ contact.address?.display ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.billingAddress')">
                        {{ contact.billingAddress?.display ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.locale')">
                        {{ $t(`locales.${contact.locale}`) }}
                    </InfoText>
                </div>
            </div>

            <!-- Tasks section -->
            <div
                :class="$theme('modal.right_panel.section.container')"
                v-if="contact.tasks?.isNotEmpty()"
            >
                <TaskTaskLinesTable
                    :collection="contact.tasks"
                    :key="contact.tasks?.getInternalId()"
                />
            </div>
        </div>
    </ModelModal>
</template>
