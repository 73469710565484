<script setup lang="ts">
    import { InvoiceValidateCustomInternalNumberResponse } from '~~/app/invoicing/endpoints/types';

    const { t } = useTranslation();
    const props = defineProps({
  customInternalNumberValidation: null
});

    const color = computed(() => {
        if (props.customInternalNumberValidation.isUnique) {
            if (props.customInternalNumberValidation.isPerfectlySequential) {
                return 'bg-neutral-100 text-green-600 border-l-4 border-green-600';
            }
            return 'bg-neutral-100 text-neutral-800 border-l-4 border-gray-800';
        }
        if (!props.customInternalNumberValidation.isUnique) {
            return 'bg-neutral-100 text-red-600 border-l-4 border-red-600';
        }
        if (props.customInternalNumberValidation.isUnique && props.customInternalNumberValidation.isSequential) {
            return 'bg-neutral-100 text-orange-600 border-l-4 border-orange-600';
        }
    });

    const icon = computed(() => {
        if (props.customInternalNumberValidation.isUnique) {
            if (props.customInternalNumberValidation.isPerfectlySequential) {
                return 'fa-circle-check';
            }
            return 'fa-circle-check';
        }
        if (!props.customInternalNumberValidation.isUnique) {
            return 'fa-exclamation-circle';
        }
        if (props.customInternalNumberValidation.isUnique && props.customInternalNumberValidation.isSequential) {
            return 'fa-exclamation-triangle';
        }
    });

    const text = computed(() => {
        if (props.customInternalNumberValidation.isUnique) {
            if (props.customInternalNumberValidation.isPerfectlySequential) {
                return t('invoicing.invoice_custom_internal_number_validation.perfect');
            }
            return t('invoicing.invoice_custom_internal_number_validation.good');
        }
        return t('invoicing.invoice_custom_internal_number_validation.used');
    });
</script>

<template>
    <div>
        <div
            class="flex items-center p-4 mb-8 space-x-4 text-sm rounded"
            :class="color"
        >
            <div>
                <i
                    class="text-2xl fa-regular"
                    :class="icon"
                ></i>
            </div>
            <div>
                <span>{{ text }}</span>
                <span
                    class="block text-xs"
                    v-if="customInternalNumberValidation.missingFormattedNumbers.length > 0"
                >
                    {{ $t('invoicing.invoice_custom_internal_number_validation.missing_number') }}
                    {{ customInternalNumberValidation.missingFormattedNumbers.join(', ') }}
                </span>
            </div>
        </div>
    </div>
</template>
