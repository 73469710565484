import { ComposedWorkLinesIndexSchema, ProductsIndexSchema } from '~/app/common/schemas/ProductsIndexSchema';

// TODO TYPE HINT FOR COMPOSED WORK LINE AND PRODUCT
export default function (selectedProducts: ComputedRef) {
    const draggingItem = ref<ProductsIndexSchema | ComposedWorkLinesIndexSchema | null>(null);
    const placeholderIndex = ref<number | null>(null);

    const startDrag = (event: any, item: any, index: number) => {
        placeholderIndex.value = index;
        draggingItem.value = item;

        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer?.setData('text/plain', item.uuid);
    };

    const onDragOver = async (event: DragEvent, index: number) => {
        event.preventDefault();

        // Ensure the dragged element moves visually
        if (placeholderIndex.value !== index) {
            const oldIndex = placeholderIndex.value;
            placeholderIndex.value = index;

            // Update the items array to reflect the new position of the placeholder
            const draggedIndex = selectedProducts.value.findIndex((item) => item.uuid === draggingItem.value?.uuid);
            if (draggedIndex !== -1 && draggingItem.value) {
                const movedItem = selectedProducts.value.splice(draggedIndex, 1)[0];
                selectedProducts.value.splice(index, 0, movedItem);

                await nextTick();

                // Calculate and apply the translation
                if (oldIndex !== null) {
                    const draggedElement = document.getElementById(`item-${draggingItem.value.uuid}`);
                    const targetElement = document.getElementById(`item-${selectedProducts.value[oldIndex].uuid}`);

                    if (draggedElement && targetElement) {
                        const draggedRect = draggedElement.getBoundingClientRect();
                        const targetRect = targetElement.getBoundingClientRect();
                        const dx = targetRect.left - draggedRect.left;
                        const dy = targetRect.top - draggedRect.top;
                        draggedElement.style.transform = `translate(${dx}px, ${dy}px)`;
                        await nextTick(); // Wait for DOM updates
                        draggedElement.style.transform = '';
                    }
                }
            }
        }
    };

    const onDragLeave = (event: DragEvent, index: number) => {
        // You can use this to handle styling when leaving an item
    };

    const onDrop = (event: DragEvent, index: number) => {
        event.preventDefault();

        // Reset dragging item and placeholder index
        draggingItem.value = null;
        placeholderIndex.value = null;

        // Ensure all elements are in their final positions
        selectedProducts.value.forEach((item) => {
            const element = document.getElementById(`item-${item.uuid}`);
            if (element) {
                element.style.transform = '';
            }
        });
    };
    return { onDragLeave, onDragOver, onDrop, startDrag, placeholderIndex };
}
