<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ModelFileEndpoint from '~~/app/common/endpoints/ModelFileEndpoint';
    import ModelFile from '~~/app/common/models/ModelFile';

    const endpoint = EndpointFactory.make(ModelType.MODEL_FILES) as ModelFileEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: ModelFile) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };

    /**
     * TODO: Improve this somehow...
     */
    const shouldDisplayMoveAction = computed(() => {
        return window.location.pathname.startsWith('/worksite/worksite');
    });
</script>

<template>
    <ModelContextMenu
        :model-type="ModelFile"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.open())"
        >
            <i
                class="fa-eye"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => useEvent('common:model-file:move-modal:open', { modelFile: slotProps.model }))"
            v-if="shouldDisplayMoveAction"
        >
            <i
                class="fa-arrow-right"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.move') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.download())"
            v-if="slotProps.model?.isDownloadable()"
        >
            <i
                class="fa-download"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.download') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
            ref="deleteButton"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
