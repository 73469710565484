import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoicePaymentRelationships = {
    invoice: ToOneRelationship<ModelType.INVOICES>;
    bankAccountTransaction: ToOneRelationship<ModelType.BANK_ACCOUNT_TRANSACTIONS>;
};

type InvoicePaymentAttributes = {
    title: string;
    amount: number;
    paidAt: string;
    paymentMethod: string;
    claimRemains: boolean;
};

type InvoicePaymentMeta = {};

export default class InvoicePaymentSchema extends BaseSchema<InvoicePaymentAttributes, InvoicePaymentMeta, InvoicePaymentRelationships> {
    protected type: ModelType = ModelType.INVOICE_PAYMENTS;
}
