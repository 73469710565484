<script setup lang="ts">
    import BaseEndpoint from '../../app/base/endpoints/BaseEndpoint';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import BaseModel from '@/app/base/models/BaseModel';
    import BaseCollection from '@/app/base/collections/BaseCollection';

    const show = ref(false);
    const options = ref<{ x: number; y: number }>();
    useListen('common:meilisearch-hit:context-menu:open', async (payload) => {
        options.value = { x: payload.event.clientX, y: payload.event.clientY };
        show.value = true;
        const endpoint = EndpointFactory.make(payload.modelType) as BaseEndpoint<BaseModel, BaseCollection<BaseModel>>;
        const response = await endpoint.retrieve(payload.uuid);
        show.value = false;
        response.data?.openContextMenu(payload.event);
    });
</script>

<template>
    <div
        v-if="show"
        ref="contextMenu"
        class="absolute z-[2000]"
        :style="`top: ${options!.y}px; left: ${options!.x}px;`"
    >
        <transition
            :enter-active-class="$theme('modal.centered.transition.enter-active-class')"
            :enter-from-class="$theme('modal.centered.transition.enter-from-class')"
            :enter-to-class="$theme('modal.centered.transition.enter-to-class')"
            :leave-active-class="$theme('modal.centered.transition.leave-active-class')"
            :leave-from-class="$theme('modal.centered.transition.leave-from-class')"
            :leave-to-class="$theme('modal.centered.transition.leave-to-class')"
        >
            <div
                class="max-h-[100px]"
                :class="[$theme('contextmenu.container', 'h-full')]"
            >
                <div class="animate-spin">
                    <i class="fa-duotone fa-spinner-third text-primary"></i>
                </div>
            </div>
        </transition>
    </div>
</template>
