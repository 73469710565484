<script setup lang="ts">
    import InvoiceSchema from '@/app/invoicing/schemas/InvoiceSchema';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const inputFileId = ref(makeId());
    const inputFile = ref<HTMLInputElement | null>(null);
    const endpoint = EndpointFactory.make(ModelType.INVOICES);
    const invoice = reactiveModel<Invoice>(null);

    useModal(
        'invoicing:invoice:french-vat-certificate',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
        },
        () => {
            //
        }
    );

    const uploading = ref<boolean>(false);
    const onFileUpload = async (event: Event) => {
        event.preventDefault();
        const file = (event.target as HTMLInputElement).files?.[0];

        uploading.value = true;

        await endpoint.uploadFrenchVatCertificate(invoice.value?.getId(), file);

        useToasteoSuccess();
        useEvent('invoicing:invoice:french-vat-certificate:close');
        uploading.value = false;
    };

    const openExistingCertificate = () => {
        if (!invoice.value || !invoice.value.frenchVatCertificateUrl) return;

        window.open(invoice.value?.frenchVatCertificateUrl, '_blank');
    };

    const deleteExistingCertificate = async (payload: LoadingButtonClickedEventPayload) => {
        if (!invoice.value || !invoice.value.frenchVatCertificateUrl) return;

        const schema = new InvoiceSchema({ id: invoice.value?.getId(), attributes: { frenchVatCertificateUrl: null } });
        await payload.loadUntil(endpoint.update(schema));
        useToasteoSuccess();
        useEvent('invoicing:invoice:french-vat-certificate:close');
    };

    const downloadSimplifiedCertificate = () => window.open('https://www.impots.gouv.fr/sites/default/files/formulaires/1301-sd/2016/1301-sd_1515.pdf', '_blank');
    const downloadNormalCertificate = () => window.open('https://www.impots.gouv.fr/sites/default/files/formulaires/1300-sd/2016/1300-sd_1527.pdf', '_blank');
</script>

<template>
    <Modal
        name="invoicing:invoice:french-vat-certificate"
        ref="modal"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('invoicing.french_vat_certificate_modal.title') }}
            </h2>
        </div>
        <div>
            <div :class="$theme('modal.padding')">
                <div
                    v-if="invoice?.frenchVatCertificateUrl"
                    class="flex items-center px-12 py-6 space-x-12 text-blue-900 bg-blue-200 rounded"
                >
                    <div>
                        <i class="text-[50px] fa-light fa-memo-circle-check"></i>
                    </div>
                    <div>
                        <p class="mb-6 text-sm">
                            {{ $t('invoicing.french_vat_certificate_modal.existing_certificate') }}
                        </p>
                        <div class="flex items-stretch space-x-2 justify-stretch">
                            <LoadingButton
                                :class-name="$theme('button.style.transparent_sm2', 'w-full border border-primary hover:bg-white hover:text-red-600 hover:border-white')"
                                @clicked="deleteExistingCertificate"
                            >
                                {{ $t('actions.delete') }}
                            </LoadingButton>
                            <LoadingButton
                                :class-name="$theme('button.style.default_sm2', 'w-full')"
                                @clicked="openExistingCertificate"
                            >
                                {{ $t('actions.download') }}
                            </LoadingButton>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span class="text-sm text-center text-gray-800">{{ $t('invoicing.french_vat_certificate_modal.upload.label') }}</span>
                    <label
                        :for="inputFileId"
                        class="relative flex items-center justify-center w-full py-8 transition-all rounded cursor-pointer hover:bg-neutral-200 bg-neutral-100"
                    >
                        <Loader v-if="uploading" />
                        <i
                            class="text-xl text-gray-400 fa-regular fa-upload"
                            v-else
                        ></i>
                        <input
                            :id="inputFileId"
                            ref="inputFile"
                            type="file"
                            class="hidden"
                            @change="onFileUpload"
                            accept="application/pdf"
                        />
                    </label>
                    <div class="pt-8 mt-8 border-t border-gray-200">
                        <p class="mb-4 text-sm text-gray-600">
                            {{ $t('invoicing.french_vat_certificate_modal.upload.explanation') }}
                        </p>
                        <div class="flex items-stretch space-x-2 justify-stretch">
                            <LoadingButton
                                :class-name="$theme('button.style.gray_sm2', 'w-full')"
                                @clicked="downloadSimplifiedCertificate"
                            >
                                {{ $t('invoicing.french_vat_certificate_modal.upload.button_simplified') }}
                            </LoadingButton>
                            <LoadingButton
                                :class-name="$theme('button.style.gray_sm2', 'w-full')"
                                @clicked="downloadNormalCertificate"
                            >
                                {{ $t('invoicing.french_vat_certificate_modal.upload.button_normal') }}
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>
