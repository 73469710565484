import BaseModel from '~~/app/base/models/BaseModel';
import { onMounted } from 'vue';
import { useListen, useEvent } from './useEventBus';

export default function <Model extends BaseModel>(modelClass: typeof BaseModel, onOpened: Function | null = null, onClosed: Function | null = null) {
    const modalName = `${modelClass.prefix}:modal`;
    const model: Ref<Model | null> = ref(null);
    const modal = ref(null);

    useListen(`${modalName}:open`, (payload: ModalPayload<Model>) => {
        model.value = payload.model || null;
        useEvent('modal:open', modalName);
        if (onOpened) onOpened(payload);
    });
    useListen(`${modalName}:close`, () => {
        model.value = null;
        useEvent('modal:close', modalName);
    });

    useListen('modal:close', (name: string) => {
        if (name == modalName && onClosed) onClosed();
    });

    return {
        modalName,
        model,
        modal,
    };
}
