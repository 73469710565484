<template>
    <div>
        <Modal
            ref="modal"
            name="worksite:worksite-progress-billing:force-saving-progress-billing-modal"
        >
            <div :class="$theme('modal.title.container')">
                <h2
                    v-if="route.fullPath !== progressBillingRoute.index()"
                    :class="$theme('modal.title.text')"
                    class="px-8 pt-4 first-letter:capitalize"
                >
                    {{ $t('worksite_progress_billing.save_before_validation.title') }}
                </h2>
                <h2
                    v-else
                    :class="$theme('modal.title.text')"
                    class="items-center px-8 py-4 mt-12 text-sm rounded text-neutral-500 bg-neutral-100"
                >
                    {{ $t('worksite_progress_billing.modal.force_saving.info') }}
                </h2>
            </div>

            <div :class="$theme('modal.padding')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        v-if="route.fullPath !== progressBillingRoute.index()"
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="loading"
                        :class-name="$theme('button.style.white_border')"
                        prevent-default
                        @clicked="save"
                    >
                        <i class="mr-2 fal fa-floppy-disk"></i>
                        {{ $t('actions.save') }}
                    </LoadingButton>
                    <LoadingButton
                        v-else
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="loading"
                        :class-name="$theme('button.style.white_border')"
                        prevent-default
                        @clicked="goToProgressBilling()"
                    >
                        <i class="mr-2 fal fa-pencil"></i>
                        {{ $t('actions.edit') }}
                    </LoadingButton>

                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        @clicked="validate"
                    >
                        <i class="mr-2 fal fa-check"></i>
                        {{ $t('actions.validate') }}
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { UnwrapNestedRefs } from 'vue';
    import WorksiteProgressBillingForm from '../../../app/worksite/classes/WorksiteProgressBillingForm';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import { ModelType } from '../../../app/base/schemas/BaseSchema';
    import WorksiteProgressBillingEndpoint from '../../../app/worksite/endpoints/WorskiteProgressBillingEndpoint';
    import WorksiteProgressBillingSchema from '../../../app/worksite/schemas/WorksiteProgressBillingSchema';
    import WorksiteProgressBilling from '../../../app/worksite/models/WorksiteProgressBilling';
    import ProgressBillingRoute from '../../../classes/ProgressBillingRoute';

    const worksiteProgressBilling = ref<WorksiteProgressBilling | null>(null);
    const form = ref<WorksiteProgressBillingForm | null>(null);
    const loading = ref<boolean>(false);
    const { t } = useTranslation();

    useModal(
        'worksite:worksite-progress-billing:force-saving-progress-billing-modal',
        (payload: { progressBilling: UnwrapNestedRefs<WorksiteProgressBilling>; form: UnwrapNestedRefs<WorksiteProgressBillingForm> }) => {
            worksiteProgressBilling.value = payload.progressBilling;
            form.value = payload.form;
        }
    );

    const route = useRoute();
    const progressBillingRoute = computed(() => new ProgressBillingRoute(route.params.id));
    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROGRESS_BILLINGS) as WorksiteProgressBillingEndpoint;

    const validate = () => {
        useEvent('worksite:worksite-progress-billing:force-saving-progress-billing-modal:close');
        useEvent('worksite:worksite-progress-billing:validation-modal:open', worksiteProgressBilling);
    };

    const goToProgressBilling = () => {
        useEvent('worksite:worksite-progress-billing:force-saving-progress-billing-modal:close');
        return worksiteProgressBilling.value?.edit(worksiteProgressBilling.value?.worksite);
    };
    const save = async () => {
        // TODO
        loading.value = true;
        const response = ref();
        const schema = form.value?.toSchema();
        response.value = await endpoint.storeOrUpdate(schema as WorksiteProgressBillingSchema);

        if (response.value.error) {
            useToasteoError();
            return;
        }
        loading.value = false;
        useToasteoSuccess();
        useEvent('worksite:worksite-progress-billing:force-saving-progress-billing-modal:close');

        useEvent('worksite:worksite-progress-billing:validation-modal:open', worksiteProgressBilling);
    };
</script>
