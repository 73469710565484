<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
type _VTI_TYPE_InvoiceDocumentType = /* enum */ string
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        withCreateButton?: boolean;
        documentType: _VTI_TYPE_InvoiceDocumentType;
    }
    import { FormInputData } from '~~/composables/useFormInput'
    import { InvoiceDocumentType } from '@/app/invoicing/enums/InvoiceDocumentType'
    
    const select = ref(null);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  withCreateButton: { type: Boolean, default: false },
  documentType: { default: 'quote' }
});
    const inputData: FormInputData = useFormInput(props);
    const displayCreateButton = computed(() => props.withCreateButton && !inputData?.model.value);
</script>

<template>
    <FormElement
        :input-data="inputData"
        @label-clicked="select?.show()"
    >
        <SelectQuote
            ref="select"
            :initial-value="inputData.model"
            :with-create-button="displayCreateButton"
            :document-type="documentType"
        >
            <template
                v-if="inputData.model.value"
                #button
            >
                <div class="flex items-center h-full space-x-3">
                    <a
                        href="#"
                        class="text-sm text-gray-600 transition-all cursor-pointer hover:text-primary"
                        :data-tooltip="$t('actions.unselect')"
                        :data-tooltip-location="'left'"
                        @click.stop.prevent="inputData.model.value = null"
                    >
                        <i class="fa-regular fa-xmark" />
                    </a>
                </div>
            </template>
        </SelectQuote>
    </FormElement>
</template>
