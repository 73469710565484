export const useThemeStore = defineStore('ThemeStore', () => {
    const theme: Ref<string> = ref('light');
    const toggleTheme = () => {
        theme.value = theme.value === 'light' ? 'dark' : 'light';

        theme.value == 'dark' ? document.body.classList.add('dark') : document.body.classList.remove('dark');
    };

    return {
        theme,
        toggleTheme,
    };
});
