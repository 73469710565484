<script setup lang="ts">
    const { goLive, isLoading, modal } = useTenantLiveModeModal();
</script>

<template>
    <Modal
        :name="modal.name"
        modal-style="centered"
    >
        <div class="flex flex-col justify-evenly">
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    <span>{{ $t('tenancy.tenant.mode.modal.title') }}</span>
                </h2>
            </div>
            <div class="px-4 pb-4 text-sm text-gray-600 md:px-6">
                {{ $t('tenancy.tenant.mode.modal.text') }}
            </div>
            <div :class="$theme('modal.footer.container')">
                <div class="flex flex-col">
                    <LoadingButton
                        :loading="isLoading"
                        :class-name="$theme('button.style.warning_gray')"
                        @clicked="goLive"
                    >
                        {{ $t('actions.confirm') }}
                    </LoadingButton>
                </div>
            </div>
        </div>
    </Modal>
</template>
