<script setup lang="ts">
    import WorksiteProfitabilityDocument from '@/app/worksite/models/WorksiteProfitabilityDocument';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteProfitabilityDocumentSchema from '~~/app/worksite/schemas/WorksiteProfitabilityDocumentSchema';
    import { useEvent } from '@/composables/useEventBus';
    import WorksiteProfitabilityDocumentEndpoint from '~~/app/worksite/endpoints/WorksiteProfitabilityDocumentEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Worksite from '~~/app/worksite/models/Worksite';

    interface Fields {
        title: string;
        documentType: string;
        worksite: Worksite | null;
        amount: number;
    }
    const form = useForm<Fields>({
        title: '',
        documentType: 'expense',
        worksite: null,
        amount: 0,
    });

    const { modalName, model } = useModelFormModal<WorksiteProfitabilityDocument, Fields>(
        WorksiteProfitabilityDocument,
        (payload?: FormModalPayload<WorksiteProfitabilityDocument, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<WorksiteProfitabilityDocument, Fields>) => {
        if (!payload) {
            alert('You need to provide the worksite on which you are working...');
            return;
        }

        if (payload.fields?.worksite) {
            form.set('worksite', payload.fields.worksite);
        }

        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
                worksite: payload?.model.worksite,
                amount: payload?.model.amount,
                documentType: payload?.model.documentType,
            });
        }
    };

    const submit = async () => {
        const schema = WorksiteProfitabilityDocumentSchema.make({
            attributes: form.dataExcept(['worksite']),
        });
        schema.attributes!.status = 'null';
        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: WorksiteProfitabilityDocumentEndpoint = EndpointFactory.make(ModelType.WORKSITE_PROFITABILITY_DOCUMENTS) as WorksiteProfitabilityDocumentEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="WorksiteProfitabilityDocument"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('worksite.worksite_profitability_document.edit_form.title') : $t('worksite.worksite_profitability_document.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormSelect
                    :form="form"
                    input-name="documentType"
                    :with-null-option="false"
                    :options="[
                        {
                            value: 'expense',
                            text: $t('worksite_profitability_document_type.expense'),
                        },
                        {
                            value: 'labor',
                            text: $t('worksite_profitability_document_type.labor'),
                        },
                        {
                            value: 'subcontracting',
                            text: $t('worksite_profitability_document_type.subcontracting'),
                        },
                        {
                            value: 'other',
                            text: $t('worksite_profitability_document_type.other'),
                        },
                    ]"
                />
                <FormTwoFields>
                    <template #field-1>
                        <FormInput
                            :form="form"
                            input-name="title"
                            autofocus
                        />
                    </template>
                    <template #field-2>
                        <FormPrice
                            :form="form"
                            input-name="amount"
                        />
                    </template>
                </FormTwoFields>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
