<script setup lang="ts">
    useModal('app:konami-code-modal');
</script>

<template>
    <Modal
        name="app:konami-code-modal"
        :width="1000"
        ref="modal"
    >
        <div class="p-20">
            <div class="flex items-center gap-12">
                <div class="relative -my-[50px]">
                    <div class="absolute top-0 left-0 right-0 h-[80px] bg-white"></div>
                    <div class="absolute bottom-0 left-0 right-0 h-[80px] bg-white"></div>
                    <img
                        src="https://shared-assets.trustup.io/images/hot-bongo.gif"
                        class="h-[400px] rounded"
                    />
                </div>
                <div>
                    <h1 class="mb-2 text-xl text-gray-800">Wow, such skillz</h1>
                    <p class="text-gray-600">
                        Did you know that we are hiring in
                        <a
                            class="underline transition-all hover:text-black"
                            href="https://www.trustup-group.com/carrieres/"
                            target="_blank"
                            rel="noopener"
                            >France and Belgium</a
                        >?
                    </p>
                    <p class="text-gray-600">Might be a good fit with all of time and skills that you have!</p>
                </div>
            </div>
            <p class="relative z-10 text-sm font-thin text-center text-gray-400">(Don't forget to tell the recruiter that you know Bongo, the CTO's dog. You might win some points for free!)</p>
        </div>
    </Modal>
</template>
