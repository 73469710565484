export interface RawPreviewProduct {
    0: string; // ref
    1: string; // title
    2: string; // descirption
    3: number; // price
    4: number; // pruchase price
    5: string; // unit
    6: string; // category
    7: number; // vat
}

export interface PreviewProduct {
    reference: RawPreviewProduct[0];
    title: RawPreviewProduct[1];
    description: RawPreviewProduct[2];
    price: RawPreviewProduct[3];
    purchasePrice: RawPreviewProduct[4];
    productUnit: RawPreviewProduct[5];
    productCategory: RawPreviewProduct[6];
    defaultVatRate: RawPreviewProduct[7];
}

export default class ProductPreview {
    private _reference: string;
    private _title: string;
    private _description: string;
    private _price: number;
    private _purchasePrice: number;
    private _productUnit: string;
    private _productCategory: string;
    private _defaultValueRate: number;

    constructor(product: PreviewProduct) {
        this._reference = product.reference;
        this._title = product.title;
        this._description = product.description;
        this._price = product.price;
        this._purchasePrice = product.purchasePrice;
        this._productUnit = product.productUnit;
        this._productCategory = product.productCategory;
        this._defaultValueRate = product.defaultVatRate;
    }

    get reference() {
        return this._reference;
    }

    set reference(value: string) {
        this.reference = value;
    }

    get title() {
        return this._title;
    }

    set title(value: string) {
        this.title = value;
    }

    get description() {
        return this._description;
    }

    set description(value: string) {
        this.description = value;
    }

    get price() {
        return this._price;
    }

    set price(value: number) {
        this.price = value;
    }

    get purchasePrice() {
        return this._purchasePrice;
    }

    set purchasePrice(value: number) {
        this.purchasePrice = value;
    }

    get productUnit() {
        return this._productUnit;
    }

    set productUnit(value: string) {
        this.productUnit = value;
    }

    get productCategory() {
        return this._productCategory;
    }

    set productCategory(value: string) {
        this.productCategory = value;
    }

    get defaultVatRate() {
        return this._defaultValueRate;
    }

    set defaultVatRate(value: number) {
        this.defaultVatRate = value;
    }
}
