<script setup lang="ts">
    import InvoiceCollection from '~~/app/invoicing/collections/InvoiceCollection';

    const props = defineProps({
  collection: null,
  checkable: { type: Boolean, default: false },
  checked: { default: [] }
});

    const emit = defineEmits(['toggle']);
</script>

<template>
    <table class="w-full mb-8 border border-gray-200 rounded">
        <tbody>
            <tr
                v-for="invoice in collection.get()"
                :key="invoice.getInternalId()"
                @contextmenu="invoice.openContextMenu($event)"
            >
                <td
                    class="p-2 text-sm text-gray-600 border-b border-gray-200"
                    v-if="checkable"
                >
                    <input
                        type="checkbox"
                        class="nice-checkbox"
                        :checked="checked.includes(invoice.getId())"
                        @change="emit('toggle', invoice.getId())"
                    />
                </td>
                <td class="p-2 text-sm text-gray-600 border-b border-gray-200">
                    {{ invoice.number }}
                </td>
                <td class="p-2 text-sm text-gray-600 border-b border-gray-200">
                    {{ invoice.trueDate.toDateString() }}
                </td>
                <td class="p-2 text-sm text-gray-600 border-b border-gray-200">{{ $rounded2(invoice.depositInvoicePercentage) }}%</td>
                <td class="p-2 text-sm text-gray-600 border-b border-gray-200">
                    {{ $price(invoice.total) }}
                </td>
                <td class="p-2 text-sm text-gray-600 border-b border-gray-200">
                    <InvoicingInvoiceStatusBadge :invoice="invoice" />
                </td>
            </tr>
        </tbody>
    </table>
</template>
