<script setup lang="ts">
    import ModelFile from '@/app/common/models/ModelFile';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ModelFileEndpoint from '~~/app/common/endpoints/ModelFileEndpoint';

    const { model } = useModelModal<ModelFile>(ModelFile);
</script>

<template>
    <ModelModal
        :model="ModelFile"
        modal-style="right_panel"
    >
        <div
            v-if="model"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ model.title }}
                </h2>
            </div>

            <!-- Main section -->
            <div :class="$theme('modal.right_panel.section.container')">
                <div
                    v-if="model.thumbnail"
                    class="mb-4"
                >
                    <div class="flex items-center justify-center p-4 bg-neutral-700 rounded">
                        <img
                            :src="model.thumbnail"
                            class="block w-auto h-auto max-w-[500px] max-h-[200px]"
                        />
                    </div>
                </div>
                <div
                    v-if="model.isPdf()"
                    class="mb-4"
                >
                    <div class="block h-full p-4 bg-neutral-700 rounded">
                        <iframe
                            :src="`${model.getDownloadUrl()}#navpanes=0`"
                            height="100%"
                            width="100%"
                            class="min-h-[500px]"
                        />
                    </div>
                </div>

                <InfoText :label="$t('form.labels.file_name')">
                    {{ model.title }}
                </InfoText>
                <InfoText :label="$t('form.labels.size')">
                    {{ model.getMediaReadableSize() }}
                </InfoText>
                <InfoText :label="$t('form.labels.added_at')">
                    {{ model.createdAt?.toShortDayDateString() }}
                </InfoText>
                <InfoText :label="$t('form.labels.added_by')">
                    {{ model.user?.displayName ?? '-' }}
                </InfoText>
                <div v-if="model.isDownloadable()">
                    <LoadingButton @clicked="model.download()">
                        <i class="mr-2 fa-solid fa-download" />
                        <span>{{ $t('actions.download') }}</span>
                    </LoadingButton>
                </div>
            </div>
        </div>
    </ModelModal>
</template>
