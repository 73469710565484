<script lang="ts" setup>
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import GlobalSearchItemCollection from '@/app/common/collections/GlobalSearchItemCollection';
    import GlobalSearchItem from '@/app/common/models/GlobalSearchItem';

    useModal('app:search');

    const form = useForm(
        {
            query: '',
        },
        () => {
            loading.value = true;
            loadDebounced();
        }
    );
    const modal = ref(null);
    const { loadDebounced, loading, collection } = useCollectionLoader<GlobalSearchItem, GlobalSearchItemCollection>(ModelType.GLOBAL_SEARCH_ITEMS, [], () => {
        return {
            limitByModelType: 5,
            filter: {
                search: form.get('query'),
            },
        };
    });
    loading.value = false;

    useGlobalShortcut(['k', 'K'], () => {
        modal.value && modal.value.opened ? useEvent('app:search:close') : useEvent('app:search:open');
    });
</script>

<template>
    <Modal
        ref="modal"
        name="app:search"
        modal-style="centered"
        :show-close-icon="false"
        scrollable
    >
        <FormInput
            without-label
            :form="form"
            input-name="query"
            :placeholder="$t('global_search.placeholder')"
            container-class-name="relative w-full"
            element-class-name=" "
            class-name="pl-16 pr-6 py-6 border-b border-gray-200 w-full rounded-t-lg outline-none text-sm"
            autofocus
        >
            <template #before-input>
                <div class="absolute top-0 bottom-0 left-0 flex items-center pl-6">
                    <i class="text-sm text-gray-600 fa-regular fa-search" />
                </div>
                <div class="absolute top-0 bottom-0 right-0 flex items-center pl-2 pr-6 bg-white border-b border-gray-200 rounded-lg">
                    <span
                        class="px-2 py-1 text-gray-600 bg-white transition-all border border-gray-200 rounded text-[11px] hover:bg-neutral-100 cursor-pointer"
                        @click.prevent.stop="useEvent('app:search:close')"
                    >
                        ESC
                    </span>
                </div>
                <div v-if="form.get('query') !== ''">
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-20">
                        <i
                            class="text-sm text-gray-400 transition-all cursor-pointer hover:text-primary fa-regular fa-times"
                            @click.prevent="form.set('query', '')"
                        />
                    </div>
                </div>
            </template>
        </FormInput>
        <div class="h-full min-h-[500px]">
            <div
                v-if="loading"
                class="flex flex-col gap-6 p-4"
            >
                <div
                    v-for="i in 4"
                    :key="i"
                    class="flex flex-col gap-2"
                >
                    <div
                        v-for="n in 2"
                        :key="n"
                        class="bg-skeleton-loading h-[40px] rounded"
                    ></div>
                </div>
            </div>
            <template v-else>
                <AppSearchModalActions v-if="form.get('query') == ''" />
                <AppSearchModalResults
                    v-else
                    :results="collection"
                />
            </template>
        </div>
    </Modal>
</template>
