<script lang="ts" setup>
    import GlobalSearchItemCollection from '@/app/common/collections/GlobalSearchItemCollection';
    import CalendarEventResult from './CalendarEventResult';
    import ContactResult from './ContactResult';
    import InvoiceResult from './InvoiceResult';
    import LeadResult from './LeadResult';
    import WorksiteResult from './WorksiteResult';
    import TaskResult from './TaskResult';
    import GlobalSearchItem from '@/app/common/models/GlobalSearchItem';
    import Str from '@supercharge/strings';

    const props = defineProps<{
        results: GlobalSearchItemCollection;
    }>();

    const modelTypes = ref(['invoice', 'worksite', 'lead', 'task', 'calendar-event', 'contact']);
    const resultComponent = (result: GlobalSearchItem) => {
        switch (result.modelType) {
            case 'invoice':
                return InvoiceResult;
            case 'worksite':
                return WorksiteResult;
            case 'lead':
                return LeadResult;
            case 'task':
                return TaskResult;
            case 'calendar-event':
                return CalendarEventResult;
            case 'contact':
                return ContactResult;
        }
    };
    const resultsLabel = (modelType: string) => {
        const { t } = useTranslation();
        return t(`global_search.labels.${Str(modelType).snake()}s`);
    };
</script>

<template>
    <div class="h-full">
        <div
            v-if="results.isEmpty()"
            class="p-8"
        >
            <SubmenuEmptyContent />
        </div>
        <template v-else>
            <template
                v-for="modelType in modelTypes"
                :key="modelType"
            >
                <div
                    class="p-4"
                    v-if="results.filterByModelType(modelType).length > 0"
                >
                    <p class="pl-4 mb-2 text-base text-gray-800">
                        {{ resultsLabel(modelType) }}
                    </p>
                    <div class="flex flex-col space-y-1">
                        <template
                            v-for="result in results.filterByModelType(modelType)"
                            :key="result.getInternalId()"
                        >
                            <component
                                :is="resultComponent(result)"
                                :result="result"
                            />
                        </template>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
