<template>
    <div class="flex w-full rounded-l relative bg-transparent border py-2 px-6 min-w-[200px]">
        <button
            data-action="decrement"
            class="text-gray-600 hover:text-gray-700 rounded-l cursor-pointer outline-none"
            @click.prevent="$emit('dec')"
        >
            <span class="text-2xl font-thin">−</span>
        </button>
        <input
            type="number"
            v-model="quantity"
            :max="leftQuantity"
            :min="0"
            class="focus:outline-none text-center w-full font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            name="custom-input-number"
        />
        <button
            data-action="increment"
            class="text-gray-600 hover:text-gray-700 rounded-r cursor-pointer"
            @click.prevent="$emit('inc')"
        >
            <span class="m-auto text-2xl font-thin">+</span>
        </button>
    </div>
</template>
<script setup lang="ts">
    const props = defineProps({
  modelValue: null,
  leftQuantity: null
});

    const emit = defineEmits(["update"]);

    const quantity = computed({
        get() {
            if (!props.modelValue) return undefined;
            return props.modelValue || 0;
        },
        set(newValue) {
            if (!newValue) return emit('update', 0);
            if (newValue > props.leftQuantity) {
                return emit('update', props.leftQuantity);
            }
            return emit('update', newValue);
        },
    });
</script>
