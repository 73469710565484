<script setup lang="ts">
    import Invoice from '../../../app/invoicing/models/Invoice';
    import InvoiceCollection from '../../../app/invoicing/collections/InvoiceCollection';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import TimeActivitySchema from '../../../app/planning/schemas/TimeActivitySchema';
    import useToasteoError from '../../../composables/useToasteoError';
    import useToasteoSuccess from '../../../composables/useToasteoSuccess';
    import TimeActivity from '@/app/planning/models/TimeActivity';
    import Form from '@/classes/Form';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '@/app/base/schemas/BaseSchema';

    interface Fields {
        invoice: string | null;
    }
    const form = useForm<Fields>({
        invoice: null,
    });

    useModal('planning:time-activity:link-invoice', (payload: TimeActivity) => {
        onOpened(payload);
    });

    const timeActivity = ref<TimeActivity | null>();
    const onOpened = async (payload: TimeActivity) => {
        form.reset();
        timeActivity.value = payload;
    };

    const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES);

    const retrieveTimeActivity = async () => {
        if (!timeActivity.value) return;
        const timeActityUpdated = await endpoint.retrieve(timeActivity.value.getId());
        if (timeActityUpdated.error) {
            useToasteoError();
            return;
        }
        useEvent('planning:time-activity:updated', timeActityUpdated.data as TimeActivity);
        useToasteoSuccess();
    };
    const submit = async () => {
        form.load();
        if (!timeActivity.value) return;
        const response = await endpoint.transformToInvoice(timeActivity.value?.getId(), form.get('invoice').getId());
        if (response.error) return useToasteoError();
        await retrieveTimeActivity();
        form.stop();
        close();
    };

    const close = () => {
        form.reset();
        timeActivity.value = null;
        useEvent(`planning:time-activity:link-invoice:close`);
    };
</script>

<template>
    <Modal
        name="planning:time-activity:link-invoice"
        :model="TimeActivity"
        @before-close="form.reset()"
    >
        <div>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{
                        $t('time_activity.link_invoice.modal.title', {
                            title: timeActivity?.title,
                        })
                    }}
                </h2>
            </div>
            <form
                class="min-h-[250px]"
                @submit.prevent="submit"
            >
                <div :class="$theme('modal.padding')">
                    <FormQuoteSelect
                        :document-type="'invoice'"
                        :form="form"
                        form-key="invoice"
                        :placeholder="$t('form.labels.invoice')"
                        :label="$t('form.labels.invoice')"
                        input-name="invoice"
                    >
                    </FormQuoteSelect>
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.confirm') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
