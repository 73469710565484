<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceDiscountEndpoint from '~~/app/invoicing/endpoints/InvoiceDiscountEndpoint';
    import InvoiceDiscount from '~~/app/invoicing/models/InvoiceDiscount';

    const endpoint = EndpointFactory.make(ModelType.INVOICE_DISCOUNTS) as InvoiceDiscountEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: InvoiceDiscount) => {
        const { t } = useTranslation();
        if (!window.confirm(t('invoicing.invoice_discount.confirm_before_deleting'))) {
            return;
        }

        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="InvoiceDiscount"
        :menu-height="100"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
            ref="deleteButton"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
