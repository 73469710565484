<script setup lang="ts">
    import Task from '@/app/task/models/Task';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';

    useModelModal<Task>(
        Task,
        (data: ModalPayload<Task>) => retrieve(data),
        () => (task.value = null)
    );
    const loading: Ref<boolean> = ref(false);
    const task: Ref<Task | null> = reactiveModel(null);

    const retrieve = async (data: ModalPayload<Task>) => {
        loading.value = true;
        const endpoint = EndpointFactory.make(data.model.type) as TaskEndpoint;
        const response = await endpoint.retrieve(data.model.getId(), { include: `${endpoint.include},timeActivities,timeActivities.user,timeActivities.worksite,timeActivities.worksitePhase` });
        task.value = response.data;
        loading.value = false;
    };
</script>

<template>
    <ModelModal
        :model="Task"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            v-if="!loading && task"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ task.title }}
                </h2>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.users')">
                    {{ task.users.map((user) => user.name).join(', ') }}
                </InfoText>
                <InfoText :label="$t('form.labels.date')">
                    {{ task.dueDate?.toDayDateString() ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.contact')">
                    {{ task.contact?.title ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.lead')">
                    {{ task.lead?.title ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.worksite')">
                    {{ task.worksite?.title ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.worksitePhase')">
                    {{ task.worksitePhase?.title ?? '-' }}
                </InfoText>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <div
                    v-if="!task.timeActivities || task.timeActivities?.isEmpty()"
                    class="flex items-center px-8 py-3 space-x-4 bg-white border border-gray-200 rounded"
                >
                    <div>
                        <div class="h-[60px] w-[60px]">
                            <Lottie json="https://shared-assets.trustup.io/lottie/planning-time-activity.json" />
                        </div>
                    </div>
                    <p>
                        {{ $t('task.task.no_time_activity_linked') }}
                    </p>
                </div>

                <div
                    v-if="task.timeActivities && task.timeActivities.isNotEmpty()"
                    class="flex flex-col space-y-2"
                >
                    <PlanningTimeActivityItem
                        v-for="timeActivity in task.timeActivities"
                        :key="timeActivity.getInternalId()"
                        :time-activity="timeActivity"
                    />
                </div>
            </div>

            <!-- Messaging -->
            <div :class="$theme('modal.right_panel.section.container', 'flex-1 flex flex-col')">
                <h2 :class="$theme('modal.right_panel.section.title')">
                    {{ $t('messaging.comments_title') }}
                </h2>
                <Messaging :model="task" />
            </div>
        </div>
    </ModelModal>
</template>
