<script setup lang="ts">
    import ModelFileFolder from '@/app/common/models/ModelFileFolder';
    import '@vuepic/vue-datepicker/dist/main.css';

    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ModelFileFolderSchema from '~~/app/common/schemas/ModelFileFolderSchema';
    import { useEvent } from '@/composables/useEventBus';
    import ModelFileFolderEndpoint from '~~/app/common/endpoints/ModelFileFolderEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Worksite from '~~/app/worksite/models/Worksite';

    interface Fields {
        title: string;
        worksite: Worksite | null;
        parent: ModelFileFolder | null;
    }
    const form = useForm<Fields>({
        title: '',
        worksite: null,
        parent: null,
    });

    const { modalName, model } = useModelFormModal<ModelFileFolder, Fields>(
        ModelFileFolder,
        (payload?: FormModalPayload<ModelFileFolder, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<ModelFileFolder, Fields>) => {
        if (!payload) {
            alert('You need to provide the worksite on which you are working...');
            return;
        }

        if (payload.fields?.worksite) {
            form.set('worksite', payload.fields.worksite);
        }

        if (payload.fields?.parent) {
            form.set('parent', payload.fields.parent);
        }

        if (payload?.model) {
            form.set('title', payload.model.title);
            form.set('parent', payload.model.parent);
        }
    };

    const submit = async () => {
        const schema = ModelFileFolderSchema.make({ attributes: form.dataExcept(['worksite', 'parent']) });
        if (form.get('worksite')) {
            schema.addToOneRelationship('model', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (form.get('parent')) {
            schema.addToOneRelationship('parent', ModelType.MODEL_FILE_FOLDERS, form.get('parent').getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: ModelFileFolderEndpoint = EndpointFactory.make(ModelType.MODEL_FILE_FOLDERS) as ModelFileFolderEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="ModelFileFolder"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('common.model_file_folder.edit_form.title') : $t('common.model_file_folder.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
