<script setup lang="ts">
interface Props {
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Source | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        withCreateButton?: boolean;
    }
    
    import SourceCollection from '../../../app/crm/collections/SourceCollection'
    import SourceEndpoint from '../../../app/crm/endpoints/SourceEndpoint'
    import LeadSource from '../../../app/crm/enums/LeadSource'
    import { ModelType } from '../../../app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    const emit = defineEmits(['selected']);
    
    const props = defineProps({
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' },
  withCreateButton: { type: Boolean, default: false }
});
    const loading = ref(true);
    const submenu = ref(null);
    const sources: Ref<SourceCollection | null> = ref(null);
    const selected: Ref<Source | null> = ref(props.initialValue);
    if (selected.value == '') {
        selected.value = null;
    }
    const query: Ref<string> = ref('');
    const isSelected = (source: Source) => {
        return selected.value && selected.value.getId() === source.getId();
    };
    const select = (source: Source | null) => {
        selected.value = source;
        emit('selected', source);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const show = () => {
        if (!submenu.value.isDisplayed) {
            submenu.value.show();
        }
    };
    const endpoint = EndpointFactory.make(ModelType.SOURCES) as SourceEndpoint;
    const destroy = async (source: Source) => {
        const { t } = useTranslation();
        if (!window.confirm(t('misc.confirm_delete'))) {
            return;
        }
        const response = await endpoint.destroy(source.getId());
        if (response.error) useToasteoError();
        select(null);
        useToasteoSuccess();
    };
    const setValue = (source: Source) => {
        selected.value = source;
    };
    const getSources = async () => {
        loading.value = true;
        const response = await endpoint.index({
            include: '',
            filter: {
                search: query.value,
            },
            page: {
                size: 10,
            },
        });
        if (response.error) {
            useToasteoError();
            submenu.value?.hide();
            return;
        }
        sources.value = response.data;
        loading.value = false;
    };
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getSources"
    >
        <template #default="slotProps">
            <div
                class="flex items-stretch justify-between px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i
                            class="transition-all group-hover:text-primary fa-regular fa-link"
                            :class="selected ? 'text-neutral-600' : 'text-neutral-400'"
                        ></i>
                    </div>
                    <div>
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? `${selected.title || ''}` : $t('selects.source.placeholder')"
                        ></span>
                    </div>
                </div>
                <div>
                    <template v-if="withCreateButton">
                        <a
                            href="#"
                            :class="$theme('button.style.gray_sm', 'hover:bg-neutral-300')"
                            @click.stop.prevent="useEvent('crm:source:form-modal:open')"
                        >
                            <span class="hidden md:inline">
                                {{ $t('actions.add') }}
                            </span>
                            <span class="inline md:hidden">
                                <i class="fa-regular fa-plus" />
                            </span>
                        </a>
                    </template>
                    <slot name="button"></slot>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <p class="mb-2">
                    {{ $t('selects.source.title') }}
                </p>
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        autocomplete="off"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('source.sources.search_input_placeholder')"
                        @keyup.enter.prevent.stop="getSources()"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="getSources()"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="sources?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="source in sources?.get()"
                                :key="source.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(source) }]"
                                @click.stop.prevent="select(source)"
                                @contextmenu="source.openContextMenu($event)"
                            >
                                <div>
                                    <span class="text-sm">{{ `${source.title || ''}` }}</span>
                                </div>
                                <div class="absolute top-0 bottom-0 right-0 flex items-center pr-3 space-x-2">
                                    <div v-if="isSelected(source)">
                                        <a
                                            href="#"
                                            @click.prevent.stop="select(null)"
                                        >
                                            <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                        </a>
                                    </div>

                                    <div v-if="!Object.values(LeadSource).includes(source.title)">
                                        <a
                                            href="#"
                                            @click.prevent.stop="destroy(source)"
                                        >
                                            <i class="text-neutral-400 fa-regular fa-trash hover:text-red-600"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
