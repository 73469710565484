<script setup lang="ts">
    import { UnwrapNestedRefs } from 'vue';
    import Invoice from '../../../app/invoicing/models/Invoice';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import InvoiceEndpoint from '../../../app/invoicing/endpoints/InvoiceEndpoint';
    import InvoiceSchema from '../../../app/invoicing/schemas/InvoiceSchema';
    import Lead from '@/app/crm/models/Lead';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    interface Fields {
        quote: Invoice | null;
    }

    interface SchemaFields {
        lead: Lead | null;
    }
    const form = useForm<Fields>({
        quote: null,
    });
    const formSchema = useForm<SchemaFields>({
        lead: null,
    });
    const lead = ref<Lead | null>(null);
    useModal('crm:lead:quote-modal', (payload: { lead: UnwrapNestedRefs<Lead> }) => onOpened(payload));

    const onOpened = async (payload: { lead: UnwrapNestedRefs<Lead> }): Promise<void> => {
        lead.value = payload.lead;
        formSchema.set('lead', lead.value);
    };

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;

    const submit = async () => {
        const schema = InvoiceSchema.make({ attributes: formSchema.dataExcept(['lead']) });
        if (form.get('quote')) {
            schema.addToOneRelationship('lead', ModelType.LEADS, formSchema.fields.lead?.getId() as string);
            schema.id = form.fields.quote?.getId();
        }
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }
        lead.value?.invoices.add(response.data);
        // lead.value?.setRelationship("invoices", response.data);
        useEvent('crm:lead:updated', lead.value);
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`crm:lead:quote-modal:close`);
    };
</script>

<template>
    <div>
        <Modal
            ref="modal"
            name="crm:lead:quote-modal"
        >
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('crm.lead.quotes_modal.title') }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div
                    :class="$theme('modal.padding')"
                    class="space-y-4"
                >
                    <FormQuoteSelect
                        :form="form"
                        input-name="quote"
                        :label="$t('form.labels.quote')"
                    />
                </div>
                <div :class="$theme('modal.footer.container')">
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.import') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
            <!-- <form @submit.prevent="submit">
      <div :class="$theme('modal.padding')"></div>

      <div :class="$theme('modal.footer.container')">
        <div :class="$theme('modal.footer.button_container')">
          <LoadingButton
            :extra-class-name="$theme('modal.footer.button')"
            :class-name="$theme('button.style.cancel')"
            prevent-default
            @clicked="close()"
            >{{ $t("actions.cancel") }}</LoadingButton
          >
          <LoadingButton
            :extra-class-name="$theme('modal.footer.button')"
            :loading="form.isLoading"
            type="submit"
            >{{ $t("actions.save") }}</LoadingButton
          >
        </div>
      </div> -->
            <!-- </form> -->
        </Modal>
    </div>
</template>
