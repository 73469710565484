import { onMounted } from 'vue';
import { useListen, useEvent } from './useEventBus';
import BaseModel from '~~/app/base/models/BaseModel';

export default function <Model extends BaseModel, Fields>(modelClass: typeof BaseModel, onOpened: Function | null = null, onClosed: Function | null = null) {
    const modalName = `${modelClass.prefix}:form-modal`;
    const model: Ref<Model | null> = ref(null);
    const modal = ref(null);
    const opened = ref(false);

    useListen(`${modalName}:open`, (payload?: FormModalPayload<Model, Fields>) => {
        model.value = payload?.model || null;
        useEvent('modal:open', modalName);
        if (onOpened) onOpened(payload);
        opened.value = true;
    });
    useListen(`${modalName}:close`, () => {
        model.value = null;
        useEvent('modal:close', modalName);
    });

    useListen('modal:close', (name: string) => {
        if (name !== modalName) return;
        if (onClosed) onClosed();
        opened.value = false;
    });

    return {
        opened,
        modalName,
        model,
        modal,
    };
}
