import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type TermsOfServiceAcceptationRelationships = {
    user: ToOneRelationship<ModelType.USERS>;
    tenant: ToOneRelationship<ModelType.TENANTS>;
    termsOfService: ToOneRelationship<ModelType.TERMS_OF_SERVICES>;
};

type TermsOfServiceAcceptationAttributes = {
    ipAddress: string;
    userAgent: string;
    locale: string;
    location: object;
    device: object;
};

type TermsOfServiceAcceptationMeta = {};

export default class TermsOfServiceAcceptationSchema extends BaseSchema<TermsOfServiceAcceptationAttributes, TermsOfServiceAcceptationMeta, TermsOfServiceAcceptationRelationships> {
    protected type: ModelType = ModelType.TERMS_OF_SERVICE_ACCEPTATIONS;
}
