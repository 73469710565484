import { UnwrapNestedRefs } from 'vue';
import Tenant from '@/app/auth/models/Tenant';
import { MODE } from '@/app/enums/tenant/Mode';

const useTenantLiveModeModal = () => {
    const name = `${Tenant.prefix}:mode:modal` as const;
    const tenant = ref<UnwrapNestedRefs<Tenant>>();
    const { switchMode, isLoading } = useTenantSwitchMode();
    useModal(name, (model: UnwrapNestedRefs<Tenant>) => (tenant.value = model));
    const goLive = () =>
        switchMode({
            tenant: tenant.value as UnwrapNestedRefs<Tenant>,
            mode: MODE.LIVE,
        });

    const open = (tenant: UnwrapNestedRefs<Tenant>) => useEvent(`${name}:open`, tenant);

    return {
        tenant,
        goLive,
        isLoading,
        modal: {
            open,
            name,
        },
    };
};

export default useTenantLiveModeModal;
