<script setup lang="ts">
    import Notification from '@/app/common/models/Notification';

    const props = defineProps({
  notification: null
});

    const loading = ref(false);

    const open = async (): Promise<void> => {
        loading.value = true;
        const { tenant } = useAuthStore();
        const { relatedToId, notificationType, tenantId } = props.notification;
        if (!relatedToId) return;
        const { setValue, openModal } = useNotificationModal();
        setValue({
            relatedToId,
            notificationType,
        });
        const isCurrentTenant = tenant?.getId() === tenantId;

        if (isCurrentTenant) {
            await openModal();
            loading.value = true;
            return;
        }

        navigateTo({
            name: 'switch-tenant',
            query: { tenant: tenantId },
        });
    };
</script>

<template>
    <div
        :class="$theme('submenu.item', 'cursor-pointer relative')"
        @click="open"
    >
        <Loader v-if="loading" />
        <div class="w-[40px] flex-none flex items-center justify-center">
            <i :class="`fa-regular text-lg fa-${notification.icon}`"></i>
        </div>
        <div class="flex-1 whitespace-normal">
            <p class="text-sm text-gray-800">
                {{ notification.title }}
            </p>
            <p
                v-if="notification.text"
                class="text-[10px] text-gray-400 -mt-1"
            >
                {{ notification.text }}
            </p>
            <p class="text-[11px] text-gray-600">
                {{ notification.createdAt?.receivedAt() }}
            </p>
        </div>
    </div>
</template>
