<script setup lang="ts">
    import InvoicingPaymentRemindersSetting from '@/app/invoicing/models/InvoicingPaymentRemindersSetting';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoicingPaymentRemindersSettingSchema from '~~/app/invoicing/schemas/InvoicingPaymentRemindersSettingSchema';
    import { useEvent } from '@/composables/useEventBus';
    import InvoicingPaymentRemindersSettingEndpoint from '~~/app/invoicing/endpoints/InvoicingPaymentRemindersSettingEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    interface Fields {
        level: number;
        enabled: boolean;
        dueDays: number;
        emailCopy: boolean;
        emailSubject: TranslatedField;
        emailText: TranslatedField;
    }
    const form = useForm<Fields>({
        level: 1,
        enabled: true,
        dueDays: 15,
        emailCopy: true,
        emailSubject: {},
        emailText: {},
    });
    form.setTranslatedFields(['emailSubject', 'emailText']);

    const { modalName, model } = useModelFormModal<InvoicingPaymentRemindersSetting, Fields>(
        InvoicingPaymentRemindersSetting,
        (payload?: FormModalPayload<InvoicingPaymentRemindersSetting, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<InvoicingPaymentRemindersSetting, Fields>) => {
        if (payload?.model) {
            form.fillWithModel(payload.model);
        }
        if (payload?.fields) {
            form.fill(payload.fields);
        }
    };

    const submit = async () => {
        let schema = InvoicingPaymentRemindersSettingSchema.make({ attributes: form.data() });
        schema.attributes!.emailCopy = !!form.get('emailCopy');
        if (model.value) {
            schema.id = model.value?.getId();
        }

        let endpoint: InvoicingPaymentRemindersSettingEndpoint = EndpointFactory.make(ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS) as InvoicingPaymentRemindersSettingEndpoint;
        let response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoicingPaymentRemindersSetting"
        scrollable
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('invoicing.invoicing_payment_reminders_settings.edit_form.title') : $t('invoicing.invoicing_payment_reminders_settings.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <div class="flex items-center space-x-4">
                    <div class="w-[140px]">
                        <FormInput
                            :form="form"
                            input-name="level"
                            input-type="number"
                            autofocus
                            :label="$t('form.labels.number')"
                            :tooltip="$t('invoicing.invoicing_payment_reminders_settings.number_tooltip')"
                        />
                    </div>
                    <div class="w-[140px]">
                        <FormInput
                            :form="form"
                            input-name="dueDays"
                            input-type="number"
                            :label="$t('form.labels.dueAt')"
                            :tooltip="$t('invoicing.invoicing_payment_reminders_settings.due_days_tooltip')"
                        />
                    </div>
                    <div class="pt-5">
                        <FormCheckbox
                            :form="form"
                            input-name="enabled"
                        />
                    </div>
                </div>
                <template v-if="form.get('enabled')">
                    <FormInput
                        :form="form"
                        input-name="emailSubject"
                        translated
                        with-variables
                    />
                    <FormEditor
                        :form="form"
                        input-name="emailText"
                        translated
                    />
                    <FormCheckbox
                        :form="form"
                        input-name="emailCopy"
                        :label="$t('form.labels.copy')"
                    />
                </template>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
