<template>
    <div class="flex items-center justify-center space-x-2">
        <button
            data-action="increment"
            class="text-gray-600 hover:text-gray-700 cursor-pointer px-2 border rounded-lg h-8 items-center justify-center flex"
            @click.prevent="$emit('dec')"
        >
            <i class="fa-solid fa-minus text-xs"></i>
        </button>
        <div class="flex relative bg-transparent border rounded-lg py-2 px-6 min-w-[100px] max-w-[100px]">
            <input
                v-model="quantity"
                type="number"
                pattern="[0-9]*"
                :min="0"
                class="w-[50px] focus:outline-none text-center font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                name="custom-input-number"
            />
        </div>
        <button
            data-action="decrement"
            class="text-gray-600 hover:text-gray-700 rounded-lg cursor-pointer outline-none px-2 border h-8 items-center justify-center flex"
            @click.prevent="$emit('inc')"
        >
            <i class="fa-solid fa-plus text-xs"></i>
        </button>
    </div>
</template>
<script setup lang="ts">
    const props = defineProps({
  modelValue: null
});

    const emit = defineEmits(["update"]);

    const quantity = computed({
        get() {
            if (!props.modelValue) return 0;
            return props.modelValue || 0;
        },
        set(newValue) {
            if (!newValue) return emit('update', 0);
            if (newValue <= 0) {
                return emit('update', 0);
            }
            return emit('update', newValue);
        },
    });
</script>
