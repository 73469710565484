<script setup lang="ts">
    import Source from '../../../app/crm/models/Source';
    import SourceEndpoint from '../../../app/crm/endpoints/SourceEndpoint';
    import SourceSchema from '../../../app/crm/schemas/SourceSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    interface Fields {
        title: string;
    }
    const form = useForm<Fields>({
        title: '',
    });

    const { modalName, model } = useModelFormModal<Source, Fields>(
        Source,
        (payload?: FormModalPayload<Source, Fields>) => onOpened(payload),
        () => {
            form.reset();
            useIgnore('crm:source:created');
        }
    );

    // const { collection, load } = useCollectionLoader<Source, SourceCollection>(
    //   ModelType.INVOICES,
    //   [],
    //   () => {
    //     return {};
    //   },
    // );
    const loading = ref<boolean>(false);
    const onOpened = async (payload?: FormModalPayload<Source, Fields>) => {
        loading.value = true;
        fillForm(payload);
    };

    const fillForm = (payload?: FormModalPayload<Source, Fields>) => {
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
            });
        }
    };

    const submit = async () => {
        const schema = SourceSchema.make({
            attributes: form.fields,
        });

        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: SourceEndpoint = EndpointFactory.make(ModelType.SOURCES) as SourceEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="Source"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('crm.source.edit_form.title') : $t('crm.source.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
            </div>

            <div :class="$theme('modal.footer.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
