import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoiceTemplateSectionRelationships = {
    invoiceTemplate: ToOneRelationship<ModelType.INVOICE_TEMPLATES>;
    invoiceTemplateSectionColumns: ToManyRelationship<ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS>;
};

type InvoiceTemplateSectionAttributes = {
    title: string;
    content: string | null;
    style: object;
    beforeTable: boolean;
    order: number;
};

type InvoiceTemplateSectionMeta = {};

export default class InvoiceTemplateSectionSchema extends BaseSchema<InvoiceTemplateSectionAttributes, InvoiceTemplateSectionMeta, InvoiceTemplateSectionRelationships> {
    protected type: ModelType = ModelType.INVOICE_TEMPLATE_SECTIONS;
}
