<script lang="ts" setup>
    import GlobalSearchItem from '@/app/common/models/GlobalSearchItem';

    const props = defineProps({
  result: null
});

    const icon = computed(() => {
        if (props.result.task.isCompleted) {
            return 'fa-check-circle';
        }
        if (props.result.task.isDue) {
            return 'fa-circle-xmark';
        }
        return 'fa-circle';
    });
</script>

<template>
    <AppSearchModalResult
        :icon="icon"
        :result="result"
    >
        <template v-slot>
            <span class="block text-sm text-gray-800">
                {{ result.title }}
            </span>
            <span class="block text-xs text-gray-600">
                <span>{{ result.task.dueDate?.toEUDateString() }}</span>
                <template v-if="result.task.worksite">
                    <span
                        class="mx-1"
                        v-if="result.task.dueDate"
                        >&middot;</span
                    >
                    <span>
                        {{ result.task.worksite.title }}
                    </span>
                </template>
            </span>
        </template>
        <template v-slot:right>
            <UsersAvatarList
                :users="result.task.users"
                size="w-[24px] h-[24px]"
            />
        </template>
    </AppSearchModalResult>
</template>
