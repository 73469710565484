<script setup lang="ts">
    import User from '~~/app/auth/models/User';

    const { model } = useModelModal<User>(User, (data: ModalPayload<User>) => retrieve(data));

    const retrieve = async (data: ModalPayload<User>) => {
        // TODO:
        // console.log('Retrieve user tasks, user time activities, user calendar events...', data.model, data.model.options, data.model.hourlyRate);
    };
</script>

<template>
    <ModelModal
        :model="User"
        modal-style="right_panel"
    >
        <div
            :class="$theme('modal.right_panel.content')"
            v-if="model"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text', 'flex items-center space-x-4')">
                    <AuthUserAvatar
                        :user="model"
                        :clickable-avatar="false"
                    />
                    <span>{{ model.name }}</span>
                </h2>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.name')">
                    {{ model.name }}
                </InfoText>
                <InfoText :label="$t('form.labels.email')">
                    {{ model.email }}
                </InfoText>
                <InfoText :label="$t('form.labels.phone')">
                    {{ model.phone }}
                </InfoText>
                <InfoText :label="$t('form.labels.role')">
                    {{ $t(`auth.user_role.${model.role}`) }}
                </InfoText>
                <InfoText :label="$t('form.labels.status')">
                    {{ model.isActive ? $t('misc.active') : $t('misc.inactive') }}
                </InfoText>
                <InfoText :label="$t('form.labels.color')">
                    <div
                        class="w-[150px] h-6 rounded"
                        :style="`background-color: ${model.color ?? 'black'}`"
                    ></div>
                </InfoText>
                <InfoText :label="$t('form.labels.hourlyCost')">
                    {{ model.hourlyCost ? $priceFromEuros(model.hourlyCost) : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.hourlyRate')">
                    {{ model.hourlyRate ? $priceFromEuros(model.hourlyRate) : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.weeklyHours')">
                    {{ model.weeklyHours ? `${model.weeklyHours}h` : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.defaultTimeActivityTitle')">
                    {{ model.timeActivityDefaultTitle ?? '-' }}
                </InfoText>
            </div>
        </div>
    </ModelModal>
</template>
