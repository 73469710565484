<script setup lang="ts">
    import TermsOfService from '@/app/common/models/TermsOfService';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { vScroll } from '@vueuse/components';
    import type { UseScrollReturn } from '@vueuse/core';
    import TermsOfServiceAcceptationSchema from '@/app/common/schemas/TermsOfServiceAcceptationSchema';
    import { storeToRefs } from 'pinia';
    import { useGeolocation } from '@vueuse/core';

    const loading = ref(false);
    const termsOfService = reactiveModel<TermsOfService>(null);
    const disabled = ref(true);
    const { tenant, authed } = storeToRefs(useAuthStore());
    const { coords, locatedAt, error, resume, pause } = useGeolocation();

    const onScroll = (state: UseScrollReturn) => {
        disabled.value = state.arrivedState.bottom !== true;
    };

    const download = () => {
        window.open(termsOfService.value?.pdfUrl, '_blank');
    };

    const accept = async (payload: LoadingButtonClickedEventPayload) => {
        payload.load();
        const endpoint = EndpointFactory.make(ModelType.TERMS_OF_SERVICE_ACCEPTATIONS);
        const schema = TermsOfServiceAcceptationSchema.make({
            attributes: {
                userAgent: window.navigator.userAgent,
                device: {
                    screen: {
                        width: window.screen.width,
                        height: window.screen.height,
                        pixelRatio: window.devicePixelRatio,
                    },
                    language: window.navigator.language,
                },
                locale: useTranslation()?.getCurrentLocale() ?? useGetDefaultLocale(),
                location: {
                    latitude: coords.value?.latitude ?? null,
                    longitude: coords.value?.longitude ?? null,
                    accuracy: coords.value?.accuracy ?? null,
                    altitude: coords.value?.altitude ?? null,
                    altitudeAccuracy: coords.value?.altitudeAccuracy ?? null,
                    heading: coords.value?.heading ?? null,
                    speed: coords.value?.speed ?? null,
                    locatedAt: locatedAt.value ?? null,
                },
            },
        });
        schema.addToOneRelationship('user', ModelType.USERS, authed.value?.getId());
        schema.addToOneRelationship('tenant', ModelType.TENANTS, tenant.value?.getId());
        schema.addToOneRelationship('termsOfService', ModelType.TERMS_OF_SERVICES, termsOfService.value?.getId());
        const response = await endpoint.store(schema);

        if (response.data) {
            useToasteoSuccess(useTranslate('common.terms-of-service.accept-modal.success'));
            useEvent('common:terms-of-service:accept-modal:close');
        } else {
            useToasteoError();
        }
        payload.stop();
    };

    useModal('common:terms-of-service:accept-modal', async (id: string) => {
        loading.value = true;

        const endpoint = EndpointFactory.make(ModelType.TERMS_OF_SERVICES);
        const response = await endpoint.retrieve(id);
        termsOfService.value = response.data;

        loading.value = false;
        resume();
    });
</script>

<template>
    <Modal
        name="common:terms-of-service:accept-modal"
        :width="800"
        :show-close-icon="false"
        ref="modal"
        scrollable
    >
        <div
            class="py-12"
            v-if="loading"
        >
            <div class="relative py-12">
                <Loader />
            </div>
        </div>
        <template v-else>
            <div class="flex flex-col p-8 space-y-8">
                <div class="text-center">
                    <h2 class="mb-2 text-2xl font-semibold">{{ $t('common.terms-of-service.accept-modal.title') }}</h2>
                    <p class="text-sm text-gray-500">{{ $t('common.terms-of-service.accept-modal.text') }}</p>
                </div>
                <div class="relative">
                    <div
                        class="p-4 prose-sm rounded bg-neutral-100 h-[400px] 2xl-h-[600px] overflow-y-auto nice-scrollbar"
                        v-html="termsOfService.html"
                        v-scroll="onScroll"
                    ></div>
                    <transition
                        enter-active-class="duration-300 ease-out"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="duration-300 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <div
                            class="pointer-events-none absolute bottom-0 left-0 right-0 h-[200px]"
                            v-if="disabled"
                        >
                            <div class="z-[1] relative pb-4 w-full h-full flex items-center justify-end flex-col space-y-2">
                                <i class="text-4xl animate-bounce fa-regular fa-arrow-down text-neutral-400" />
                                <p class="text-sm text-center text-neutral-600">
                                    {{ $t('common.terms-of-service.accept-modal.please_scroll_text') }}
                                </p>
                            </div>
                            <div
                                class="absolute bottom-0 left-0 right-0 h-[200px]"
                                style="background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)"
                            ></div>
                        </div>
                    </transition>
                </div>
                <div class="flex flex-col space-y-2">
                    <LoadingButton
                        :class-name="$theme('button.style.link')"
                        @clicked="download"
                        >{{ $t('actions.download') }}</LoadingButton
                    >
                    <LoadingButton
                        :disabled="disabled"
                        :class-name="disabled ? $theme('button.style.disabled') : $theme('button.style.default')"
                        extra-class-name="w-full"
                        @clicked="accept"
                    >
                        {{ $t('actions.accept') }}
                    </LoadingButton>
                </div>
            </div>
        </template>
    </Modal>
</template>
