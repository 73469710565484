import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type VatRateRelationships = {};

type VatRateAttributes = {
    title: string;
    rate: number;
    belgiumCocontractorMention: boolean;
    belgiumRenovationMention: boolean;
    franceCocontractorMention: boolean;
};

type VatRateMeta = {};

export default class VatRateSchema extends BaseSchema<VatRateAttributes, VatRateMeta, VatRateRelationships> {
    protected type: ModelType = ModelType.VAT_RATES;
}
