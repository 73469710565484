<template>
    <div>
        <Modal
            ref="modal"
            name="invoicing:invoice-annex-pdf:annex-pdf-modal"
        >
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoice_annex_pdf.modal.title.success')"
                    :subtitle="$t('invoice_annex_pdf.modal.text.success')"
                >
                    <div class="flex justify-center space-x-4">
                        <LoadingButton
                            :annex-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>

            <div v-else>
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">
                        {{ $t('invoice_annex_pdf.modal.title') }}
                    </h2>
                </div>

                <form
                    class="mb-4"
                    @submit.prevent="submit"
                >
                    <div
                        :class="$theme('modal.padding')"
                        class="space-y-4"
                    >
                        <FormCheckbox
                            :form="form"
                            input-name="isDefault"
                        />

                        <div class="flex flex-row-reverse items-center w-full space-x-2 space-x-reverse md:flex-auto">
                            <div class="w-full">
                                <label
                                    for="type-check"
                                    class="block inline-block mb-1 text-sm text-gray-400 cursor-pointer"
                                >
                                    {{ $t('invoice_annex_pdf.modal.document_type.checkboxe.label') }}
                                </label>
                            </div>
                            <input
                                id="type-check"
                                v-model="checked"
                                class="nice-checkbox"
                                type="checkbox"
                            />
                        </div>

                        <FormSelect
                            v-if="checked"
                            :form="form"
                            input-name="documentType"
                            with-null-option
                            :label="$t('form.labels.documentType')"
                        >
                            <option
                                v-for="(document, index) in Object.values(InvoiceDocumentType)"
                                :key="index"
                                :value="document"
                            >
                                {{ $t(`invoice_document_type.${document}`) }}
                            </option>
                        </FormSelect>

                        <div class="mt-4">
                            <InvoicingSettingsInvoiceAnnexPdfImportPdf
                                :file="form.get('file')"
                                :label="inputLabel"
                                @file-changed="($event) => fileChanged($event)"
                            />
                        </div>

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice-annex-pdf:annex-pdf-modal:close')"
                                >{{ $t('actions.cancel') }}</LoadingButton
                            >
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :loading="form.isLoading"
                                :class-name="!form.get('file') ? $theme('button.style.disabled') : $theme('button.style.default')"
                                :disabled="!form.get('file')"
                                type="submit"
                            >
                                <i class="mr-2 fal fa-check"></i>
                                {{ $t('actions.add') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import EndpointFactory from '../../../../app/factories/EndpointFactory';
    import { ModelType } from '../../../../app/base/schemas/BaseSchema';
    import { InvoiceDocumentType } from '../../../../app/invoicing/enums/InvoiceDocumentType';
    import InvoiceAnnexPdfEndpoint from '../../../../app/invoicing/endpoints/InvoiceAnnexPdfEndpoint';

    export interface annexForm {
        file: File;
        documentType: InvoiceDocumentType;
        isDefault: boolean;
    }
    const form = useForm<{
        file?: File;
        documentType?: InvoiceDocumentType;
        isDefault: boolean;
    }>({
        file: null,
        documentType: null,
        isDefault: true,
    });

    const checked = ref(false);
    const success = ref(false);
    const { t } = useTranslation();
    const file = ref<File>();
    const inputLabel = ref<string>(t('actions.upload'));

    const resetFields = () => {
        file.value = null;
        checked.value = false;
        success.value = false;
        inputLabel.value = t('actions.upload');
    };
    useModal('invoicing:invoice-annex-pdf:annex-pdf-modal', () => {
        form.reset();
        resetFields();
    });

    const endpoint = EndpointFactory.make(ModelType.INVOICES_ANNEX_PDFS) as InvoiceAnnexPdfEndpoint;

    const fileChanged = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            form.set('file', target.files[0]);
        }
        if (form.get('file')) inputLabel.value = form.get('file').name;
    };
    const submit = async () => {
        const response = await form.loadUntil(endpoint.upload(form.fields, checked));
        if (response.error) return useToasteoError();
        useEvent('invoicing:invoice-annex-pdf:annex-pdf-modal:created', response.data);
        success.value = true;
        useToasteoSuccess();
    };

    const close = () => {
        useEvent('invoicing:invoice-annex-pdf:annex-pdf-modal:close');
        success.value = false;
        form.reset();
    };
</script>
