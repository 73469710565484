<script setup lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { v4 as uuid } from 'uuid';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import TimeActivityCollection from '../../../app/planning/collections/TimeActivityCollection';
    import Worksite from '../../../app/worksite/models/Worksite';
    import TimeActivity from '@/app/planning/models/TimeActivity';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import '@vuepic/vue-datepicker/dist/main.css';

    interface Fields {
        timeActivities: TimeActivity[] | null;
        startAt: string;
        endsAt: string | null;
        worksite: string | null;
    }
    const form = useForm<Fields>({
        timeActivities: null,
        startAt: moment().startOf('isoWeek').toDateTimeString(),
        endsAt: moment().endOf('isoWeek').subtract(1, 'day').toDateTimeString(),
        worksite: null,
    });

    useModal('planning:time-activity:invoice-multiple', (payload: any) => {
        onOpened(payload);
    });

    const dateRange = computed({
        get() {
            return [moment(form.get('startAt')), moment(form.get('endsAt'))];
        },
        set(value: Array<moment.Moment>) {
            form.set('startAt', moment(value[0]).toDateTimeString());
            form.set('endsAt', moment(value[1]).toDateTimeString());
        },
    });

    const getFilter = () => {
        const filter = {
            invoiced: false,
            dateBetween: `${moment(form.get('startAt'))?.toDateString()},${moment(form.get('endsAt'))?.toDateString()}`,
        };

        if (form.get('worksite')) {
            filter.worksite = [form.get('worksite').getId()];
        }
        return filter;
    };

    const { collection, loading, load } = useCollectionLoader<TimeActivity, TimeActivityCollection>(ModelType.TIME_ACTIVITIES, [], () => {
        return {
            filter: getFilter(),
        };
    });

    const onOpened = async (payload: any) => {
        form.reset();
        form.set('startAt', payload.get('start'));
        form.set('endsAt', payload.get('end').subtract(1, 'day'));
        await load();
        setTimeActivities();
    };

    const setTimeActivities = () => {
        if (collection.value?.isEmpty()) {
            form.set('timeActivities', null);
            key.value = uuid();

            return;
        }
        // const timeActivitiesId = collection.value?.items?.flatMap(
        //   (timeActivity: TimeActivity) => {
        //     return timeActivity.getId();
        //   },
        // );
        form.set('timeActivities', collection.value?.items);
        key.value = uuid();
    };

    const key = ref();
    const setAndLoad = async (worksite: Worksite | null) => {
        if (worksite) form.set('worksite', worksite);
        await load();
        setTimeActivities();
    };

    const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES);

    const submit = async () => {
        if (!form.get('timeActivities').length) return useToasteoError('time_activity.invoice_multiple.is_empty');
        form.load();
        const uuids = form.get('timeActivities').map((timeActivity: TimeActivity) => timeActivity.getId());
        const response = await endpoint.transformActivitiesToInvoice(uuids);

        if (response.error) {
            useToasteoError();
            return;
        }
        const updatedActivities = await endpoint.index({
            filter: {
                id: uuids,
            },
        });

        form.stop();

        useToasteoSuccess();
        updatedActivities.data?.items.forEach((timeActivity) => {
            useEvent('planning:time-activity:updated', timeActivity);
        });

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`planning:time-activity:invoice-multiple:close`);
    };
</script>

<template>
    <Modal
        name="planning:time-activity:invoice-multiple"
        :model="TimeActivity"
        @before-close="form.reset()"
    >
        <ModalLoader v-if="loading"></ModalLoader>
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('time_activity.invoice_multiple.modal.title') }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding', 'space-y-4')">
                    <FormDate
                        :form="form"
                        input-name="startAt"
                        alignment="bottom-full"
                        position="left-0"
                        :label="$t('form.labels.dates')"
                    >
                        <template #input="{ toggle }">
                            <div
                                :class="$theme('form.input', 'w-full flex items-center space-x-4 cursor-pointer')"
                                @click.prevent="toggle()"
                            >
                                <i class="fa-regular fa-calendar"></i>
                                <span>
                                    {{ moment(form.get('startAt')).toShortDayDateString() }} -
                                    {{ moment(form.get('endsAt')).toShortDayDateString() }}
                                </span>
                            </div>
                        </template>
                        <template #datepicker="{ hide }">
                            <VueDatePicker
                                v-model="dateRange"
                                inline
                                auto-apply
                                range
                                :locale="useGetCurrentMomentLocale()"
                                :partial-range="false"
                                :enable-time-picker="false"
                                multi-calendars
                                :multi-static="false"
                                @update:model-value="
                                    hide;
                                    setAndLoad();
                                "
                            />
                        </template>
                    </FormDate>
                    <FormWorksiteSelect
                        :form="form"
                        input-name="worksite"
                        :label="$t('form.filter.worksite')"
                        :placeholder="$t('form.filter.worksite')"
                        @selected="(worksite) => setAndLoad(worksite)"
                    >
                    </FormWorksiteSelect>
                    <FormTimeActivitiesSelect
                        :key="key"
                        :form="form"
                        :collection="collection"
                        input-name="timeActivities"
                        :multiple="true"
                        :close-on-select="false"
                        form-key="timeActivities"
                    >
                    </FormTimeActivitiesSelect>
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.confirm') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
