<script setup lang="ts">
    import ProductCategory from '@/app/inventory/models/ProductCategory';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ProductCategorySchema from '~~/app/inventory/schemas/ProductCategorySchema';
    import { useEvent } from '@/composables/useEventBus';
    import ProductCategoryEndpoint from '~~/app/inventory/endpoints/ProductCategoryEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    interface Fields {
        title: string;
    }
    const form = useForm<Fields>({
        title: '',
    });

    const { modalName, model } = useModelFormModal<ProductCategory, Fields>(
        ProductCategory,
        (payload?: FormModalPayload<ProductCategory, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<ProductCategory, Fields>) => {
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
            });
        }
    };

    const submit = async () => {
        const schema = ProductCategorySchema.make({ attributes: form.data() });
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: ProductCategoryEndpoint = EndpointFactory.make(ModelType.PRODUCT_CATEGORIES) as ProductCategoryEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        useToasteoSuccess();
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="ProductCategory"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('inventory.product_category.edit_form.title') : $t('inventory.product_category.create_form.title') }}
            </h2>
        </div>
        <form
            method="POST"
            action="/"
            @submit.prevent.stop="submit"
        >
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
            </div>

            <div :class="$theme('modal.footer.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
