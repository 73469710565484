<template>
    <div class="max-w-full overflow-x-auto nice-scrollbar">
        <table class="w-full border-t border-l border-gray-200">
            <thead>
                <tr class="border-b border-r border-gray-200 whitespace-nowrap">
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.reference') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.title') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.description') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.price') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.purchase_price') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.unity') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.category') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('inventory.product.import_form.preview_table.vat_number') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="product in productPreviews"
                    :key="product.reference"
                    class="border-b border-r border-gray-200"
                >
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.reference ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.title ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.description ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.price ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.purchasePrice ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.productUnit ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.productCategory ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ product.defaultVatRate ?? '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" setup>
    import ProductPreview, { RawPreviewProduct } from '../../../app/common/classes/ProductPreview';
    import PreviewProductFactory from '../../../app/common/Factories/PreviewProductFactory';
    const props = defineProps({
  products: null
});

    const productPreviews = ref<ProductPreview[]>([]);
    const init = () => {
        props.products?.forEach((product: RawPreviewProduct) => {
            const factory = new PreviewProductFactory();
            const formatedProduct = factory.create(product);
            productPreviews.value.push(formatedProduct);
        });
    };

    onMounted(() => {
        init();
    });
</script>
