<script lang="ts" setup>
    import Invoice from '~/app/invoicing/models/Invoice';
    import BankAccountTransaction from '~/app/bankaccount/models/BankAccountTransaction';
    import InvoicePaymentSchema from '~/app/invoicing/schemas/InvoicePaymentSchema';
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';

    const { t } = useTranslation();
    const { modalName } = useModal('bankaccount:bank-account-transaction:assign-invoice', (data: { transaction: BankAccountTransaction }) => {
        transaction.value = data.transaction;
        form.set('amount', transaction.value.amount);
        form.set('paidAt', transaction.value.executedAt.toUtcDateTimeString());
        form.set('invoice', transaction.value.invoice);
    });

    const isDisabled = () => !form.get('invoice');

    const form = useForm<{
        invoice: Invoice | null;
        title: string;
        amount: number;
        claimRemains: boolean;
        paidAt: string;
        paymentMethod: string;
    }>({
        invoice: null,
        title: t('invoicing.invoice_automatic_payment_default_title'),
        amount: 0,
        claimRemains: true,
        paidAt: '',
        paymentMethod: 'bank_transfer',
    });
    const submit = async () => {
        if (isDisabled()) return;
        const schema = InvoicePaymentSchema.make({
            attributes: form.dataExcept(['invoice']),
        });
        schema.addToOneRelationship('invoice', ModelType.INVOICES, form.get('invoice').getId());
        if (transaction.value) {
            schema.addToOneRelationship('bankAccountTransaction', ModelType.BANK_ACCOUNT_TRANSACTIONS, transaction.value?.getId());
        }

        const endpoint = EndpointFactory.make(ModelType.INVOICE_PAYMENTS);
        const response = await form.loadUntil(endpoint.store(schema));
        if (!response.data) return useToasteoError();
        const { invoice } = response.data;
        transaction.value?.setRelationship('invoice', invoice);
        transaction.value?.onUpdate();
        useToasteoSuccess();
        useEvent('bankaccount:bank-account-transaction:assign-invoice:close');
    };
    const transaction = ref<BankAccountTransaction>();
</script>
<template>
    <Modal :name="modalName">
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('bankaccount.bank_account_transactions.assign_invoice_modal.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                />
                <FormQuoteSelect
                    document-type="invoice"
                    :form="form"
                    input-name="invoice"
                ></FormQuoteSelect>
            </div>
            <div :class="$theme('modal.footer.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        :disabled="isDisabled()"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </Modal>
</template>
