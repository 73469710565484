<script setup lang="ts">
interface Props {
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Array | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        placeholderContainerClass?: string;
        placeholder?: string;
        disabled?: boolean;
        collection: _VTI_TYPE_TimeActivityCollection | null;
    }
    import TimeActivity from '../../../app/planning/models/TimeActivity'
    
    const emit = defineEmits(['selected', 'hidden']);
    
    const props = defineProps({
  multiple: { type: Boolean, default: true },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: false },
  submenuPosition: { default: 'left-0' },
  placeholderContainerClass: { default: 'px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group' },
  placeholder: null,
  disabled: { type: Boolean, default: false },
  collection: null
});
    const submenu = ref(null);
    const isSelected = (timeActivity: TimeActivity | null) => {
        if (!selected.value || !timeActivity) {
            return false;
        }
        if (!Array.isArray(selected.value)) {
            return selected.value?.getId() === timeActivity.getId();
        }
        return selected.value?.some((a) => a.getId() === timeActivity.getId());
    };
    const unselect = (timeActivity: TimeActivity) => {
        if (!selected.value) {
            return;
        }
        if (!Array.isArray(selected.value)) {
            selected.value = null;
        } else {
            selected.value = selected.value.filter((a) => a.getId() !== timeActivity.getId());
        }
        return onChange();
    };
    const select = (timeActivity: TimeActivity | null) => {
        if (!props.multiple) {
            selected.value = timeActivity;
            return onChange();
        }
        if (!selected.value) {
            selected.value = [];
        }
        if (isSelected(timeActivity)) {
            selected.value = selected.value.filter((a: TimeActivity) => a.getId() !== timeActivity.getId());
        } else if (timeActivity) {
            selected.value = [...selected.value, timeActivity];
        }
        return onChange();
    };
    const onChange = () => {
        // if (props.multiple && selected.value && selected.value.length === 0) {
        //   selected.value = null;
        // }
        emit('selected', selected.value);
    };
    const setValue = (value: TimeActivity | Array<TimeActivity>) => {
        selected.value = value;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    const isDisplayed = computed(() => props.initialValue?.length);
    defineExpose({
        show,
        isDisplayed,
        setValue,
    });
    const selected: Ref<Array<TimeActivity> | null> = ref(null);
    onMounted(async () => {
        if (props.initialValue && props.initialValue instanceof Array) {
            selected.value = props.initialValue;
        }
        // if (
        //   props.initialValue &&
        //   typeof props.initialValue !== "string" &&
        //   (typeof props.initialValue?.isModelOrCollection === "function" ||
        //     typeof props.initialValue === "object")
        // ) {
        //   selected.value = props.initialValue;
        // }
        // if (typeof props.initialValue === "string" && props.initialValue !== "") {
        //   const ids = props.initialValue.split(",");
        //   selected.value = tenant.value?.users?.filterByIds(ids) ?? [];
        // }
    });
</script>

<template>
    <!-- Adding more padding bottom to have the last item of the list more visible -->
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl', 'pb-12')"
        max-height="max-h-[400px]"
        @hidden="emit('hidden', selected)"
    >
        <template #default="slotProps">
            <div
                :class="placeholderContainerClass"
                @click.prevent.stop="!disabled ? slotProps.toggle() : null"
            >
                <slot
                    v-if="selected"
                    name="selected"
                    :selected="selected"
                >
                    <div class="relative flex items-center space-x-2">
                        <template v-if="multiple">
                            <div>
                                <span class="text-sm transition-all text-neutral-500 group-hover:text-primary">{{
                                    $t('selects.time_activities.count.selected', {
                                        count: selected.length,
                                    })
                                }}</span>
                            </div>
                        </template>
                    </div>
                </slot>
                <slot
                    v-else
                    name="placeholder"
                >
                    <div class="flex items-center space-x-2">
                        <div class="w-[24px] text-center">
                            <i class="transition-all text-neutral-600 group-hover:text-primary fa-regular fa-business-time"></i>
                        </div>
                        <div>
                            <span class="text-sm transition-all text-neutral-500 group-hover:text-primary">
                                {{ placeholder ?? $t('selects.timeActivities.placeholder') }}
                            </span>
                        </div>
                    </div>
                </slot>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <SubmenuEmptyContent v-if="collection?.isEmpty()" />

                <div
                    v-else
                    class="flex flex-col"
                >
                    <div
                        v-for="timeActivity in collection?.items"
                        :key="timeActivity.getInternalId()"
                        :class="
                            $theme('contextmenu.link', 'cursor-pointer', {
                                'px-6 py-3': 'p-2',
                            })
                        "
                        @click.prevent="select(timeActivity)"
                    >
                        <div class="flex items-center space-x-2">
                            <div
                                class="relative transition-all border-2 rounded-full w-fit"
                                :class="isSelected(timeActivity) ? 'border-primary' : 'border-transparent'"
                            >
                                <div class="border-2 border-transparent rounded-full">
                                    <AuthUserAvatar
                                        :user="timeActivity.user"
                                        size="h-6 w-6"
                                        :clickable-avatar="false"
                                    />
                                </div>
                                <transition
                                    enter-active-class="duration-150 ease-linear "
                                    enter-from-class="transform translate-y-[2px] opacity-0"
                                    enter-to-class="transform translate-y-0 opacity-100"
                                    leave-active-class="duration-150 ease-linear "
                                    leave-from-class="transform translate-y-0 opacity-100"
                                    leave-to-class="transform translate-y-[2px] opacity-0"
                                >
                                    <div
                                        v-if="isSelected(timeActivity)"
                                        class="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-[.10rem] h-4 w-4 rounded-full flex bg-primary items-center justify-center"
                                    >
                                        <i class="fa-solid fa-sharp fa-check text-[.6rem] text-white"></i>
                                    </div>
                                </transition>
                            </div>
                            <div>
                                <span class="block text-sm">
                                    {{ timeActivity.title }}
                                    <span class="text-xs text-gray-600"> - {{ timeActivity.liveTimer.get().format('HH:mm:ss', { trim: false }) }} </span>
                                </span>
                                <div class="text-xs text-gray-600">
                                    <span>
                                        {{ timeActivity?.createdAt?.toShortDayDateTimeString() }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="isSelected(timeActivity)"
                            class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                        >
                            <a
                                href="#"
                                @click.prevent.stop="unselect(timeActivity)"
                            >
                                <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
