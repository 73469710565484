import { ModelType } from '@/app/base/schemas/BaseSchema';
import EndpointFactory from '@/app/factories/EndpointFactory';
import Form from '@/classes/Form';

export default async function useStoreOrUpdateModel<M extends BaseModel>(modelType: ModelType, schema: typeof BaseSchema, endpointSetupCallback?: (endpoint) => endpoint) {
    let endpoint = EndpointFactory.make(modelType);

    if (endpointSetupCallback) {
        endpoint = endpointSetupCallback(endpoint);
    }

    return await endpoint.storeOrUpdate(schema);
}
