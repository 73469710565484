<template>
    <div>
        <Modal
            ref="modal"
            name="common:files:export-modal"
        >
            <ModalLottie
                :title="$t('common.files.modal.export.title.success')"
                :subtitle="$t('common.files.modal.export.text.success')"
            >
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('common:files:export-modal:close')"
                    >
                        {{ $t('actions.close') }}
                    </LoadingButton>
                </div>
            </ModalLottie>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    const { t } = useTranslation();
    useModal('common:files:export-modal', (payload: any) => {
        // TBD if we want to do soemthing else
    });
</script>
