<script setup lang="ts">
    import Invoice from '@/app/invoicing/models/Invoice';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { storeToRefs } from 'pinia';

    const { tenant } = storeToRefs(useAuthStore());
    const invoice = ref<Invoice | null>(null);

    const form = useForm<{
        understood: boolean;
    }>({
        understood: false,
    });

    useModal('invoicing:invoice:delete-alert', (payload) => {
        form.reset();

        invoice.value = payload.invoice;
        if (invoice.value.status == 'draft') {
            form.set('understood', true);
        }
    });

    const deleteInvoice = async () => {
        const { t } = useTranslation();
        const endpoint = EndpointFactory.make(ModelType.INVOICES);

        await form.loadUntil(endpoint.destroy(invoice.value!.getId()));
        useToasteoSuccess(t('toasts.element_deleted'));

        useEvent('invoicing:invoice:delete-alert:close');
    };
</script>

<template>
    <Modal
        name="invoicing:invoice:delete-alert"
        ref="modal"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text', 'px-12')">
                {{ $t('invoicing.invoice.delete_document.title') }}
            </h2>
        </div>
        <div :class="$theme('modal.padding')">
            <div class="flex justify-center mb-8">
                <div class="max-w-[180px] h-[180px]">
                    <Lottie json="https://shared-assets.trustup.io/lottie/warning.json" />
                </div>
            </div>

            <div
                class="p-4 mb-8 rounded bg-neutral-100"
                v-if="invoice.status !== 'draft'"
            >
                <p class="mb-1 text-base text-neutral-800">{{ $t('invoicing.invoice.delete_document.explaination.title') }}</p>
                <p
                    class="text-xs text-neutral-600"
                    v-if="invoice.documentType == 'invoice'"
                >
                    {{ $t('invoicing.invoice.delete_document.explaination.text_invoice') }}
                </p>
                <p
                    class="text-xs text-neutral-600"
                    v-else
                >
                    {{ $t('invoicing.invoice.delete_document.explaination.text') }}
                </p>
            </div>

            <FormCheckbox
                :form="form"
                input-name="understood"
                :label="$t('invoicing.invoice.delete_document.checkbox')"
            />

            <div :class="$theme('modal.footer.button_container')">
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.cancel')"
                    prevent-default
                    @clicked="useEvent('invoicing:invoice:delete-alert:close')"
                >
                    {{ $t('actions.cancel') }}
                </LoadingButton>
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="form.get('understood') !== true ? $theme('button.style.disabled') : $theme('button.style.red')"
                    @clicked="deleteInvoice"
                    :loading="form.isLoading"
                    :disabled="form.get('understood') !== true"
                >
                    {{ $t('actions.delete') }}
                </LoadingButton>
            </div>
        </div>
    </Modal>
</template>
