<script setup lang="ts">
    import moment from '~~/utils/moment';
    import CalendarEvent from '@/app/planning/models/CalendarEvent';
    import { CalendarEventTypeValue } from '@/app/planning/enums/CalendarEventType';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import CalendarEventSchema from '~~/app/planning/schemas/CalendarEventSchema';
    import User from '~~/app/auth/models/User';
    import { useEvent } from '@/composables/useEventBus';
    import CalendarEventEndpoint from '~~/app/planning/endpoints/CalendarEventEndpoint';
    import Worksite from '~~/app/worksite/models/Worksite';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
    import Lead from '~~/app/crm/models/Lead';
    import Contact from '~~/app/contact/models/Contact';

    interface Fields {
        title: string;
        notes: string;
        contact: Contact | null;
        address: Address | null;
        users: Array<User> | null;
        lead: Lead | null;
        worksite: Worksite | null;
        worksitePhase: WorksitePhase | null;
        startsAt: string;
        endsAt: string | null;
        eventType: typeof CalendarEventTypeValue;
        timetrackingAllowed: boolean;
        accountedForWeeklyHours: boolean;
    }
    const isLoading = ref(false);
    const form = useForm<Fields>(
        {
            title: '',
            notes: '',
            address: null,
            contact: null,
            users: null,
            lead: null,
            worksite: null,
            worksitePhase: null,
            eventType: 'worksite',
            timetrackingAllowed: true,
            accountedForWeeklyHours: true,
            startsAt: moment().hour(8).startOf('hour').toDateTimeString(),
            endsAt: moment().hour(16).startOf('hour').toDateTimeString(),
        },
        (field: string, value: any) => {
            if (field === 'worksite') {
                if (form.get('worksite')?.address) {
                    form.set('address', form.get('worksite').address);
                }
                if (form.get('worksite')?.contact) {
                    form.set('contact', form.get('worksite')?.contact ?? null);
                }
                if (!form.get('title')) {
                    form.set('title', form.get('worksite')?.title ?? '');
                }
            }
        }
    );

    const useDifferentStartEndDates = ref(false);
    const { modalName, model } = useModelFormModal<CalendarEvent, Fields>(
        CalendarEvent,
        (payload?: FormModalPayload<CalendarEvent, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const getCompleteCalendarEvent = async (payload?: FormModalPayload<CalendarEvent, Fields>): Promise<CalendarEvent | null> => {
        if (!payload || !payload.model) {
            return null;
        }
        const endpoint = EndpointFactory.make(ModelType.CALENDAR_EVENTS);
        const response = await endpoint.setInclude('users,worksite,contact,lead,worksitePhase').retrieve(payload.model.getId());

        return response.data;
    };

    const fillForm = async (payload?: FormModalPayload<CalendarEvent, Fields>) => {
        isLoading.value = true;
        const calendarEvent = await getCompleteCalendarEvent(payload);

        if (calendarEvent) {
            form.fill({
                title: calendarEvent.title,
                notes: calendarEvent.notes,
                address: calendarEvent.address,
                users: calendarEvent.users?.toArray() || null,
                lead: calendarEvent.lead,
                contact: calendarEvent.contact,
                worksite: calendarEvent.worksite,
                worksitePhase: calendarEvent.worksitePhase,
                eventType: calendarEvent.eventType.name,
                timetrackingAllowed: true,
                accountedForWeeklyHours: true,
                // timetrackingAllowed: calendarEvent.timetrackingAllowed,
                // accountedForWeeklyHours: calendarEvent.accountedForWeeklyHours,
                startsAt: calendarEvent.startsAt?.toDateTimeString() || '',
                endsAt: calendarEvent.endsAt?.toDateTimeString() || '',
            });
        }

        if (payload?.fields) {
            if (payload.fields.eventType === 'appointment') {
                payload.fields.startsAt = moment().toDateTimeString();
                payload.fields.endsAt = moment().add(1, 'hours').toDateTimeString();
            }
            form.fill(payload.fields);
        }

        isLoading.value = false;
    };

    const { formattedDuration, date, startTime, endTime } = useDateRangeForm(form, 'startsAt', 'endsAt');

    const submit = async () => {
        const schema = CalendarEventSchema.make({
            attributes: form.dataExcept(['users', 'worksite', 'worksitePhase', 'lead', 'contact']),
        });
        schema.attributes!.startsAt = moment(form.get('startsAt')).toUtcDateTimeString();
        const endsAt = moment(form.get('endsAt'));
        schema.attributes!.endsAt = moment(form.get('startsAt')).set('hour', endsAt.hours()).set('minutes', endsAt.minutes()).toUtcDateTimeString();
        if (form.get('lead')) {
            schema.addToOneRelationship('lead', ModelType.LEADS, form.get('lead').getId());
        }
        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (form.get('worksitePhase')) {
            schema.addToOneRelationship('worksitePhase', ModelType.WORKSITE_PHASES, form.get('worksitePhase').getId());
        }
        if (form.get('contact')) {
            schema.addToOneRelationship('contact', ModelType.CONTACTS, form.get('contact').getId());
        }
        if (form.get('users')) {
            form.get('users').forEach((user: User) => {
                schema.addToManyRelationship('users', ModelType.USERS, user.getId());
            });
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: CalendarEventEndpoint = EndpointFactory.make(ModelType.CALENDAR_EVENTS) as CalendarEventEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="CalendarEvent"
        :scrollable="false"
        :width="1000"
    >
        <ModalLoader v-if="isLoading" />
        <div
            v-else
            class="flex items-stretch w-full h-full centered-modal-max-height"
        >
            <div class="hidden md:block w-[360px] flex-shrink-0 border-r border-neutral-200 p-4">
                <PlanningCalendarEventFormModalAgenda
                    :key="$moment(form.get('startsAt'))?.toDateString()"
                    :form="form"
                    :day="form.get('startsAt')"
                />
            </div>
            <form
                :class="$theme('modal.padding', 'flex flex-col overflow-y-auto nice-scrollbar')"
                @submit.prevent="submit"
            >
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">
                        {{ model ? $t('planning.calendar_event.edit_form.title') : $t('planning.calendar_event.create_form.title') }}
                    </h2>
                </div>
                <div class="flex-1">
                    <div class="flex items-stretch mb-6 border border-gray-200 divide-x divide-gray-200">
                        <div
                            v-for="calendarEventType in useGetCalendarEventTypes()"
                            :key="calendarEventType.name"
                            :data-tooltip="calendarEventType.title()"
                            class="relative flex items-center justify-center p-4 text-base transition-all duration-300 cursor-pointer grow"
                            :class="[form.get('eventType') === calendarEventType.name ? '' : 'text-[#f5f5f5] hover:text-black']"
                            :style="`
                            background-color: ${form.get('eventType') === calendarEventType.name ? calendarEventType.color() : '#f5f5f5'};
                            color: ${form.get('eventType') === calendarEventType.name ? 'white' : '#374151'}
                        `"
                            @click.prevent="form.set('eventType', calendarEventType.name)"
                        >
                            <div
                                v-show="form.get('eventType') === calendarEventType.name"
                                class="absolute inset-0 z-10 flex items-center justify-center pointers-event-none"
                            >
                                <i :class="`fa-solid fa-${calendarEventType.icon()} text-white`"></i>
                            </div>
                            <div class="w-[20px] flex justify-center">
                                <i
                                    v-if="form.get('eventType') !== calendarEventType.name"
                                    :class="`fa-regular fa-${calendarEventType.icon()}`"
                                />
                            </div>
                        </div>
                    </div>
                    <template v-if="!form.get('lead')">
                        <div class="flex space-x-2">
                            <div class="flex-1">
                                <FormWorksiteSelect
                                    :form="form"
                                    input-name="worksite"
                                    with-create-button
                                />
                            </div>
                            <div
                                v-if="form.get('worksite')"
                                class="flex-1"
                            >
                                <FormWorksitePhaseSelect
                                    :form="form"
                                    input-name="worksitePhase"
                                    :worksite="form.get('worksite')"
                                    @changed="form.set('worksitePhase', null)"
                                />
                            </div>
                        </div>
                    </template>
                    <FormContactSelect
                        :form="form"
                        input-name="contact"
                        :label="$t('form.labels.client')"
                        with-create-button
                        contact-type="client"
                    />
                    <FormUserSelect
                        :form="form"
                        input-name="users"
                        multiple
                        :close-on-select="false"
                        form-key="users"
                    />
                    <FormInput
                        :form="form"
                        input-name="title"
                        autofocus
                    />
                    <FormAddressAutocomplete
                        :form="form"
                        input-name="address"
                    />
                    <div :class="$theme('form.containers.element')">
                        <div class="flex items-center justify-between space-x-6">
                            <div>
                                <label
                                    :class="$theme('form.label')"
                                    for="calendar-event-form-modal-startsAt-input"
                                >
                                    {{ $t('form.labels.start_end_date') }}
                                </label>
                                <div class="flex items-center space-x-2">
                                    <FormDate
                                        id="calendar-event-form-modal-startsAt-input"
                                        :form="form"
                                        input-name="startsAt"
                                        element-class-name=" "
                                        position="left-0"
                                        alignment="bottom-full"
                                        without-label
                                    />
                                    <FormDate
                                        v-if="useDifferentStartEndDates"
                                        id="calendar-event-form-modal-endsAt-input"
                                        :form="form"
                                        input-name="endsAt"
                                        element-class-name=" "
                                        position="left-0"
                                        alignment="bottom-full"
                                        without-label
                                    />
                                    <FormTime
                                        :form="form"
                                        input-name="startsAt"
                                        element-class-name=" "
                                        position="left-0"
                                        alignment="bottom-full"
                                        without-label
                                    />
                                    <FormTime
                                        :form="form"
                                        input-name="endsAt"
                                        element-class-name=" "
                                        position="left-0"
                                        alignment="bottom-full"
                                        without-label
                                        :min-time="form.get('startsAt')"
                                    />
                                </div>
                            </div>
                            <div>
                                <span
                                    class="text-center"
                                    :class="$theme('form.label')"
                                    >{{ $t('form.labels.totalTime') }}</span
                                >
                                <span class="text-2xl font-medium text-gray-800">
                                    {{ formattedDuration }}
                                </span>
                            </div>
                        </div>
                        <!-- <div class="mt-1">
                    <a href="#" @click.prevent="useDifferentStartEndDates = ! useDifferentStartEndDates" class="text-xs text-gray-600 transition-all hover:underline hover:text-primary">Use different dates (t)</a>
                </div> -->
                    </div>
                    <FormTextarea
                        :form="form"
                        input-name="notes"
                    />
                </div>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </form>
        </div>
    </ModelFormModal>
</template>
