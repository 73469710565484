import ProductPreview, { RawPreviewProduct } from '../classes/ProductPreview';
export default class PreviewProductFactory {
    create(product: RawPreviewProduct): ProductPreview {
        return new ProductPreview({
            reference: product[0],
            title: product[1],
            description: product[2],
            price: product[3],
            purchasePrice: product[4],
            productUnit: product[5],
            productCategory: product[6],
            defaultVatRate: product[7],
        });
    }
}
