import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoiceDiscountRelationships = {};

type InvoiceDiscountAttributes = {
    title: string;
    value: number;
    valueType: string;
    discountType: string;
    appliedBeforeTax: boolean;
};

type InvoiceDiscountMeta = {};

export default class InvoiceDiscountSchema extends BaseSchema<InvoiceDiscountAttributes, InvoiceDiscountMeta, InvoiceDiscountRelationships> {
    protected type: ModelType = ModelType.INVOICE_DISCOUNTS;
}
