<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        worksite: _VTI_TYPE_Worksite;
    }
    
    import WorksitePhase from '~~/app/auth/models/WorksitePhase'
    import { FormInputData } from '~~/composables/useFormInput'
    const emit = defineEmits(['selected', 'changed']);
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  worksite: null
});
    const inputData: FormInputData = useFormInput(props);
    const select = ref(null);
</script>

<template>
    <FormElement
        :inputData="inputData"
        @label-clicked="select?.show()"
    >
        <SelectWorksitePhase
            :initial-value="inputData.model"
            :worksite="worksite"
        ></SelectWorksitePhase>
    </FormElement>
</template>
