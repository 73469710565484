import { BaseSchema, ModelType } from '~~/app/base/schemas/BaseSchema';

type SourceRelationships = {};

type SourceAttributes = {
    title: string;
};

type SourceMeta = {};

export default class SourceSchema extends BaseSchema<SourceAttributes, SourceMeta, SourceRelationships> {
    protected type: ModelType = ModelType.SOURCES;
}
