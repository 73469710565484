<script setup lang="ts">
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import InvoiceTemplateSectionEndpoint from '@/app/invoicing/endpoints/InvoiceTemplateSectionEndpoint';
    import InvoiceTemplateSection from '@/app/invoicing/models/InvoiceTemplateSection';

    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: InvoiceTemplateSection) => {
        await deleteButton.value.loadUntil(onPromise(useDeleteModel(model)));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="InvoiceTemplateSection"
        :menu-height="100"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
            ref="deleteButton"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
