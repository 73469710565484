import UserEndpoint from '~~/app/auth/endpoints/UserEndpoint';
import UserSchema from '~~/app/auth/schemas/UserSchema';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import EndpointFactory from '~~/app/factories/EndpointFactory';

export default async (newLocale: string) => {
    const { authed } = useAuthStore();
    const endpoint = EndpointFactory.make(ModelType.USERS) as UserEndpoint;
    const schema = new UserSchema({ id: authed!.getId(), attributes: { locale: newLocale } });
    await endpoint.withoutEvents().update(schema);

    await wait(200);

    useToasteoSuccess('auth.locale.success');
};
