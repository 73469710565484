import { UnwrapNestedRefs } from 'vue';
import TenantEndpoint from '@/app/auth/endpoints/TenantEndpoint';
import Tenant from '@/app/auth/models/Tenant';
import { Mode } from '@/app/enums/tenant/Mode';
import EndpointFactory from '@/app/factories/EndpointFactory';
import { ModelType } from '@/app/base/schemas/BaseSchema';

const useTenantModeSwitch = () => {
    const isLoading = ref(false);
    const switchMode = async ({ tenant, mode }: { tenant: UnwrapNestedRefs<Tenant>; mode: Mode }) => {
        isLoading.value = true;
        const endpoint = EndpointFactory.make(ModelType.TENANTS) as TenantEndpoint;
        const { data } = await endpoint.mode(tenant.getId(), mode);
        if (!data) return;
        window.location.replace('/');
    };

    return { isLoading, switchMode };
};

export default useTenantModeSwitch;
