<script setup lang="ts">
    import { InvoiceFormNextNumber } from '@/app/invoicing/endpoints/types';
    import Invoice from '@/app/invoicing/models/Invoice';

    const props = defineProps({
  invoice: null,
  defaultNextNumber: null,
  wantedNextNumber: null
});

    const nextNumberIsLater = computed(() => {
        return props.wantedNextNumber !== props.defaultNextNumber;
    });
</script>

<template>
    <div
        class="flex items-center px-5 py-4 mb-4 space-x-4 text-sm"
        :class="{ 'text-blue-900 bg-blue-200 rounded': true }"
    >
        <i class="text-2xl fa-regular fa-circle-info" />
        <div>
            <span class="block text-xs">
                {{ $t('invoicing.invoice_custom_internal_number_legal_reminder.text_1') }}
            </span>
            <span
                class="block text-xs"
                v-if="nextNumberIsLater"
            >
                {{ $t('invoicing.invoice_custom_internal_number_legal_reminder.text_2') }}
            </span>
        </div>
    </div>
</template>
