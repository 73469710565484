<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TimeActivityEndpoint from '~~/app/planning/endpoints/TimeActivityEndpoint';
    import TimeActivity from '~~/app/planning/models/TimeActivity';

    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const { authed } = useAuthStore();

    type ContextMenuAction = 'pause' | 'restart' | 'stop' | 'replicate' | 'destroy' | 'markAsInvoiced' | 'unmarkAsInvoiced' | 'transformToInvoice' | 'linkToInvoice';

    const retrieveTimeActivity = async (model: TimeActivity) => {
        const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES);
        const timeActityUpdated = await endpoint.retrieve(model.getId());
        if (timeActityUpdated.error) {
            useToasteoError();
            return;
        }
        useEvent('planning:time-activity:updated', timeActityUpdated.data as TimeActivity);
        useToasteoSuccess();
    };
    const action = async (model: TimeActivity, action: ContextMenuAction, payload: LoadingButtonClickedEventPayload) => {
        const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES) as TimeActivityEndpoint;
        const response = await payload.loadUntil(onPromise(endpoint[action](model.getId())));

        if (response.error) {
            useToasteoError();
            return;
        }

        if (action === 'transformToInvoice') {
            retrieveTimeActivity(model);
        }

        if (action == 'markAsInvoiced') {
            useToasteoSuccess('toasts.time_activity_marked_as_invoiced');
        }
        if (action == 'unmarkAsInvoiced') {
            useToasteoSuccess('toasts.time_activity_unmarked_as_invoiced');
        }
    };
</script>

<template>
    <ModelContextMenu
        v-slot="{ model }"
        ref="contextMenu"
        :model-type="TimeActivity"
        :menu-height="200"
    >
        <div :class="$theme('contextmenu.links_container')">
            <div :class="$theme('contextmenu.links')">
                <a
                    href="#"
                    :class="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => model?.preview())"
                >
                    <i
                        class="fa-eye"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.preview') }}</span>
                </a>
                <a
                    v-can="'planning:time_activity:time_activity_manual_create'"
                    href="#"
                    :class="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => model?.edit())"
                >
                    <i
                        class="fa-pencil"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.edit') }}</span>
                </a>
                <LoadingButton
                    v-if="model.isInProgress()"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'pause', payload)"
                >
                    <i
                        class="fa-pause-circle"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.pause') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.isPaused()"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'restart', payload)"
                >
                    <i
                        class="fa-play-circle"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.restart') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.isRunning()"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'stop', payload)"
                >
                    <i
                        class="fa-stop"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.stop') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.isCompleted()"
                    v-can="'planning:time_activity:time_activity_manual_create'"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'replicate', payload)"
                >
                    <i
                        class="fa-copy"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.duplicate') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-can="'planning:time_activity:time_activity_manual_create'"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'destroy', payload)"
                >
                    <i
                        class="fa-trash"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.delete') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.worksite"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="onAction(() => model.worksite.open())"
                >
                    <i
                        class="fa-hard-hat"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.open_worksite') }}</span>
                </LoadingButton>
            </div>
            <!-- When UnmArkedInvoice the relation is no deleted so we keep the link to invoice endpoint to allow user to switch invoice if not invoiced -->
            <div
                v-if="model.isCompleted()"
                :class="$theme('contextmenu.links')"
            >
                <LoadingButton
                    v-if="!model.invoicedAt"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="onAction(() => useEvent('planning:time-activity:link-invoice:open', model))"
                >
                    <i
                        class="fa-link"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.link_invoice') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="!model.hasRelationship('invoice')"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'transformToInvoice', payload)"
                >
                    <i
                        class="fa-file-plus"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.create_invoice') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.invoice"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="onAction(() => model.invoice.edit())"
                >
                    <i
                        class="fa-eye"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.open_invoice') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="!model.invoicedAt"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'markAsInvoiced', payload)"
                >
                    <i
                        class="fa-check"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.mark_as_invoiced') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="model.invoicedAt"
                    :class-name="$theme('contextmenu.link')"
                    @clicked="(payload) => action(model, 'unmarkAsInvoiced', payload)"
                >
                    <i
                        class="fa-xmark"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.unmark_as_invoiced') }}</span>
                </LoadingButton>
            </div>
        </div>
    </ModelContextMenu>
</template>
