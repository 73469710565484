<script setup lang="ts">
    import { UnwrapNestedRefs } from 'vue';
    import Tenant from '@/app/auth/models/Tenant';
    defineProps({
  tenant: null
});
    const {
        modal: { open },
    } = useTenantLiveModeModal();
</script>
<template>
    <div class="flex items-center justify-between gap-4 px-8 py-3 lg:gap-8 bg-neutral-100 h-[60px]">
        <p class="text-sm text-gray-800">
            <span class="hidden xl:block">
                {{ $t('tenancy.tenant.mode.alert.text') }}
            </span>
            <span class="block xl:hidden">
                {{ $t('tenancy.tenant.mode.alert.text_sm') }}
            </span>
        </p>
        <LoadingButton
            :class-name="$theme('button.style.warning_semi')"
            @clicked="open(tenant)"
        >
            <span class="hidden xl:block">
                {{ $t('actions.exit_test_mode') }}
            </span>
            <span class="block xl:hidden">
                {{ $t('actions.exit') }}
            </span>
        </LoadingButton>
    </div>
</template>
