<script lang="ts" setup>
    import Form from '@/classes/Form';

    const props = defineProps({
  day: null,
  form: null
});

    const { calendar, calendarOptions, collection, loading, load, filters, dateRange, getModelFromEvent } = useFullCalendar({
        initialView: 'timeGridDay',
        initialDate: moment(props.day).toDate(),
        headerToolbar: false,
    });

    const conflictDisplayed = ref(true);
    const conflictExists = computed(() => {
        if (!props.form.get('users')) {
            return false;
        }
        return collection.value?.conflictExists(props.form.get('users'), props.form.get('startsAt'), props.form.get('endsAt'));
    });
</script>

<template>
    <div class="relative flex flex-col h-full">
        <div
            v-if="conflictExists && conflictDisplayed"
            class="absolute bottom-0 left-0 right-0 p-4 mb-4 text-sm text-white rounded bg-pastel-orange-dark z-[1000] cursor-pointer"
            @click.prevent="conflictDisplayed = false"
        >
            <span>
                {{ $t('planning.calendar_event.form_modal.conflict_exists') }}
            </span>
        </div>
        <div
            class="flex-1"
            id="full-calendar-container-calendar-event-modal"
        >
            <Loader v-if="loading" />
            <FullCalendar
                ref="calendar"
                :calendar-options="calendarOptions"
                :getModelFromEvent="getModelFromEvent"
                v-else
            />
        </div>
    </div>
</template>
