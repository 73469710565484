<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteProfitabilityDocumentEndpoint from '~~/app/worksite/endpoints/WorksiteProfitabilityDocumentEndpoint';
    import WorksiteProfitabilityDocument from '~~/app/worksite/models/WorksiteProfitabilityDocument';

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROFITABILITY_DOCUMENTS) as WorksiteProfitabilityDocumentEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: WorksiteProfitabilityDocument) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="WorksiteProfitabilityDocument"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.preview())"
        >
            <i
                class="fa-eye"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.preview') }}</span>
        </a>
        <template v-if="!slotProps.model?.model">
            <a
                href="#"
                :class="$theme('contextmenu.link')"
                @click.prevent="onAction(() => slotProps.model?.edit())"
            >
                <i
                    class="fa-pencil"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>{{ $t('actions.edit') }}</span>
            </a>
            <LoadingButton
                :class-name="$theme('contextmenu.link')"
                @click.prevent="destroy(slotProps.model)"
                ref="deleteButton"
            >
                <i
                    class="fa-trash"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>{{ $t('actions.delete') }}</span>
            </LoadingButton>
        </template>
        <template v-if="slotProps.model.timeActivity">
            <a
                href="#"
                :class="$theme('contextmenu.link')"
                @click.prevent="onAction(() => slotProps.model.model.preview())"
            >
                <i
                    class="fa-stopwatch"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>
                    {{ $t('actions.see_original_time_activity') }}
                </span>
            </a>
        </template>
        <template v-if="slotProps.model.invoice">
            <a
                href="#"
                :class="$theme('contextmenu.link')"
                @click.prevent="onAction(() => slotProps.model.model.edit())"
            >
                <i
                    class="fa-pencil"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>
                    {{ $t('actions.edit_original_document') }}
                </span>
            </a>
        </template>
        <template v-if="slotProps.model.expense">
            <a
                href="#"
                :class="$theme('contextmenu.link')"
                @click.prevent="onAction(() => slotProps.model.model.edit())"
            >
                <i
                    class="fa-pencil"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>
                    {{ $t('actions.edit_original_expense') }}
                </span>
            </a>
        </template>
    </ModelContextMenu>
</template>
