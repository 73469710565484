import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ModelFileFolderRelationships = {
    model: ToOneRelationship<ModelType.WORKSITES> | ToOneRelationship<ModelType.INVOICES>;
    parent: ToOneRelationship<ModelType.MODEL_FILE_FOLDERS>;
};

type ModelFileFolderAttributes = {
    title: string;
};

type ModelFileFolderMeta = {};

export default class ModelFileFolderSchema extends BaseSchema<ModelFileFolderAttributes, ModelFileFolderMeta, ModelFileFolderRelationships> {
    protected type: ModelType = ModelType.MODEL_FILE_FOLDERS;
}
