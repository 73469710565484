<script setup lang="ts">
    import moment from '~~/utils/moment';
    import Worksite from '@/app/worksite/models/Worksite';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Contact from '~~/app/contact/models/Contact';
    import Invoice from '@/app/invoicing/models/Invoice';
    import WorksiteClosureReportSchema from '@/app/worksite/schemas/WorksiteClosureReportSchema';
    import WorskiteClosureReportEndpoint from '@/app/worksite/endpoints/WorskiteClosureReportEndpoint';
    import '@vuepic/vue-datepicker/dist/main.css';

    interface Fields {
        contact: Contact | null;
        address: Address | null;
        worksite: Worksite | null;
        quote: Invoice | null;
        title: string | null;
        doneAt: moment.Moment | null;
    }

    const form = useForm<Fields>(
        {
            contact: null,
            address: null,
            worksite: null,
            quote: null,
            title: null,
            doneAt: null,
        },
        (field: string, value: any) => {
            if (field === 'contact' && form.get('contact') && form.get('contact').address && !form.get('address')) {
                form.setSilently('address', form.get('contact').address);
            }
        }
    );
    const model = ref<Worksite>();
    const { modalName } = useModal(
        'worksite:worksite:closure-report:form-modal',
        (payload?: { worksite?: Worksite }) => {
            form.reset();
            useListen('contact:contact:created', (contact) => {
                form.set('contact', contact);
            });
            if (payload?.worksite) {
                model.value = payload?.worksite;
                fillForm(payload?.worksite);
            }
        },
        () => {
            form.reset();
            useIgnore('contact:contact:created');
        }
    );

    const fillForm = (worksite?: Worksite) => {
        if (!worksite) return;
        if (!worksite.address && worksite.contact) {
            worksite.address = worksite.contact.address;
        }
        form.fill({
            contact: worksite?.contact,
            address: worksite?.address,
            worksite,
            quote: null,
            title: '',
            doneAt: worksite?.createdAt,
        });
    };

    const submit = async () => {
        const schema = WorksiteClosureReportSchema.make({
            attributes: form.dataExcept(['quote', 'worksite', 'contact']),
        });

        if (form.get('contact')) {
            // form.set("invoiceId", form.get("quote").getId());
            schema.addToOneRelationship('contact', ModelType.CONTACTS, form.get('contact').getId());
        }
        if (form.get('quote')) {
            // form.set("invoiceId", form.get("quote").getId());
            schema.addToOneRelationship('invoice', ModelType.INVOICES, form.get('quote').getId());
        }
        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        const endpoint: WorskiteClosureReportEndpoint = EndpointFactory.make(ModelType.WORKSITES_CLOSURE_REPORT);
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));
        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        useToasteoSuccess();
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`worksite:worksite:closure-report:form-modal:close`);
    };
</script>

<template>
    <Modal
        :scrollable="true"
        :name="modalName"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('worksite.worksite.minutes_of_work.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    :label="$t('form.labels.closure_reports.title')"
                />
                <FormContactSelect
                    :form="form"
                    input-name="contact"
                    :label="$t('form.labels.project_owner')"
                    with-create-button
                    contact-type="client"
                />
                <FormWorksiteSelect
                    :form="form"
                    input-name="worksite"
                />
                <FormQuoteSelect
                    :form="form"
                    input-name="quote"
                    :label="$t('form.labels.quote')"
                />
                <FormAddressAutocomplete
                    :form="form"
                    input-name="address"
                />
                <FormDate
                    :form="form"
                    input-name="doneAt"
                    alignment="bottom-full"
                />

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </Modal>
</template>
