<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ModelFileEndpoint from '~~/app/common/endpoints/ModelFileEndpoint';
    import ModelFileSchema from '~~/app/common/schemas/ModelFileSchema';
    import WorksiteSchema from '~~/app/worksite/schemas/WorksiteSchema';

    const dropping = ref(false);
    const uploading = ref(false);
    const uploadFileInput = ref(null);
    const { enabled, worksite, modelFileFolder } = storeToRefs(useUploadDropZoneStore());

    const display = () => (dropping.value = true);
    const hide = () => (dropping.value = false);

    const onDrop = async (event: Event) => {
        event.preventDefault();

        if (event.dataTransfer.getData('URL')) {
            await uploadFileFromUrl(event.dataTransfer.getData('URL'));
            return;
        }

        await uploadFiles(event.dataTransfer.files);
    };

    const uploadFileFromUrl = async (url: string) => {
        try {
            var blob = await fetch(url).then((r) => r.blob());
        } catch (error) {
            console.error(error);
            uploading.value = false;
            dropping.value = false;
            useToasteoError('toasts.upload_failed');
            return;
        }

        let file = new File([blob], url.split('/').pop(), { type: blob.type });
        console.log(url, file, blob);
        await uploadFiles([file]);
    };

    const onFileUpload = async (event: Event) => {
        event.preventDefault();
        await uploadFiles(event.target.files);
    };

    const uploadFiles = async (files: Array<File>) => {
        uploading.value = true;

        let promises = [];
        for (let i = 0; i < files.length; i++) {
            promises.push(uploadFile(files[i]));
        }

        let uploadedFiles = await Promise.all(promises);
        dropping.value = false;
        uploading.value = false;
        // // this.onFilesCreation(uploadedFiles);
    };

    const uploadFile = async (file: File) => {
        try {
            let endpoint = EndpointFactory.make(ModelType.MODEL_FILES) as ModelFileEndpoint;
            let schema = new ModelFileSchema({ attributes: { title: file.name } });

            if (worksite.value) {
                schema.addToOneRelationship('model', ModelType.WORKSITES, worksite.value?.getId());
            }
            if (modelFileFolder.value) {
                schema.addToOneRelationship('modelFileFolder', ModelType.MODEL_FILE_FOLDERS, modelFileFolder.value?.getId());
            }

            let response = await endpoint.upload(schema, file);
            useToasteoSuccess('toasts.upload_success');
            return response.data;
        } catch (error) {
            console.log(error);
            useToasteoError('toasts.upload_failed');
            return null;
        }
    };

    const startUploading = async () => {
        dropping.value = true;
        await nextTick();
        uploadFileInput.click();
    };

    onMounted(() => {
        var body = document.querySelector('body');
        // body!.ondragleave = (e) => {
        //     if ( enabled.value ) {
        //         console.log('dropping right now', e.clientX > 0 && e.clientX < body!.clientWidth && e.clientY > 0 && e.clientY < body!.clientHeight);
        //         dropping.value = e.clientX > 0 && e.clientX < body!.clientWidth && e.clientY > 0 && e.clientY < body!.clientHeight;
        //     }
        // }
        useListen('common:model-file:form-modal:open', display);
        useListen('common:model-file:form-modal:upload', startUploading);
        useListen('common:model-file:form-modal:close', hide);
    });

    onUnmounted(() => {
        useIgnore('common:model-file:form-modal:open', display);
        useIgnore('common:model-file:form-modal:upload', startUploading);
        useIgnore('common:model-file:form-modal:close', hide);
    });
</script>

<template>
    <div
        class="fixed inset-0 z-[1011] flex items-center justify-center w-full h-full"
        @dragenter.prevent
        @dragover.prevent
        @drop="onDrop"
        v-if="dropping || uploading"
    >
        <div
            class="fixed inset-0 z-[21]"
            v-show="uploading"
        >
            <lottie
                json="https://shared-assets.trustup.io/lottie/uploading.json"
                container-class-name="lottie-uploading-animation-container"
            ></lottie>
        </div>
        <div class="relative z-20 text-center cursor-pointer">
            <form>
                <label
                    for="dropdown-file-input"
                    class="cursor-pointer"
                >
                    <div
                        class="max-w-[300px] min-h-[300px]"
                        v-show="!uploading"
                    >
                        <div class="flex justify-center mb-4">
                            <lottie
                                json="https://shared-assets.trustup.io/lottie/upload.json"
                                container-class-name="lottie-upload-animation-container"
                            ></lottie>
                            <input
                                ref="uploadFileInput"
                                multiple
                                id="dropdown-file-input"
                                class="hidden"
                                name="dropdown-file-input"
                                type="file"
                                @change="onFileUpload"
                            />
                        </div>
                    </div>
                    <!-- <i class="fal fa-cloud-upload mb-4 text-[100px] text-gray-600"></i> -->
                    <p class="text-xl text-gray-800">
                        {{ uploading ? $t('worksite.upload_dropzone.uploading') : $t('worksite.upload_dropzone.select_file') }}
                    </p>
                    <!-- <v-spa-loader v-if="uploading"></v-spa-loader> -->
                </label>
            </form>
        </div>
        <div class="absolute top-0 right-0 z-20 pt-12 pr-12">
            <i
                class="text-xl text-gray-600 cursor-pointer fa-regular fa-xmark"
                @click.prevent="dropping = false"
            ></i>
        </div>
        <div class="absolute inset-0 bg-white opacity-80"></div>
    </div>
</template>
