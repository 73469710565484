<script setup lang="ts">
    import VatRate from '@/app/invoicing/models/VatRate';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import VatRateSchema from '~~/app/invoicing/schemas/VatRateSchema';
    import { useEvent } from '@/composables/useEventBus';
    import VatRateEndpoint from '~~/app/invoicing/endpoints/VatRateEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import { storeToRefs } from 'pinia';

    interface Fields {
        title: string;
        rate: number;
        belgiumCocontractorMention: boolean;
        belgiumRenovationMention: boolean;
        franceCocontractorMention: boolean;
        franceRequireVatCertificate: boolean;
    }
    const form = useForm<Fields>({
        title: '',
        rate: 21,
        belgiumCocontractorMention: false,
        belgiumRenovationMention: false,
        franceCocontractorMention: false,
        franceRequireVatCertificate: false,
    });

    const { tenant } = storeToRefs(useAuthStore());
    const { modalName, model } = useModelFormModal<VatRate, Fields>(
        VatRate,
        (payload?: FormModalPayload<VatRate, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<VatRate, Fields>) => {
        if (payload?.model) {
            form.fillWithModel(payload.model);
        }
    };

    const submit = async () => {
        let schema = VatRateSchema.make({ attributes: form.data() });
        if (model.value) {
            schema.id = model.value?.getId();
        }

        let endpoint: VatRateEndpoint = EndpointFactory.make(ModelType.VAT_RATES) as VatRateEndpoint;
        let response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="VatRate"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('invoicing.vat_rates.edit_form.title') : $t('invoicing.vat_rates.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <FormPercentage
                    :form="form"
                    input-name="rate"
                />
                <template v-if="tenant?.isBelgian()">
                    <FormCheckbox
                        :form="form"
                        input-name="belgiumCocontractorMention"
                        :tooltip="$t('form.helpers.belgiumCocontractorMention')"
                        v-if="form.get('rate') == 0"
                    />
                    <FormCheckbox
                        :form="form"
                        input-name="belgiumRenovationMention"
                        :tooltip="$t('form.helpers.belgiumRenovationMention')"
                        v-if="form.get('rate') == 6"
                    />
                </template>
                <template v-if="tenant?.isFrench()">
                    <FormCheckbox
                        :form="form"
                        input-name="franceRequireVatCertificate"
                        :tooltip="$t('form.helpers.franceRequireVatCertificate')"
                        v-if="form.get('rate') == 5.5 || form.get('rate') == 10"
                    />
                    <FormCheckbox
                        :form="form"
                        input-name="franceCocontractorMention"
                        :tooltip="$t('form.helpers.franceCocontractorMention')"
                        v-if="form.get('rate') == 0"
                    />
                </template>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
