<template>
    <div class="ml-2 flex items-center">
        <div class="flex">
            <input
                type="checkbox"
                :id="item.title"
                class="peer hidden"
                @click="checked(item)"
                :disabled="item.disabled"
            />
            <label
                :for="item.title"
                class="select-none rounded-l border border-gray-200 py-3 px-6 transition-colors duration-200 ease-in-out"
                :class="[item.checked ? 'peer-checked:bg-neutral-200 peer-checked:text-neutral-900 peer-checked:border-gray-200' : '', item.disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
            >
                {{ item.title }}
            </label>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { SettingItem } from '../SettingModal.vue';

    const props = defineProps({
  item: null
});

    const emit = defineEmits(["checked"]);

    const checked = (item: SettingItem) => {
        if (item.disabled) return useToasteoBasic('You can select only one type of unit');
        item.checked = !item.checked;
        emit('checked', item);
    };
</script>
