<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceDiscountEndpoint from '~~/app/invoicing/endpoints/InvoiceDiscountEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';
    import 'vue-slider-component/theme/antd.css';
    import InvoiceDiscount from '~/app/invoicing/models/InvoiceDiscount';
    import InvoiceDiscountSchema from '~/app/invoicing/schemas/InvoiceDiscountSchema';

    const { t } = useTranslation();
    const endpoint = EndpointFactory.make(ModelType.INVOICE_DISCOUNTS);
    const invoice = reactiveModel<Invoice>(null);
    interface Fields {
        title: string;
        value: number;
        valueType: string;
        discountType: string;
        appliedBeforeTax: boolean;
    }
    const form = useForm<Fields>({
        title: '',
        value: 0,
        valueType: 'flat',
        discountType: 'custom',
        appliedBeforeTax: false,
    });

    const { modalName, model } = useModelFormModal<InvoiceDiscount, Fields>(
        InvoiceDiscount,
        (payload?: FormModalPayload<InvoiceDiscount, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const forceValueTypeForNonCustomDiscounts = () => {
        if (form.get('discountType') !== 'custom') {
            form.set('valueType', 'flat');
        }
    };

    const fillForm = (payload?: FormModalPayload<InvoiceDiscount, Fields>) => {
        console.log('fill discount form modal', payload);
        if (payload?.fields) {
            if (payload.fields.invoice) {
                invoice.value = payload.fields.invoice;
            }
            if (payload.fields.appliedBeforeTax) {
                form.set('appliedBeforeTax', payload.fields.appliedBeforeTax);
                form.set('title', t('invoicing.invoice_discount_default_title'));
                form.set('valueType', 'percentage');
                form.set('value', 10);
            }
        }
        if (payload?.model) {
            form.fillWithModel(payload.model);
        }
    };

    const submit = async () => {
        const schema = new InvoiceDiscountSchema({ attributes: form.data() });
        schema.attributes!.value = Math.round(schema.attributes?.value);
        if (model.value) {
            schema.id = model.value.getId();
        }
        schema.addToOneRelationship('invoice', ModelType.INVOICES, invoice.value!.getId());

        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.data) {
            useToasteoSuccess();
            useEvent('invoicing:invoice-discount:form-modal:close');
        }
    };
</script>

<template>
    <ModelFormModal
        :model="InvoiceDiscount"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('actions.update_discount') : $t('actions.record_discount') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <template v-if="form.get('appliedBeforeTax') !== true">
                    <FormSelect
                        :form="form"
                        input-name="discountType"
                        @change="forceValueTypeForNonCustomDiscounts"
                    >
                        <option
                            :value="discountType"
                            v-for="discountType in ['custom', 'cee', 'ma_prime_renov']"
                            :key="discountType"
                        >
                            {{ $t(`invoicing.invoice_discount_type.${discountType}`) }}
                        </option>
                    </FormSelect>
                </template>
                <template v-if="form.get('appliedBeforeTax') !== true && form.get('discountType') == 'custom'">
                    <FormSelect
                        :form="form"
                        input-name="valueType"
                    >
                        <option
                            :value="valueType"
                            v-for="valueType in ['percentage', 'flat']"
                            :key="valueType"
                        >
                            {{ $t(`invoicing.invoice_discount_value_type.${valueType}`) }}
                        </option>
                    </FormSelect>
                </template>
                <FormPercentage
                    :form="form"
                    input-name="value"
                    input-type="number"
                    v-if="form.get('valueType') == 'percentage'"
                />
                <FormPrice
                    :form="form"
                    input-name="value"
                    v-if="form.get('valueType') == 'flat'"
                />
                <FormInput
                    :form="form"
                    input-name="title"
                    v-if="form.get('discountType') == 'custom'"
                />
                <!-- <FormCheckbox :form="form" input-name="appliedBeforeTax" /> -->
                <div
                    class="p-4 mb-4 text-sm text-blue-900 bg-blue-200 rounded"
                    v-if="form.get('discountType') == 'ma_prime_renov'"
                >
                    <p>{{ $t('invoicing.invoice_discount_modal.ma_prime_renov_explaination') }}</p>
                </div>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('invoicing:invoice-discount:form-modal:close')"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                    >
                        {{ model ? $t('actions.update_discount') : $t('actions.record_discount') }}
                    </LoadingButton>
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
