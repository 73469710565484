import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoiceTemplateSectionColumnRelationships = {
    invoiceTemplateSection: ToOneRelationship<ModelType.INVOICE_TEMPLATE_SECTIONS>;
};

type InvoiceTemplateSectionColumnAttributes = {
    title: string;
    content: string;
    style: object;
    order: number;
};

type InvoiceTemplateSectionColumnMeta = {};

export default class InvoiceTemplateSectionColumnSchema extends BaseSchema<InvoiceTemplateSectionColumnAttributes, InvoiceTemplateSectionColumnMeta, InvoiceTemplateSectionColumnRelationships> {
    protected type: ModelType = ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS;
}
