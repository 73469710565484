<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const replicatedInvoice = reactiveModel<Invoice>(null);
    const success = ref(false);
    const form = useForm<{
        documentType: string;
        shouldKeepContact: boolean;
    }>({
        documentType: 'invoice',
        shouldKeepContact: true,
    });

    useModal(
        'invoicing:invoice:replicate',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            form.set('documentType', invoice.value.documentType);
        },
        () => {
            success.value = false;
            invoice.value = replicatedInvoice.value = null;
            form.reset();
        }
    );

    const submit = async () => {
        const response = await form.loadUntil(
            endpoint.replicate(invoice.value!.getId(), {
                documentType: form.get('documentType'),
                shouldKeepContact: form.get('shouldKeepContact'),
            })
        );

        if (response.data) {
            replicatedInvoice.value = response.data;
            success.value = true;
        }
    };

    const openReplicatedInvoice = () => {
        replicatedInvoice.value!.edit();
        useEvent('invoicing:invoice:replicate:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:replicate"
    >
        <template v-if="success">
            <ModalLottie
                :title="$t('invoicing.invoice_duplicate_modal.success.title')"
                :subtitle="$t('invoicing.invoice_duplicate_modal.success.text')"
            >
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('invoicing:invoice:replicate:close')"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        prevent-default
                        @clicked="openReplicatedInvoice"
                        >{{ $t('actions.open') }}</LoadingButton
                    >
                </div>
            </ModalLottie>
        </template>
        <template v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">{{ $t('actions.duplicate') }}</h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <FormSelect
                        :form="form"
                        input-name="documentType"
                    >
                        <option
                            v-for="documentType in ['quote', 'invoice', 'credit-note', 'delivery-slip', 'order-slip']"
                            :key="documentType"
                            :value="documentType"
                        >
                            {{ $t(`invoice_document_type.${documentType}`) }}
                        </option>
                    </FormSelect>
                    <FormCheckbox
                        :form="form"
                        input-name="shouldKeepContact"
                    />
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice:replicate:close')"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.duplicate') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
