<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteEndpoint from '~~/app/worksite/endpoints/WorksiteEndpoint';
    import Worksite from '~~/app/worksite/models/Worksite';
    import WorksiteSchema from '~~/app/worksite/schemas/WorksiteSchema';

    const endpoint = EndpointFactory.make(ModelType.WORKSITES) as WorksiteEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const archiveButton = ref(null);
    const unarchiveButton = ref(null);
    const deleteButton = ref(null);
    const closureReportButton = ref(null);
    const unarchive = async (model: Worksite) => {
        const schema = new WorksiteSchema({ attributes: { archivedAt: null }, id: model.getId() });
        await unarchiveButton.value.loadUntil(onPromise(endpoint.update(schema)));
    };

    const archive = async (model: Worksite) => {
        const schema = new WorksiteSchema({ attributes: { archivedAt: moment().toUtcDateTimeString() }, id: model.getId() });
        await archiveButton.value.loadUntil(onPromise(endpoint.update(schema)));
    };

    const destroy = async (model: Worksite) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };

    const createClosureReport = (model: Worksite) => {
        useEvent('worksite:worksite:context-menu:close');
        useEvent('worksite:worksite:closure-report:form-modal:open', {
            worksite: model,
        });
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="Worksite"
        :menu-height="200"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.preview())"
        >
            <i
                class="fa-eye"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.preview') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.open())"
        >
            <i
                class="fa-arrow-right"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            ref="closureReportButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="createClosureReport(slotProps.model)"
        >
            <i
                class="fa-file-signature"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.closure_report.create') }}</span>
        </LoadingButton>
        <LoadingButton
            v-if="!slotProps.model.archivedAt"
            ref="archiveButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="archive(slotProps.model)"
        >
            <i
                class="fa-box-archive"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.archive') }}</span>
        </LoadingButton>
        <LoadingButton
            v-if="slotProps.model.archivedAt"
            ref="unarchiveButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="unarchive(slotProps.model)"
        >
            <i
                class="fa-inbox"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.unarchive') }}</span>
        </LoadingButton>
        <LoadingButton
            ref="deleteButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
