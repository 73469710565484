<script setup lang="ts">
    import TimeActivity from '@/app/planning/models/TimeActivity';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TimeActivityEndpoint from '~~/app/planning/endpoints/TimeActivityEndpoint';

    useModelModal<TimeActivity>(
        TimeActivity,
        (data: ModalPayload<TimeActivity>) => retrieve(data),
        () => (timeActivity.value = null)
    );
    const loading: Ref<boolean> = ref(false);
    const timeActivity: Ref<TimeActivity | null> = ref(null);

    const retrieve = async (data: ModalPayload<TimeActivity>) => {
        loading.value = true;
        let endpoint = EndpointFactory.make(data.model.type) as TimeActivityEndpoint;
        let response = await endpoint.retrieve(data.model.getId(), { include: `${endpoint.include},task.users,timeActivityPauses,timeActivityGeolocations` });
        timeActivity.value = response.data;
        loading.value = false;
    };
</script>

<template>
    <ModelModal
        :model="TimeActivity"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            :class="$theme('modal.right_panel.content')"
            v-if="!loading && timeActivity"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ timeActivity.title }}
                </h2>
            </div>

            <!-- Main section -->
            <div :class="[$theme('modal.right_panel.section.container'), { 'block md:flex md:space-x-4 md:items-start': timeActivity.timeActivityGeolocations?.isNotEmpty() }]">
                <div
                    v-if="timeActivity.timeActivityGeolocations?.isNotEmpty()"
                    class="w-full md:w-[300px] h-[240px] mb-4 md:mb-0"
                >
                    <GMapMap
                        :center="{
                            lat: timeActivity.timeActivityGeolocations.first()?.latitude,
                            lng: timeActivity.timeActivityGeolocations.first()?.longitude,
                        }"
                        :zoom="19"
                        map-type-id="satellite"
                        style="width: 100%; height: 240px"
                    >
                        <GMapMarker
                            :key="index"
                            :position="{
                                lat: timeActivityGeolocation.latitude,
                                lng: timeActivityGeolocation.longitude,
                            }"
                            :icon="{
                                url: timeActivityGeolocation.getIconUrl(),
                                scaledSize: timeActivityGeolocation.getIconSize(),
                            }"
                            v-for="(timeActivityGeolocation, index) in timeActivity.timeActivityGeolocations.get()"
                        />
                    </GMapMap>
                </div>
                <div>
                    <template v-if="timeActivity.worksite">
                        <InfoText
                            :label="$t('form.labels.worksite')"
                            clickable
                            @click.prevent="timeActivity.worksite.open()"
                        >
                            {{ timeActivity.worksite.title }}
                        </InfoText>
                        <InfoText
                            :label="$t('form.labels.worksitePhase')"
                            v-if="timeActivity.worksitePhase"
                            clickable
                            @click.prevent="timeActivity.worksite.open()"
                        >
                            {{ timeActivity.worksitePhase.title }}
                        </InfoText>
                    </template>
                    <InfoText
                        :label="$t('form.labels.task')"
                        v-if="timeActivity.task"
                    >
                        <div class="mt-2">
                            <TaskTaskItem :task="timeActivity.task" />
                        </div>
                    </InfoText>
                    <InfoText :label="$t('form.labels.date')">
                        {{ timeActivity.startsAt?.toDayDateString() }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.hours')">
                        <span v-if="timeActivity.startsAt && timeActivity.endsAt"> {{ timeActivity.startsAt.toShortTimeString() }} - {{ timeActivity.endsAt.toShortTimeString() }} </span>
                        <span v-else>
                            {{ $t('planning.time_activity.modal.started_at', { time: timeActivity.startsAt?.toShortTimeString() }) }}
                        </span>
                    </InfoText>
                    <InfoText :label="$t('form.labels.duration')">
                        <PlanningTimeActivityItemTimer
                            :time-activity="timeActivity"
                            timer-class="text-base text-gray-900"
                            :display-tooltip="false"
                        />
                    </InfoText>
                    <InfoText :label="$t('form.labels.user')">
                        {{ timeActivity.user?.name ?? '-' }}
                    </InfoText>
                </div>
            </div>

            <!-- Pauses -->
            <div
                :class="$theme('modal.right_panel.section.container')"
                v-if="timeActivity.timeActivityPauses?.isNotEmpty()"
            >
                <template
                    v-for="(timeActivityPause, index) in timeActivity.timeActivityPauses.get()"
                    :key="timeActivityPause.getInternalId()"
                >
                    <InfoText :label="$t('planning.time_activity.pause_number', { number: index + 1 })">
                        <span v-if="timeActivityPause.startsAt && timeActivityPause.endsAt">
                            {{ timeActivityPause.startsAt.toShortTimeString() }} - {{ timeActivityPause.endsAt.toShortTimeString() }}
                        </span>
                        <span v-else>
                            {{ $t('planning.time_activity.pause_started_at', { time: timeActivityPause.startsAt?.toShortTimeString() }) }}
                        </span>
                    </InfoText>
                </template>
            </div>

            <!-- Messaging -->
            <div :class="$theme('modal.right_panel.section.container', 'flex-1 flex flex-col')">
                <h2 :class="$theme('modal.right_panel.section.title')">
                    {{ $t('messaging.comments_title') }}
                </h2>
                <Messaging :model="timeActivity" />
            </div>
        </div>
    </ModelModal>
</template>
