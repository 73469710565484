<script setup lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import moment from '~~/utils/moment';
    import Worksite from '@/app/worksite/models/Worksite';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteSchema from '~~/app/worksite/schemas/WorksiteSchema';
    import User from '~~/app/auth/models/User';
    import { useEvent } from '@/composables/useEventBus';
    import WorksiteEndpoint from '~~/app/worksite/endpoints/WorksiteEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Contact from '~~/app/contact/models/Contact';
    import '@vuepic/vue-datepicker/dist/main.css';

    interface Fields {
        title: string;
        contact: Contact | null;
        users: Array<User> | null;
        managers: Array<User> | null;
        address: Address | null;
        startsAt: string;
        endsAt: string | null;
    }
    const form = useForm<Fields>(
        {
            title: '',
            contact: null,
            address: null,
            managers: null,
            users: null,
            startsAt: moment().startOf('day').toDateTimeString(),
            endsAt: moment().startOf('day').add(14, 'days').toDateTimeString(),
        },
        (field: string, value: any) => {
            if (field === 'contact' && form.get('contact') && form.get('contact').address && !form.get('address')) {
                form.setSilently('address', form.get('contact').address);
            }
        }
    );
    const dateRange = computed({
        get() {
            return [moment(form.get('startsAt')), moment(form.get('endsAt'))];
        },
        set(value: Array<moment.Moment>) {
            form.set('startsAt', moment(value[0]).toDateTimeString());
            form.set('endsAt', moment(value[1]).toDateTimeString());
        },
    });

    const { modalName, model } = useModelFormModal<Worksite, Fields>(
        Worksite,
        (payload?: FormModalPayload<Worksite, Fields>) => {
            form.reset();
            fillForm(payload),
                useListen('contact:contact:created', (contact) => {
                    form.set('contact', contact);
                });
        },
        () => {
            form.reset();
            useIgnore('contact:contact:created');
        }
    );

    const fillForm = (payload?: FormModalPayload<Worksite, Fields>) => {
        console.log('Filling Worksite FormModal', payload);
        const { authed } = useAuthStore();
        form.fields.managers = [authed!];

        if (payload?.fields) {
            if (!payload.fields.address && payload.fields.contact) {
                payload.fields.address = payload.fields.contact.address;
            }
            form.fill(payload.fields);
        }
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
                contact: payload?.model.contact,
                address: payload?.model.address,
                managers: payload?.model.managers?.toArray(),
                users: payload?.model.users?.toArray() || null,
                startsAt: payload?.model.startsAt?.toDateTimeString() || '',
                endsAt: payload?.model.endsAt?.toDateTimeString() || '',
            });
        }
    };

    const submit = async () => {
        const schema = WorksiteSchema.make({
            attributes: form.dataExcept(['users', 'managers', 'contact']),
        });
        if (form.get('users')) {
            form.get('users').forEach((user: User) => {
                schema.addToManyRelationship('users', ModelType.USERS, user.getId());
            });
        }
        if (form.get('managers')) {
            form.get('managers').forEach((user: User) => {
                schema.addToManyRelationship('managers', ModelType.USERS, user.getId());
            });
        }
        if (form.get('contact')) {
            schema.addToOneRelationship('contact', ModelType.CONTACTS, form.get('contact').getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: WorksiteEndpoint = EndpointFactory.make(ModelType.WORKSITES) as WorksiteEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="Worksite"
        :scrollable="true"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('worksite.worksite.edit_form.title') : $t('worksite.worksite.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <FormContactSelect
                    :form="form"
                    input-name="contact"
                    :label="$t('form.labels.client')"
                    with-create-button
                    contact-type="client"
                />
                <FormAddressAutocomplete
                    :form="form"
                    input-name="address"
                />
                <div class="flex space-x-2">
                    <div class="flex-1">
                        <FormUserSelect
                            :form="form"
                            input-name="managers"
                            multiple
                            :close-on-select="false"
                            form-key="managers"
                        />
                    </div>
                    <div class="flex-1">
                        <FormUserSelect
                            :form="form"
                            input-name="users"
                            multiple
                            :close-on-select="false"
                            form-key="users"
                        />
                    </div>
                </div>
                <FormDate
                    :form="form"
                    input-name="startsAt"
                    alignment="bottom-full"
                    position="left-0"
                    :label="$t('form.labels.dates')"
                >
                    <template #input="{ toggle }">
                        <div
                            :class="$theme('form.input', 'w-full flex items-center space-x-4 cursor-pointer')"
                            @click.prevent="toggle()"
                        >
                            <i class="fa-regular fa-calendar"></i>
                            <span>
                                {{ moment(form.get('startsAt')).toShortDayDateString() }} -
                                {{ moment(form.get('endsAt')).toShortDayDateString() }}
                            </span>
                        </div>
                    </template>
                    <template #datepicker="{ hide }">
                        <VueDatePicker
                            v-model="dateRange"
                            inline
                            auto-apply
                            range
                            :locale="useGetCurrentMomentLocale()"
                            :partial-range="false"
                            :enable-time-picker="false"
                            multi-calendars
                            :multi-static="false"
                            @update:model-value="hide"
                        />
                    </template>
                </FormDate>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
