<script setup lang="ts">
interface Props {
        worksite: _VTI_TYPE_Worksite;
        multiple?: boolean;
        initialValue?: _VTI_TYPE_WorksitePhase | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
    }
    import { EndpointResponse } from '~~/app/base/endpoints/types'
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    import WorksiteCollection from '~~/app/worksite/collections/WorksiteCollection'
    import WorksitePhaseCollection from '~~/app/worksite/collections/WorksitePhaseCollection'
    import WorksitePhaseEndpoint from '~~/app/worksite/endpoints/WorksitePhaseEndpoint'
    import WorksiteEndpoint from '~~/app/worksite/endpoints/WorskiteEndpoint'
    
    
    const emit = defineEmits(['selected']);
    
    const props = defineProps({
  worksite: null,
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' }
});
    const loading = ref(true);
    const submenu = ref(null);
    const worksitePhases: Ref<WorksitePhaseCollection | null> = ref(null);
    const selected: Ref<WorksitePhase | null> = ref(props.initialValue);
    const isSelected = (worksitePhase: WorksitePhase) => {
        return selected.value && selected.value !== '' && selected.value?.getId() == worksitePhase.getId();
    };
    const select = (worksitePhase: WorksitePhase | null) => {
        selected.value = worksitePhase;
        emit('selected', worksitePhase);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (worksitePhase: WorksitePhase) => {
        selected.value = worksitePhase;
    };
    const getWorksitePhases = async () => {
        // if ( worksitePhases.value ) {
        //     loading.value = false;
        //     return;
        // }
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.WORKSITE_PHASES) as WorksitePhaseEndpoint;
        let response = await endpoint.index({
            include: '',
            sort: 'startsAt',
            filter: {
                worksite: {
                    id: [props.worksite.getId()],
                },
            },
        });
        if (response.error) {
            console.error('Could not retrieve worksitePhases.');
            submenu.value?.hide();
            return;
        }
        worksitePhases.value = response.data;
        loading.value = false;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        @shown="getWorksitePhases"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
    >
        <template v-slot:default="slotProps">
            <div
                class="px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i
                            class="transition-all group-hover:text-primary fa-regular fa-bars-progress"
                            :class="selected ? 'text-neutral-600' : 'text-neutral-400'"
                        ></i>
                    </div>
                    <div>
                        <span
                            class="text-sm text-neutral-500 transition-all group-hover:text-primary"
                            v-text="selected ? selected.title : $t('selects.worksitePhase.placeholder')"
                        ></span>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:submenu>
            <div class="w-full min-w-[260px]">
                <p class="mb-2">
                    {{ $t('selects.worksitePhase.title') }}
                </p>
                <div class="relative">
                    <div
                        class="py-6"
                        v-if="loading"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="worksitePhases?.isEmpty()" />
                        <div
                            class="flex flex-col"
                            v-else
                        >
                            <div
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(worksitePhase) }]"
                                v-for="worksitePhase in worksitePhases?.get()"
                                :key="worksitePhase.getInternalId()"
                                @click.prevent="select(worksitePhase)"
                            >
                                <div>
                                    <span class="block text-sm">
                                        {{ worksitePhase.title }}
                                    </span>
                                    <span class="block text-xs"> {{ worksitePhase.startsAt.toShortDayDateString() }} - {{ worksitePhase.endsAt.toShortDayDateString() }} </span>
                                </div>
                                <div
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                    v-if="isSelected(worksitePhase)"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
