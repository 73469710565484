<script setup lang="ts">
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';

    const startTimeActivity = async (payload: LoadingButtonClickedEventPayload) => {
        useEvent('app:search:close');
        const endpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES);
        await payload.loadUntil(endpoint.start());
    };
</script>

<template>
    <div class="p-4">
        <p class="pl-4 mb-2 text-base text-gray-800">
            {{ $t('quick_actions.label') }}
        </p>
        <div :class="$theme('submenu.items_container')">
            <div :class="$theme('submenu.items')">
                <NuxtLink
                    to="/invoicing/quotes/create"
                    :class="$theme('submenu.item')"
                    @click="useEvent('app:search:close')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-file-circle-check" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_quote') }}
                    </span>
                </NuxtLink>
                <NuxtLink
                    to="/invoicing/invoices/create"
                    :class="$theme('submenu.item')"
                    @click="useEvent('app:search:close')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-file-circle-plus" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_invoice') }}
                    </span>
                </NuxtLink>
                <NuxtLink
                    to="/invoicing/expenses/create"
                    :class="$theme('submenu.item')"
                    @click="useEvent('app:search:close')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-wallet" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_expense') }}
                    </span>
                </NuxtLink>
            </div>
            <div :class="$theme('submenu.items')">
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="useEvent('worksite:worksite:form-modal:open')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-helmet-safety" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_worksite') }}
                    </span>
                </a>
            </div>
            <div :class="$theme('submenu.items')">
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="useEvent('crm:lead:form-modal:open')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-bullseye-arrow" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_lead') }}
                    </span>
                </a>
            </div>
            <div :class="$theme('submenu.items')">
                <LoadingButton
                    :class-name="$theme('submenu.item', 'relative')"
                    @clicked="startTimeActivity"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-play" />
                    </div>
                    <span>
                        {{ $t('quick_actions.start_time_activity') }}
                    </span>
                </LoadingButton>
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="useEvent('planning:calendar-event:form-modal:open')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-calendar-day" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_calendar_event') }}
                    </span>
                </a>
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="useEvent('task:task:form-modal:open')"
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-list-check" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_task') }}
                    </span>
                </a>
            </div>
            <div :class="$theme('submenu.items')">
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="
                        useEvent('contact:contact:form-modal:open', {
                            fields: { contactType: 'client' },
                        })
                    "
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-user" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_client') }}
                    </span>
                </a>
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="
                        useEvent('contact:contact:form-modal:open', {
                            fields: { contactType: 'supplier' },
                        })
                    "
                >
                    <div :class="$theme('submenu.item_icon')">
                        <i class="fa-regular fa-truck" />
                    </div>
                    <span>
                        {{ $t('quick_actions.add_supplier') }}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>
