import WorksiteClosureReportStatus from '../enums/WorksiteClosureReportStatus';

import { BaseSchema, ModelType, ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WorksiteClosureReportRelationships = {
    contact: ToOneRelationship<ModelType.CONTACTS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    invoice: ToOneRelationship<ModelType.INVOICES>;
    pdf: ToOneRelationship<ModelType.PDFS>;
    modelEvents: ToManyRelationship<ModelType.MODEL_EVENTS>;
};

type WorksiteClosureReportAttributes = {
    address?: Address | null;
    status: WorksiteClosureReportStatus;
    reserves: any;
};

export type WorksiteClosureReportMetaProfitability = {};

type WorksiteClosureReportMeta = {};

export default class WorksiteClosureReportSchema extends BaseSchema<WorksiteClosureReportAttributes, WorksiteClosureReportMeta, WorksiteClosureReportRelationships> {
    protected type: ModelType = ModelType.WORKSITES_CLOSURE_REPORT;
}
