<script setup lang="ts">
    import Invoice from '~~/app/invoicing/models/Invoice';

    const { contextMenu, hide } = useModelContextMenu();
</script>

<template>
    <ModelContextMenu
        v-slot="{ model }"
        ref="contextMenu"
        :model-type="Invoice"
        :menu-height="200"
    >
        <InvoicingInvoiceActions
            :invoice="model"
            :can-see="true"
            :on-action="hide"
        />
    </ModelContextMenu>
</template>
