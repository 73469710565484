<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import Task from '~~/app/task/models/Task';

    const endpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();

    const toggleCompletedAt = async (model: Task, payload: LoadingButtonClickedEventPayload) => {
        await payload.loadUntil(onPromise(endpoint.toggleCompletedAt(model)));
    };

    const destroy = async (model: Task, payload: LoadingButtonClickedEventPayload) => {
        await payload.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="Task"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.preview())"
        >
            <i
                class="fa-eye"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.preview') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="(payload) => toggleCompletedAt(slotProps.model, payload)"
        >
            <i :class="[$theme('contextmenu.icon'), slotProps.model?.completedAt ? 'fa-xmark' : 'fa-check']"></i>
            <span>{{ slotProps.model?.completedAt ? $t('actions.mark_as_undone') : $t('actions.mark_as_completed') }}</span>
        </LoadingButton>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="(payload) => destroy(slotProps.model, payload)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="onAction(() => slotProps.model.worksite.open())"
            v-if="slotProps.model.worksite"
        >
            <i
                class="fa-hard-hat"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open_worksite') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="onAction(() => slotProps.model.contact.preview())"
            v-if="slotProps.model.contact"
        >
            <i
                class="fa-user"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open_contact') }}</span>
        </LoadingButton>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @clicked="onAction(() => slotProps.model.lead.open())"
            v-if="slotProps.model.lead"
        >
            <i
                class="fa-bullseye-arrow"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open_lead') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
