<script setup lang="ts">
    import Worksite from '@/app/worksite/models/Worksite';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteEndpoint from '~~/app/worksite/endpoints/WorksiteEndpoint';

    const { model } = useModelModal<Worksite>(
        Worksite,
        (data: ModalPayload<Worksite>) => retrieve(data),
        () => (worksite.value = null)
    );
    const loading: Ref<boolean> = ref(false);
    const worksite: Ref<Worksite | null> = ref(null);

    const retrieve = async (data: ModalPayload<Worksite>) => {
        loading.value = true;
        let endpoint = EndpointFactory.make(data.model.type) as WorksiteEndpoint;
        let response = await endpoint.retrieve(data.model.getId(), { include: `${endpoint.include},worksitePhases` });
        worksite.value = response.data;
        loading.value = false;
    };
</script>

<template>
    <ModelModal
        :model="Worksite"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            :class="$theme('modal.right_panel.content')"
            v-if="!loading && worksite"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ worksite.title }}
                </h2>
            </div>

            <!-- Main section -->
            <div :class="[$theme('modal.right_panel.section.container'), { 'block md:flex md:space-x-4 md:items-start': worksite.address }]">
                <div
                    v-if="worksite.address"
                    class="w-full md:w-[300px] h-[240px] mb-4 md:mb-0"
                >
                    <GMapMap
                        :center="{
                            lat: worksite.address.latitude,
                            lng: worksite.address.longitude,
                        }"
                        :zoom="19"
                        map-type-id="satellite"
                        style="width: 100%; height: 240px"
                    >
                        <GMapMarker
                            :position="{
                                lat: worksite.address.latitude,
                                lng: worksite.address.longitude,
                            }"
                        />
                    </GMapMap>
                </div>
                <div>
                    <InfoText :label="$t('form.labels.managers')">
                        {{ worksite.managers?.displayName ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.users')">
                        {{ worksite.users?.displayName ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.contact')">
                        {{ worksite.contact?.displayName ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.address')">
                        {{ worksite.address?.display ?? '-' }}
                    </InfoText>
                    <InfoText :label="$t('form.labels.endDate')">
                        {{ worksite.endsAt?.toShortDayDateString() }}
                    </InfoText>
                </div>
            </div>

            <!-- Main section -->
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.worksitePhases')">
                    <div v-for="worksitePhase in worksite.worksitePhases?.get()">
                        {{ worksitePhase.title }}
                    </div>
                </InfoText>
            </div>
        </div>
    </ModelModal>
</template>
