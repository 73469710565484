<script setup lang="ts">
    import InvoiceTemplateSection from '@/app/invoicing/models/InvoiceTemplateSection';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoiceTemplateSectionColumn from '@/app/invoicing/models/InvoiceTemplateSectionColumn';
    import InvoiceTemplateSectionSchema from '@/app/invoicing/schemas/InvoiceTemplateSectionSchema';

    interface Fields {
        title: string;
        beforeTable: boolean;
        contentVerticalAlignment: string;
        invoiceTemplate: InvoiceTemplate | null;
        invoiceTemplateSectionColumns: InvoiceTemplateSectionColumn[];
    }

    const loading = ref(true);
    const form = useForm<Fields>({
        title: '',
        beforeTable: true,
        invoiceTemplate: null,
        contentVerticalAlignment: 'top',
        invoiceTemplateSectionColumns: [],
    });

    const { modalName, model } = useModelFormModal<InvoiceTemplateSection, Fields>(
        InvoiceTemplateSection,
        async (payload?: FormModalPayload<InvoiceTemplateSection, Fields>) => {
            if (payload?.model) {
                const { load, model } = useModelLoader<InvoiceTemplateSection>(ModelType.INVOICE_TEMPLATE_SECTIONS, payload?.model.getId(), () => {
                    return {
                        include: 'invoiceTemplateSectionColumns',
                    };
                });
                await load();
                form.fillWithModel(model.value);
            }
            if (payload?.fields?.invoiceTemplate) {
                form.set('invoiceTemplate', payload.fields.invoiceTemplate);
            }
            loading.value = false;
        },
        () => {
            loading.value = true;
        }
    );

    const submit = async () => {
        const schema = InvoiceTemplateSectionSchema.make({
            attributes: {
                title: form.get('title'),
                beforeTable: !!form.get('beforeTable'),
                contentVerticalAlignment: form.get('contentVerticalAlignment'),
            },
            id: model.value?.getId(),
        });
        if (form.get('invoiceTemplate')) {
            schema.addToOneRelationship('invoiceTemplate', ModelType.INVOICE_TEMPLATES, form.get('invoiceTemplate')?.getId());
        }
        await form.loadUntil(useStoreOrUpdateModel(ModelType.INVOICE_TEMPLATE_SECTIONS, schema));
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoiceTemplateSection"
        modal-style="right_panel"
        @before-close="form.reset()"
    >
        <Loader v-if="loading" />
        <div
            v-else
            :class="$theme('modal.right_panel.content')"
        >
            <form
                :class="$theme('modal.right_panel.section.container')"
                @submit.prevent="submit"
            >
                <FormTwoFields
                    container-class="flex items-center space-x-4"
                    field-2-class="w-auto"
                >
                    <template #field-1>
                        <FormInput
                            :form="form"
                            input-name="title"
                        />
                    </template>
                    <template #field-2>
                        <div class="mt-5">
                            <FormCheckbox
                                :form="form"
                                input-name="beforeTable"
                            />
                        </div>
                    </template>
                </FormTwoFields>
                <FormSelect
                    :form="form"
                    input-name="contentVerticalAlignment"
                >
                    <option
                        v-for="alignment in ['top', 'bottom', 'center']"
                        :key="alignment"
                        :value="alignment"
                    >
                        {{ alignment }}
                    </option>
                </FormSelect>
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :loading="form.isLoading"
                    type="submit"
                >
                    {{ $t('actions.save_information') }}
                </LoadingButton>
            </form>
            <div
                v-if="model"
                :class="$theme('modal.right_panel.section.container', 'h-full')"
            >
                <div class="flex flex-col space-y-2">
                    <div
                        v-for="invoiceTemplateSectionColumn in form.get('invoiceTemplateSectionColumns')"
                        :key="invoiceTemplateSectionColumn.getInternalId()"
                    >
                        <div
                            class="flex items-center justify-between p-2 text-sm transition-all border border-gray-100 rounded cursor-pointer text-neutral-600 bg-neutral-100 hover:bg-white"
                            @click.prevent="invoiceTemplateSectionColumn.edit({ fields: { invoiceTemplateSection: model } })"
                            @contextmenu="invoiceTemplateSectionColumn.openContextMenu($event)"
                        >
                            <span>{{ invoiceTemplateSectionColumn.title ?? $t('misc.no_title') }}</span>
                        </div>
                    </div>
                    <div
                        v-if="form.get('invoiceTemplateSectionColumns').length === 0"
                        class="flex items-center justify-center h-16 text-neutral-400"
                    >
                        {{ $t('misc.no_results') }}
                    </div>
                    <LoadingButton
                        :class-name="$theme('button.style.white_border_sm2')"
                        @clicked="useEvent('invoicing:invoice-template-section-columns:form-modal:open', { fields: { invoiceTemplateSection: model } })"
                    >
                        <i class="mr-1 text-xs fa-regular fa-plus"></i>
                        <span>
                            {{ $t('actions.add_column') }}
                        </span>
                    </LoadingButton>
                </div>
            </div>
        </div>
    </ModelFormModal>
</template>
