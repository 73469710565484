<script setup lang="ts">
interface Props {
        initialValue?: _VTI_TYPE_Invoice | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        withCreateButton?: boolean;
        documentType: _VTI_TYPE_InvoiceDocumentType;
    }
    
    import InvoiceCollection from '../../../app/invoicing/collections/InvoiceCollection'
    import InvoiceEndpoint from '../../../app/invoicing/endpoints/InvoiceEndpoint'
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    
    const emit = defineEmits(['selected']);
    
    const props = defineProps({
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' },
  withCreateButton: { type: Boolean, default: false },
  documentType: { default: 'quote' }
});
    const loading = ref(true);
    const submenu = ref(null);
    const quotes: Ref<InvoiceCollection | null> = ref(null);
    const selected: Ref<Invoice | null> = ref(props.initialValue);
    if (selected.value == '') {
        selected.value = null;
    }
    const query: Ref<string> = ref('');
    const isSelected = (quote: Invoice) => {
        return selected.value && selected.value.getId() === quote.getId();
    };
    const select = (quote: Invoice | null) => {
        selected.value = quote;
        emit('selected', quote);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const show = () => {
        if (!submenu.value.isDisplayed) {
            submenu.value.show();
        }
    };
    const setValue = (quote: Invoice) => {
        selected.value = quote;
    };
    const getQuotes = async () => {
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
        const response = await endpoint.index({
            page: {
                size: 20,
                number: 1,
            },
            filter: {
                documentType: props.documentType,
            },
        });
        if (response.error) {
            useToasteoError();
            submenu.value?.hide();
            return;
        }
        quotes.value = response.data;
        loading.value = false;
    };
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getQuotes"
    >
        <template #default="slotProps">
            <div
                class="flex items-stretch justify-between px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i
                            class="transition-all group-hover:text-primary fa-regular fa-file-invoice"
                            :class="selected ? 'text-neutral-600' : 'text-neutral-400'"
                        ></i>
                    </div>
                    <div>
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? `${selected.numberOrDraft}` : $t(`selects.${documentType}.title`)"
                        ></span>
                    </div>
                </div>
                <div>
                    <slot name="button"></slot>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[380px]">
                <p class="mb-2">
                    {{ $t(`selects.${documentType}.title`) }}
                </p>
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        autocomplete="off"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('quote.quotes.search_input_placeholder')"
                        @keyup.enter.prevent.stop="getQuotes()"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="getQuotes()"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="quotes?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="quote in quotes?.get()"
                                :key="quote.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer justify-between'), { 'bg-neutral-100': isSelected(quote) }]"
                                @click.stop.prevent="select(quote)"
                                @contextmenu="quote.openContextMenu($event)"
                            >
                                <div>
                                    <div class="flex space-x-2 text-sm">
                                        <span v-if="quote.contact">{{ quote.contact.displayName }}</span>
                                        <span v-if="quote.title">{{ $truncate(quote.title, 20) }}</span>
                                    </div>
                                    <div class="flex space-x-2 text-xs text-neutral-600">
                                        <span>{{ $price(quote.total) }}</span>
                                        <span v-if="quote.createdAt">{{ quote.createdAt?.toEUDateString() }}</span>
                                    </div>
                                </div>
                                <div :class="{ 'pr-4': isSelected(quote) }">
                                    <InvoicingInvoiceStatusBadge
                                        :invoice="quote"
                                        size="min-w-[80px] px-2 py-1 font-semibold text-[11px]"
                                        :custom-label="quote.numberOrDraft"
                                    />
                                </div>
                                <div
                                    v-if="isSelected(quote)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
