<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import Contact from '~/app/contact/models/Contact';
    import EndpointFactory from '~/app/factories/EndpointFactory';

    const { tenant } = storeToRefs(useAuthStore());

    const form = useForm<{
        understood: boolean;
    }>({
        understood: false,
    });

    interface DestroyContactAlertPayload {
        callback?: () => void;
        model: Contact;
    }

    const loading = ref(false);
    const callback = ref(null);
    useModal('contact:contact:destroy-alert', async (payload: DestroyContactAlertPayload) => {
        form.reset();
        await loadContactWithRelations(payload.model);
        callback.value = payload.callback;
    });

    const contact = ref<Contact | null | undefined>();

    const loadContactWithRelations = async (model: Contact) => {
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.CONTACTS);
        const response = await endpoint.addInclude('invoices').retrieve(model.getId());
        contact.value = response.data;
        loading.value = false;
    };

    const save = async () => {
        await form.loadUntil(callback.value());
        useEvent('contact:contact:destroy-alert:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="contact:contact:destroy-alert"
    >
        <ModalLoader v-if="loading" />
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text', 'px-12')">
                    {{ $t('contact.contact_form.destroy_user_alert.title') }}
                </h2>
            </div>
            <div :class="$theme('modal.padding')">
                <AlertBox v-if="contact?.invoices">
                    <div>
                        <p>{{ $t('contact.form.edit_warning.text') }}</p>

                        <div
                            v-if="contact?.getLatestInvoices().length > 0"
                            class="mt-4"
                        >
                            <p class="mb-1 font-semibold">{{ $t('contact.form.destroy_warning.invoice_list') }}</p>
                            <ul class="pl-6 list-disc">
                                <li
                                    v-for="invoice in contact?.getLatestInvoices()"
                                    :key="invoice.getInternalId()"
                                    class="space-x-1"
                                >
                                    <span>{{ $t(`invoice_document_type.${invoice.documentType}`) }}</span>
                                    <span> {{ invoice.numberOrDraft }}</span>
                                    <span>({{ invoice.trueDate.toEUDateString() }})</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </AlertBox>
                <div class="flex justify-center mb-8">
                    <div class="max-w-[180px] h-[180px]">
                        <Lottie json="https://shared-assets.trustup.io/lottie/warning.json" />
                    </div>
                </div>

                <FormCheckbox
                    :form="form"
                    input-name="understood"
                    :label="$t('contact.contact_from.destroy_user_alert.checkbox')"
                />

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('contact:contact:destroy-alert:close')"
                    >
                        {{ $t('actions.cancel') }}
                    </LoadingButton>
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="form.get('understood') !== true ? $theme('button.style.disabled') : $theme('button.style.default')"
                        :loading="form.isLoading"
                        :disabled="form.get('understood') !== true"
                        @clicked="save"
                    >
                        {{ $t('actions.save') }}
                    </LoadingButton>
                </div>
            </div>
        </div>
    </Modal>
</template>
