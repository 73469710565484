<script setup lang="ts">
    import Form from '@/classes/Form';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import ModelFileSchema from '@/app/common/schemas/ModelFileSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import InvoiceTemplate from '@/app/invoicing/models/InvoiceTemplate';

    const props = defineProps({
  invoiceTemplate: null,
  form: null,
  block: null
});

    const loading = ref(false);
    const fileInput = ref<HTMLInputElement | null>(null);

    const reset = () => {
        props.form.set(`${props.block}ImageUrl`, '');
        props.form.set(`${props.block}ImageHeight`, null);
    };

    const upload = async (event: Event) => {
        loading.value = true;

        const file = (event.target as HTMLInputElement).files?.[0];
        fileInput.value!.value = '';

        const endpoint = EndpointFactory.make(ModelType.MODEL_FILES);
        const schema = new ModelFileSchema({ attributes: { title: file.name } });
        schema.addToOneRelationship('model', ModelType.INVOICE_TEMPLATES, props.invoiceTemplate.getId());

        const response = await endpoint.upload(schema, file);
        if (response.data) {
            props.form.set(`${props.block}ImageUrl`, response.data.url);
            props.form.set(`${props.block}ImageHeight`, response.data.media?.customProperties?.height ?? null);
            props.form.set(`${props.block}ImageWidth`, response.data.media?.customProperties?.width ?? null);
        }

        loading.value = false;
    };
</script>

<template>
    <label class="block mb-6">
        <span :class="$theme('form.label')">
            {{ $t(`form.labels.${block}Image`) }}
        </span>
        <div class="relative flex items-center bg-gray-100 rounded cursor-pointer justify-centertransition-all hover:bg-gray-200">
            <Loader v-if="loading" />
            <img
                v-if="form.get(`${block}ImageUrl`)"
                :src="form.get(`${block}ImageUrl`)"
                class="w-full"
            />
            <div
                class="absolute top-0 right-0 z-10 pt-2 pr-2"
                v-if="form.get(`${block}ImageUrl`)"
            >
                <div
                    class="flex items-center justify-center w-6 h-6 transition-all bg-white border border-gray-200 rounded hover:bg-gray-200 aspect-square"
                    @click.prevent="reset()"
                >
                    <i class="text-sm text-gray-400 cursor-pointer fa-regular fa-close"></i>
                </div>
            </div>
            <span
                v-if="!form.get(`${block}ImageUrl`)"
                class="flex justify-center w-full p-6"
            >
                <i class="text-xl text-gray-400 fa-regular fa-upload"></i>
            </span>
            <input
                ref="fileInput"
                type="file"
                class="hidden"
                @change="upload"
            />
        </div>
    </label>
</template>
