<script setup lang="ts">
    const props = defineProps({
  value: null
});
    const copy = () => {
        navigator.clipboard.writeText(props.value);
        useToasteoSuccess('actions.clipboard_copied');
    };
</script>

<template>
    <button
        :data-tooltip="$t('actions.clipboard_copy')"
        data-tooltip-location="bottom"
        @click="copy"
    >
        <slot>{{ value }}</slot>
    </button>
</template>
