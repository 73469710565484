<template>
    <div>
        <Modal
            ref="modal"
            name="tenancy:tenants:spots-modal"
        >
            <template v-if="success">
                <ModalLottie :title="$t('settings.subscription.plan.spots_modal.success.title')">
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('tenancy:tenants:spots-modal:close')"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>
            <div v-else>
                <div :class="$theme('modal.title.container')">
                    <h2 class="text-2xl font-medium text-left text-gray-800 first-letter:capitalize">
                        {{ $t('settings.subscription.plan.spots.modal.title') }}
                    </h2>
                </div>

                <form
                    class="mb-4"
                    @submit.prevent="submit"
                >
                    <div
                        :class="$theme('modal.padding')"
                        class="space-y-4"
                    >
                        <h2
                            v-if="displayWarning"
                            class="p-4 text-red-300 bg-red-100 border border-red-300 rounded-lg"
                        >
                            {{ $t('settings.subscription.plan.spots.modal.quantity.warning') }}
                        </h2>
                        <div class="w-full space-y-2 md:flex-auto">
                            <div class="flex font-thin text-gray-500">
                                <SettingsInputNumber
                                    :model-value="quantity"
                                    @inc="inc()"
                                    @dec="dec()"
                                />
                            </div>
                            <div>
                                <div>
                                    <span class="text-xs"
                                        >{{
                                            $t('settings.subscription.plan.spots.modal.current', {
                                                spots: usedSpots,
                                                available: availableSpots(),
                                            })
                                        }}
                                        <!-- 4 postes en utilisation, 1 disponible -->
                                    </span>
                                </div>
                                <div>
                                    <span class="text-xs font-semibold">
                                        {{
                                            $t('settings.subscription.plan.spots.modal.futur', {
                                                spots: quantity,
                                            })
                                        }}
                                        <!-- 3 nouveaux postes seront ajoutes -->
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="p-4 mt-8 bg-green-100 border rounded-lg">
                                    <div class="flex items-start space-x-2">
                                        <div>
                                            <i class="text-sm text-orange-300 fa-solid fa-triangle-exclamation"></i>
                                        </div>
                                        <p class="text-sm text-green-600 border-green-500">
                                            {{
                                                $t('settings.subscription.plan.spots.modal.warning', {
                                                    quantity: quantity,
                                                    price: $price(quantity * spotUnitPrice),
                                                })
                                            }}
                                            <!-- Ce changement prendre effet immediatement apres le
                      paiement. 3 posts seront ajoutes a votre abonnement, pour
                      une montant total de 27$ / mois. -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="$theme('modal.footerFitCentered.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footerFitCentered.button')"
                                :loading="loading"
                                :class-name="$theme('button.style.default')"
                                type="submit"
                            >
                                <i class="mr-2 fal fa-credit-card"></i>
                                {{ $t('actions.pay') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { UnwrapNestedRefs } from 'vue';
    import { useTenantUserSpots } from '../../composables/useTenantUserSpots';
    import useToasteoError from '../../composables/useToasteoError';
    import useToasteoSuccess from '../../composables/useToasteoSuccess';
    import { useEvent } from '../../composables/useEventBus';
    import Tenant from '@/app/auth/models/Tenant';
    import SubscriptionEndpoint from '~/app/subscription/endpoints/SubscriptionEndpoint';

    export interface spotsForm {
        spots: number;
    }
    const form = useForm<{
        spots: number;
    }>({
        spots: 0,
    });

    const loading = ref<boolean>(false);
    const { t } = useTranslation();
    const tenant = ref<Tenant | null>(null);
    const quantity = ref(0);
    const displayWarning = ref(false);
    const dec = () => {
        displayWarning.value = false;
        if (quantity!.value <= paidSpots.value) {
            displayWarning.value = true;
            return (quantity!.value = paidSpots.value);
        }

        quantity.value--;
    };

    const inc = () => {
        displayWarning.value = false;

        quantity.value++;
    };
    // TODO VERIFY WHEN ADDING TRANSLATION WITH VAR
    const { spotUnitPrice, usedSpots, availableSpots, paidSpots } = useTenantUserSpots();
    useModal('tenancy:tenants:spots-modal', (payload: { tenant: UnwrapNestedRefs<Tenant> }) => {
        tenant.value = payload.tenant;
        form.set('spots', paidSpots.value);
        quantity.value = form.get('spots');
    });
    const success = ref(false);
    const endpoint = new SubscriptionEndpoint();

    const submit = async () => {
        loading.value = true;
        const response = ref();
        response.value = await endpoint.updateSpots(quantity.value);
        await useGetDefaultTenant();

        if (response.value.error) {
            useToasteoError();
            return;
        }
        loading.value = false;
        useToasteoSuccess();
        useEvent('tenancy:tenants:spots-modal:close');
    };
</script>
