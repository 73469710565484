export const useModelContextMenu = () => {
    const contextMenu = ref(null);

    const hide = () => {
        contextMenu.value?.hide();
    };

    const onAction = (action: Function) => {
        contextMenu.value?.hide();
        action();
    };

    const onPromise = async (promise: Promise<any>) => {
        let response = await promise;
        contextMenu.value?.hide();
        return response;
    };

    return {
        hide,
        contextMenu,
        onAction,
        onPromise,
    };
};
