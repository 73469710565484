<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ProductUnitEndpoint from '~~/app/inventory/endpoints/ProductUnitEndpoint';
    import ProductUnit from '~~/app/inventory/models/ProductUnit';
    import ProductUnitSchema from '~~/app/inventory/schemas/ProductUnitSchema';

    const endpoint = EndpointFactory.make(ModelType.PRODUCT_UNITS) as ProductUnitEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: ProductUnit) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        :model-type="ProductUnit"
        :menu-height="100"
        v-slot="slotProps"
        ref="contextMenu"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
            ref="deleteButton"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
