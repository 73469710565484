<script setup lang="ts">
interface Props {
        worksitePhase?: _VTI_TYPE_WorksitePhase | null;
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Task | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
    }
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    import TaskCollection from '~~/app/task/collections/TaskCollection'
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint'
    
    
    const emit = defineEmits(['selected']);
    
    const props = defineProps({
  worksitePhase: null,
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' }
});
    const loading = ref(true);
    const submenu = ref(null);
    const tasks: Ref<TaskCollection | null> = ref(null);
    const selected: Ref<Task | null> = ref(props.initialValue);
    const isSelected = (task: Task) => {
        return selected.value?.getId() == task.getId();
    };
    const select = (task: Task | null) => {
        selected.value = task;
        emit('selected', task);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (task: Task) => {
        selected.value = task;
    };
    const getTasks = async () => {
        // if ( tasks.value ) {
        //     loading.value = false;
        //     return;
        // }
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
        const params = {};
        if (props.worksitePhase) {
            params.filter = {
                worksitePhase: {
                    id: [props.worksitePhase.getId()],
                },
            };
        }
        const response = await endpoint.index(params);
        if (response.error) {
            console.error('Could not retrieve tasks.');
            submenu.value?.hide();
            return;
        }
        tasks.value = response.data;
        loading.value = false;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    defineExpose({
        show,
        setValue,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getTasks"
    >
        <template #default="slotProps">
            <div
                class="px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i
                            class="transition-all group-hover:text-primary fa-regular fa-circle-check"
                            :class="selected ? 'text-neutral-600' : 'text-neutral-400'"
                        ></i>
                    </div>
                    <div>
                        <span
                            class="text-sm text-neutral-500 transition-all group-hover:text-primary"
                            v-text="selected ? selected.title : $t('selects.task.placeholder')"
                        ></span>
                    </div>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <p class="mb-2">
                    {{ $t('selects.task.title') }}
                </p>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="tasks?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="task in tasks?.get()"
                                :key="task.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(task) }]"
                                @click.prevent="select(task)"
                            >
                                <div>
                                    <span class="block mb-1 text-sm truncate max-w-[240px]">
                                        {{ task.title }}
                                    </span>
                                    <div class="flex items-center space-x-2">
                                        <UsersAvatarList
                                            v-if="task.users"
                                            :users="task.users.toArray()"
                                            size="w-[20px] h-[20px]"
                                            negative-margin="-ml-[10px]"
                                        />
                                        <span class="block text-xs">
                                            {{ task.dueDate?.toShortDayDateString() }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-if="isSelected(task)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
