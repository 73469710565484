export default () => {
    return [
        {
            key: 'worksite',
            title: 'Worksite',
            icon: 'hard-hat',
            color: '#10a4d3',
        },
        {
            key: 'contact',
            title: 'Contact',
            icon: 'user',
            color: '#6c5ce7',
        },
        {
            key: 'lead',
            title: 'Lead',
            icon: 'bullseye-arrow',
            color: '#00b894',
        },
    ];
};
