<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const form = useForm<{
        status: string;
    }>({
        status: 'accepted',
    });

    useModal(
        'invoicing:invoice:change-status',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
        },
        () => {
            form.reset();
        }
    );

    const submit = async () => {
        const response = await form.loadUntil(endpoint.markAs(invoice.value?.getId(), form.get('status')));

        if (response.data) {
            useEvent('invoicing:invoice:change-status:close');
            useToasteoSuccess();
        }
    };
</script>

<template>
    <Modal
        name="invoicing:invoice:change-status"
        ref="modal"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('invoicing.invoice_mark_as_modal.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormSelect
                    :form="form"
                    input-name="status"
                >
                    <option value="accepted">
                        {{ $t(`invoice_status.accepted`) }}
                    </option>
                    <option value="rejected">
                        {{ $t(`invoice_status.rejected`) }}
                    </option>
                </FormSelect>

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('invoicing:invoice:change-status:close')"
                    >
                        {{ $t('actions.cancel') }}
                    </LoadingButton>
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                    >
                        {{ $t('actions.change_status') }}
                    </LoadingButton>
                </div>
            </div>
        </form>
    </Modal>
</template>
