<script setup lang="ts">
    import ProductUnit from '@/app/inventory/models/ProductUnit';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ProductUnitSchema from '~~/app/inventory/schemas/ProductUnitSchema';
    import { useEvent } from '@/composables/useEventBus';
    import ProductUnitEndpoint from '~~/app/inventory/endpoints/ProductUnitEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    interface Fields {
        title: string;
    }
    const form = useForm<Fields>({
        title: '',
    });

    const { modalName, model } = useModelFormModal<ProductUnit, Fields>(
        ProductUnit,
        (payload?: FormModalPayload<ProductUnit, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<ProductUnit, Fields>) => {
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
            });
        }
    };

    const submit = async () => {
        let schema = ProductUnitSchema.make({ attributes: form.data() });
        if (model.value) {
            schema.id = model.value?.getId();
        }

        let endpoint: ProductUnitEndpoint = EndpointFactory.make(ModelType.PRODUCT_UNITS) as ProductUnitEndpoint;
        let response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        useToasteoSuccess();
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="ProductUnit"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('inventory.product_unit.edit_form.title') : $t('inventory.product_unit.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
            </div>

            <div :class="$theme('modal.footer.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
