<template>
    <div>
        <Modal
            ref="modal"
            name="invoicing:invoice:export-modal"
        >
            <div>
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">
                        {{ $t('invoice_export.modal.title') }}
                    </h2>
                </div>

                <form
                    class="mb-4"
                    @submit.prevent="exportInvoices()"
                >
                    <div
                        :class="$theme('modal.padding')"
                        class="space-y-4"
                    >
                        <div class="flex flex-row-reverse items-center w-full">
                            <div class="p-4">
                                <div class="p-4 mb-4 text-sm text-center text-blue-900 bg-blue-200 rounded">
                                    <span>{{ createdAfter?.format('DD/MM/YYYY') }} - {{ createdBefore?.format('DD/MM/YYYY') }}</span>
                                </div>
                                <VueDatePicker
                                    v-model="dateRange"
                                    inline
                                    auto-apply
                                    range
                                    :locale="useGetCurrentMomentLocale()"
                                    :partial-range="false"
                                    :enable-time-picker="false"
                                    multi-calendars
                                    :multi-static="false"
                                />
                            </div>
                        </div>

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:export-modal:close')"
                                >{{ $t('actions.cancel') }}
                            </LoadingButton>
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                type="submit"
                                :loading="loading"
                                @clicked="exportInvoices"
                            >
                                <i class="mr-2 fal fa-check"></i>
                                {{ $t('actions.export') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    import ImportedFileEndpoint from '../../../app/common/endpoints/ImportedFileEndpoint';

    interface ExportModalPayload {
        documentType: string;
    }
    useModal('invoicing:invoice:export-modal', (payload: ExportModalPayload) => {
        documentType.value = payload.documentType;
        resetDate();
    });

    const endpoint = new ImportedFileEndpoint();

    const documentType = ref<string>('invoice');
    const createdBefore = ref(moment());
    const createdAfter = ref(moment().subtract(1, 'months'));

    const resetDate = () => {
        createdBefore.value = moment();
        createdAfter.value = moment().subtract(1, 'months');
    };

    const dateRange = computed({
        get() {
            return [createdBefore.value, createdAfter.value];
        },
        set(value: Array<Date>) {
            createdBefore.value = moment(value[1]);
            createdAfter.value = moment(value[0]);
        },
    });

    const loading = ref(false);
    const exportInvoices = async () => {
        loading.value = true;
        const response = await endpoint.export({
            documentType: documentType.value,
            createdBefore: createdBefore.value.format('YYYY-MM-DD'),
            createdAfter: createdAfter.value.format('YYYY-MM-DD'),
        });
        loading.value = false;
        if (response?.error) return useToasteoError();
        if (response.response?.status === 204) return useToasteoError('page.empty_content.title');
        useEvent('common:files:export-modal:open');
        useToasteoSuccess('toasts.export_generated');
        close();
    };

    const close = () => {
        useEvent('invoicing:invoice:export-modal:close');
    };
</script>
