<script setup lang="ts">
    import InvoiceAnnexPdfEndpoint from '../../../../app/invoicing/endpoints/InvoiceAnnexPdfEndpoint';
    import InvoiceAnnexPdf from '../../../../app/invoicing/models/InvoiceAnnexPdf';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';

    const endpoint = EndpointFactory.make(ModelType.INVOICES_ANNEX_PDFS) as InvoiceAnnexPdfEndpoint;
    const { contextMenu, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);
    const defaultButton = ref(null);

    const download = (model: InvoiceAnnexPdf) => {
        window.open(model.url, '_blank');
    };

    const destroy = async (model: InvoiceAnnexPdf) => {
        await deleteButton.value?.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };

    const markAsDefault = async (model: InvoiceAnnexPdf) => {
        const response = await defaultButton.value?.loadUntil(onPromise(endpoint.markAsDefault(model.getId(), !model.default)));
        useEvent('invoicing:invoice-annex-pdf:annex-pdf-modal:updated', response.data);
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="InvoiceAnnexPdf"
    >
        <template v-if="!slotProps.model?.model">
            <div :class="$theme('contextmenu.links')">
                <LoadingButton
                    ref="downloadtButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="download(slotProps.model)"
                >
                    <i
                        class="fa-download"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.download') }}</span>
                </LoadingButton>
                <LoadingButton
                    ref="defaultButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="markAsDefault(slotProps.model)"
                >
                    <i
                        class="fa-bookmark"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.markAsDefault') }}</span>
                </LoadingButton>
                <LoadingButton
                    ref="deleteButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="destroy(slotProps.model)"
                >
                    <i
                        class="fa-trash"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.delete') }}</span>
                </LoadingButton>
            </div>
        </template>
    </ModelContextMenu>
</template>
