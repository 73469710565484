<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ProductCategoryEndpoint from '~~/app/inventory/endpoints/ProductCategoryEndpoint';
    import ProductCategory from '~~/app/inventory/models/ProductCategory';
    import ProductCategorySchema from '~~/app/inventory/schemas/ProductCategorySchema';

    const endpoint = EndpointFactory.make(ModelType.PRODUCT_CATEGORIES) as ProductCategoryEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: ProductCategory) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="ProductCategory"
        :menu-height="100"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <LoadingButton
            ref="deleteButton"
            :class-name="$theme('contextmenu.link')"
            @click.prevent="destroy(slotProps.model)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
