<script setup lang="ts">
    import { InvoiceNotifyFormCreditsInterface } from '~/app/invoicing/endpoints/types';

    const { t } = useTranslation();
    const props = defineProps({
  credits: null
});

    const status = computed(() => {
        if (props.credits?.enough) {
            return 'success';
        }

        if (props.credits?.available < props.credits?.cost * 3) {
            return 'warning';
        }

        return 'error';
    });

    const icon = computed(() => {
        switch (status.value) {
            case 'success':
                return 'fa-check-circle';
            case 'warning':
                return 'fa-exclamation-circle';
            case 'error':
                return 'fa-xmark-circle';
        }
    });

    const text = computed(() => {
        switch (status.value) {
            case 'success':
                return t('invoicing.invoice_notify_credits_alert.success', { available: props.credits!.available, cost: props.credits!.cost });
            case 'warning':
                return t('invoicing.invoice_notify_credits_alert.warning', { available: props.credits!.available, cost: props.credits!.cost });
            case 'error':
                return t('invoicing.invoice_notify_credits_alert.error', { available: props.credits!.available, cost: props.credits!.cost });
        }
    });
</script>

<template>
    <div
        v-if="credits"
        class="flex items-center gap-8 p-4 text-white rounded"
        :class="useStatusAccentBackgroundColour(status)"
    >
        <div>
            <i
                class="text-xl fa-regular"
                :class="icon"
            />
        </div>
        <div>
            <p class="text-sm">
                {{ text }}
            </p>
        </div>
    </div>
</template>
