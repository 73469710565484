<script setup lang="ts">
    import Invoice from '@/app/invoicing/models/Invoice';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';

    useModelModal<Invoice>(
        Invoice,
        (data: ModalPayload<Invoice>) => retrieve(data),
        () => {
            invoice.value = null;
            html.value = '';
            currentTab.value = 'details';
        }
    );
    const loading: Ref<boolean> = ref(false);
    const loadingHtml = ref(true);
    const invoice: Ref<Invoice | null> = ref(null);
    const html: Ref<string> = ref('');
    const currentTab: Ref<'details' | 'preview'> = ref('details');
    const endpoint = EndpointFactory.make(ModelType.INVOICES);

    const setTab = (tabName: 'details' | 'preview') => {
        currentTab.value = tabName;
        if (tabName === 'preview') {
            loadHtml();
        }
    };

    const retrieve = async (data: ModalPayload<Invoice>) => {
        loading.value = true;
        const response = await endpoint.retrieve(data.model.getId(), { include: 'contact,worksite', withCount: 'invoiceLines' });
        invoice.value = response.data;
        loading.value = false;
    };

    const loadHtml = async () => {
        loadingHtml.value = true;
        const response = await endpoint.previewHtml(null, invoice.value.getId());
        html.value = response.html;
        loadingHtml.value = false;
    };
</script>

<template>
    <ModelModal
        :model="Invoice"
        modal-style="right_panel_pdf"
    >
        <ModalLoader v-if="loading" />
        <div
            v-if="!loading && invoice"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container', '!pb-0')">
                <h2 :class="$theme('modal.right_panel.title.text', 'flex items-center gap-4')">
                    <div>
                        {{ invoice.numberOrDraft }}
                    </div>
                    <div>
                        <InvoicingInvoiceStatusBadge
                            v-if="invoice.status !== 'draft'"
                            :invoice="invoice"
                        />
                    </div>
                </h2>
                <div class="mt-6">
                    <div :class="$theme('layout.page_tabs.container')">
                        <a
                            v-for="tab in ['details', 'preview']"
                            :key="tab"
                            href="#"
                            :class="[$theme('layout.page_tabs.tab'), tab === currentTab ? $theme('layout.page_tabs.tab_active') : '']"
                            @click.prevent="setTab(tab)"
                        >
                            {{ $t(`invoicing.invoice.preview.tabs.${tab}`) }}
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="currentTab == 'details'"
                :class="$theme('modal.right_panel.section.container')"
            >
                <InfoText :label="$t('form.labels.number')">
                    {{ invoice.number ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.createdAt')">
                    {{ invoice.createdAt?.toEUDateString() }}
                </InfoText>
                <InfoText :label="$t('form.labels.sentAt')">
                    {{ invoice.sentAt?.toEUDateString() ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.date')">
                    {{ invoice.trueDate.toEUDateString() }}
                </InfoText>
                <InfoText :label="$t('form.labels.contact')">
                    {{ invoice.contact ? invoice.contact.displayName : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.worksite')">
                    {{ invoice.worksite ? invoice.worksite.title : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.linesCount')">
                    {{ $tc('misc.lines_count', invoice.getRelationshipCount('invoiceLines') ?? 0, { count: invoice.getRelationshipCount('invoiceLines') }) }}
                </InfoText>
                <InfoText :label="$t('form.labels.total')">
                    {{ $price(invoice.total) }}
                </InfoText>
            </div>
            <div
                v-if="currentTab == 'preview'"
                :class="$theme('modal.right_panel.section.container', 'relative')"
            >
                <div
                    v-if="loadingHtml"
                    class="relative py-12"
                >
                    <Loader />
                </div>
                <iframe
                    v-else
                    :srcdoc="html"
                    class="w-[794px] h-[1122px] mb-4 border border-gray-200 rounded bg-white"
                ></iframe>
            </div>
        </div>
    </ModelModal>
</template>
