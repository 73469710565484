<script setup lang="ts">
    import ModelFile from '@/app/common/models/ModelFile';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import ModelFileFolder from '@/app/common/models/ModelFileFolder';
    import ModelFileFolderCollection from '@/app/common/collections/ModelFileFolderCollection';
    import Worksite from '@/app/worksite/models/Worksite';
    import ModelFileSchema from 'app/common/schemas/ModelFileSchema';

    interface PayloadInterface {
        modelFile: ModelFile;
        worksite: Worksite;
    }

    const { collection, load, loading } = useCollectionLoader<ModelFileFolder, ModelFileFolderCollection>(ModelType.MODEL_FILE_FOLDERS, [], () => {
        return {
            retrieveAll: true,
            filter: {
                worksite: modelFile.value?.model?.getId(),
            },
        };
    });
    const endpoint = EndpointFactory.make(ModelType.MODEL_FILES);
    const modelFile = reactiveModel<ModelFile>(null);
    const modelFileFolder = reactiveModel<ModelFileFolder>(null);

    useModal(
        'common:model-file:move-modal',
        async (payload: PayloadInterface) => {
            const response = await endpoint.addInclude('model').retrieve(payload.modelFile.getId());

            if (!response.data || !response.data.model || response.data.model.prefix !== Worksite.prefix) {
                useToasteoError();
                useEvent('common:model-file:move-modal:close');
                return;
            }

            modelFile.value = response.data;
            await load();
        },
        () => {
            modelFile.value = null;
            modelFileFolder.value = null;
            loading.value = true;
        }
    );

    const submit = async () => {
        loading.value = true;
        await endpoint.move(modelFile.value.getId(), modelFileFolder.value?.getId() ?? null);
        useEvent('common:model-file:move-modal:close');
    };
</script>

<template>
    <Modal name="common:model-file:move-modal">
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('common.model_file_move_modal.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <div
                    class="py-6"
                    v-if="loading"
                >
                    <div class="relative">
                        <Loader />
                    </div>
                </div>
                <div v-if="modelFile && !loading">
                    <select
                        :class="$theme('form.select', 'mb-6')"
                        v-model="modelFileFolder"
                    >
                        <option :value="null">
                            {{ $t('breadcrumbs.home') }}
                        </option>
                        <option
                            v-for="modelFileFolder in collection?.get()"
                            :value="modelFileFolder"
                            :key="modelFileFolder.getId()"
                        >
                            {{ modelFileFolder.title }}
                        </option>
                    </select>

                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('common:model-file:move-modal:close')"
                        >
                            {{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            @clicked="submit"
                        >
                            {{ $t('actions.confirm') }}
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </form>
    </Modal>
</template>
