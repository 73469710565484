import { Enum } from '~~/utils/enum';

export const Importable = {
    PRODUCT: 'products',
    CLIENT: 'clients',
    INVOICE: 'invoice',
    SUPPLIER: 'supplier',
} as const;

export type Importable = Enum<typeof Importable>;
