<script setup lang="ts">
    const props = defineProps({
        model: {
            required: true,
        },
        modalStyle: {
            type: String,
            default: 'centered',
        },
    });
    const modal = ref(null);
</script>

<template>
    <Modal
        :name="`${model.prefix}:modal`"
        :modal-style="modalStyle"
        ref="modal"
    >
        <slot></slot>
    </Modal>
</template>
