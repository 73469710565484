import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

interface LoginQrCodeSchemaAttributes {
    userId: number;
    expiresAt: string;
    isScanned: boolean;
}

type LoginQrCodeSchemaMeta = {
    qrCode?: string;
    token?: LoginQrCodeTokenMeta;
};
export type LoginQrCodeTokenMeta = {
    accessToken: string;
    tokenType: string;
    expiresAt: string;
};
type LoginQrCodeSchemaRelationships = {
    tenant: ToOneRelationship<ModelType.TENANTS>;
};

export default class LoginQrCodeSchema extends BaseSchema<LoginQrCodeSchemaAttributes, LoginQrCodeSchemaMeta, LoginQrCodeSchemaRelationships> {
    protected type: ModelType = ModelType.LOGIN_QR_CODES;
}
