import RawEndpoint from '@/app/base/endpoints/RawEndpoint';
import { JsonRequestPayload } from '@/app/base/endpoints/types';

export interface InvoicePaymentReminderSendAttributes {
    invoiceId: string;
    subject: string;
    text: string;
    to: string;
}

export default class InvoicePaymentReminderEndpoint extends RawEndpoint {
    path: string = 'tenant/invoicing/invoice-payment-reminders';

    async send(attributes: InvoicePaymentReminderSendAttributes): Promise<Response> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: '-actions/send',
            data: {
                data: {
                    type: 'invoice-payment-reminders',
                    attributes,
                },
            },
        };

        const response = await this.requestRaw(payload);
        return response;
    }
}
