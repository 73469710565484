import BaseCollection from '~~/app/base/collections/BaseCollection';
import BaseEndpoint from '~~/app/base/endpoints/BaseEndpoint';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import EndpointFactory from '~~/app/factories/EndpointFactory';
import { QueryParameter } from '~~/utils/reactiveQueryParameter';

export default async function useDeleteModel<M extends BaseModel>(model: M) {
    const endpoint = EndpointFactory.make(model.type);
    return await endpoint.destroy(model.getId());
}
