// Centre de recyclage inertes
// Centre de stockage amiante - ciment
// Déchèterie professionnelle
// Déchèterie publique ouverte au BTP
// Incinérateur ordures ménagères
// Installation de stockage de déchets dangereux
// Installation de stockage de déchets inertes
// Installation de stockage de déchets non dangereux
// Plateforme de regroupement bois
// Plateforme de regroupement déchets dangereux
// Plateforme de regroupement déchets inertes
// Plateforme de regroupement DEEE
// Plateforme de regroupement métaux
// Plateforme de regroupement papier - carton
// Plateforme de regroupement plastique
// Plateforme de regroupement verre
// Plateforme de regroupement verre et recyclage vers la filière verre
// Autre type de centre
enum WasteCenterType {
    RECYCLING_CENTER_INERT = 'recycling_center_inert',
    STOCKING_CENTER_ASBESTOS = 'stocking_center_asbestos',
    PROFESSIONAL_RECYCLING_CENTER = 'professional_recycling_center',
    PUBLIC_RECYCLING_CENTER = 'public_recycling_center',
    GARBAGE_INCINERATOR = 'garbage_incinerator',
    HAZARDOUS_WASTE_CENTER_FACILITY = 'hazardous_waste_center_facility',
    NON_HAZARDOUS_WASTE_CENTER_FACILITY = 'non_hazardous_waste_center_facility',
    INERT_WASTE_CENTER_FACILITY = 'inert_waste_center_facility',
    WOOD_GROUPING_PLATFORM = 'wood_grouping_platform',
    HAZARDOUS_GROUPING_PLATFORM = 'hazardous_grouping_platform',
    INERT_GROUPING_PLATFORM = 'inert_grouping_platform',
    // NON_HAZARDOUS_GROUPING_PLATFORM = 'non_hazardous_grouping_platform',
    DEEE_GROUPING_PLATFORM = 'dee_grouping_platform',
    METAL_GROUPING_PLATFORM = 'metal_grouping_platform',
    PAPER_CARDBOARD_GROUPING_PLATFORM = 'paper_cardboard_grouping_platform',
    PLASTIC_GROUPING_PLATFORM = 'plastic_grouping_platform',
    GLASS_GROUPING_PLATFORM = 'glass_grouping_platform',
    GLASS_AND_RECYCLING_GROUPING_PLATFORM = 'glass_and_recycling_grouping_platform',
    OTHER = 'other',
}

export default WasteCenterType;
