<script setup lang="ts">
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import ContactEndpoint from '~/app/contact/endpoints/ContactEndpoint';
    import EndpointFactory from '~/app/factories/EndpointFactory';

    const props = defineProps({
  vatNumber: null,
  contactType: null,
  email: null,
  id: null
});

    watch(
        () => props.vatNumber,
        () => checkIfExists('vatNumber')
    );
    watch(
        () => props.email,
        () => checkIfExists('email')
    );

    const existsWithEmail = ref(false);
    const existsWithVatNumber = ref(false);

    const checkIfExists = async (field: string) => {
        const endpoint = EndpointFactory.make(ModelType.CONTACTS) as ContactEndpoint;
        const response = await endpoint.index({
            filter: {
                contactType: props.contactType,
                [field]: props[field],
            },
        });

        if (field == 'email') {
            existsWithEmail.value = false;
        }
        if (field == 'vatNumber') {
            existsWithVatNumber.value = false;
        }

        if (!response.data || response.data.isEmpty()) {
            return;
        }

        if (props.id == response.data.first().getId()) {
            return;
        }

        if (field == 'email') {
            existsWithEmail.value = true;
        }

        if (field == 'vatNumber') {
            existsWithVatNumber.value = true;
        }
    };
</script>

<template>
    <div
        v-if="(email && existsWithEmail) || (vatNumber && existsWithVatNumber)"
        class="flex items-center p-4 mb-8 space-x-4 text-sm text-white bg-orange-600 rounded"
    >
        <div>
            <i class="text-2xl fa-regular fa-triangle-exclamation" />
        </div>
        <div>
            <span
                v-if="email && existsWithEmail"
                class="block"
                >{{ $t('contact.contact_form_modal.exists_with_email') }}</span
            >
            <span
                v-if="vatNumber && existsWithVatNumber"
                class="block"
                >{{ $t('contact.contact_form_modal.exists_with_vat_number') }}</span
            >
        </div>
    </div>
</template>
