<script setup lang="ts">
    import NotificationLog from '@/app/common/models/NotificationLog';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import NotificationLogEndpoint from '~~/app/common/endpoints/NotificationLogEndpoint';

    const { model } = useModelModal<NotificationLog>(NotificationLog);
    const loading: Ref<boolean> = ref(false);
    const notificationLog: Ref<NotificationLog | null> = ref(null);

    const retrieve = async (data: ModalPayload<NotificationLog>) => {
        // TODO?
    };
</script>

<template>
    <ModelModal
        :model="NotificationLog"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            v-if="!loading && model"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text', 'flex items-center gap-8')">
                    <span>
                        {{ $t(`misc.notifications_driver.${model.driver}`) }}
                    </span>
                    <CommonNotificationsLogsStatusBadge :notification-log="model" />
                </h2>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.subject')">
                    {{ model.subject ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.recipient')">
                    {{ model.recipient ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.createdAt')">
                    {{ model.createdAt.toShortDayDateTimeString() }}
                </InfoText>
                <InfoText
                    v-if="model.driver == 'sms'"
                    :label="$t('form.labels.text')"
                >
                    {{ model.content ?? '-' }}
                </InfoText>
            </div>
            <div
                v-if="model.driver == 'email' && model.failure"
                :class="$theme('modal.right_panel.section.container')"
            >
                <div class="p-4 text-red-900 bg-red-200 rounded">
                    <template v-if="typeof model.failure === 'object'">
                        <span
                            v-if="model.failure.name"
                            class="block"
                        >
                            {{ model.failure.name }}
                        </span>
                        <span
                            v-if="model.failure.description"
                            class="block text-sm"
                        >
                            {{ model.failure.description }}
                        </span>
                    </template>
                </div>
            </div>
            <div
                v-if="model.driver == 'email'"
                :class="$theme('modal.right_panel.section.container')"
            >
                <InfoText :label="$t('form.labels.preview')">
                    <div class="p-4 mb-8 border border-gray-200 rounded">
                        <iframe
                            :srcdoc="model.content"
                            class="w-full h-[1000px] pointer-events-none mb-8"
                        ></iframe>
                    </div>
                    <div class="flex items-center px-8 py-4 mb-8 space-x-8 border-l-8 border-orange-600 rounded-lg bg-zinc-100">
                        <div>
                            <i class="text-2xl text-orange-600 fa-regular fa-info-circle"></i>
                        </div>
                        <div>
                            <span class="block text-sm text-gray-600">
                                {{ $t('settings.outbox.email_preview_accuracy') }}
                                <!-- Note that clicking on the links <span class="font-bold">will trigger the tracking</span>. This means that you (and potentially the user in their app) might see the email as "opened" or "clicked" in the logs. -->
                            </span>
                        </div>
                    </div>
                </InfoText>
            </div>
        </div>
    </ModelModal>
</template>
