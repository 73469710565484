<script setup lang="ts">
    import CalendarEvent from '@/app/planning/models/CalendarEvent';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import CalendarEventEndpoint from '~~/app/planning/endpoints/CalendarEventEndpoint';

    useModelModal<CalendarEvent>(
        CalendarEvent,
        (data: ModalPayload<CalendarEvent>) => retrieve(data),
        () => (calendarEvent.value = null)
    );
    const loading: Ref<boolean> = ref(false);
    const calendarEvent: Ref<CalendarEvent | null> = ref(null);

    const retrieve = async (data: ModalPayload<CalendarEvent>) => {
        loading.value = true;
        const endpoint = EndpointFactory.make(data.model.type) as CalendarEventEndpoint;
        const response = await endpoint.retrieve(data.model.getId(), {
            include: `${endpoint.include},worksitePhase,worksitePhase.tasks,timeActivities,timeActivities.user,timeActivities.worksite,timeActivities.worksitePhase,timeActivities.task`,
        });
        calendarEvent.value = response.data;
        loading.value = false;
    };
</script>

<template>
    <ModelModal
        :model="CalendarEvent"
        modal-style="right_panel"
    >
        <ModalLoader v-if="loading" />
        <div
            v-if="!loading && calendarEvent"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container')">
                <h2 :class="$theme('modal.right_panel.title.text')">
                    {{ calendarEvent.title }}
                    <span
                        class="rounded-full h-[10px] w-[10px] inline-block ml-1"
                        :style="`background-color: ${calendarEvent.eventType.color()}`"
                    ></span>
                    <span class="block text-sm">
                        {{ calendarEvent.startsAt?.toDayDateString() }}
                        -
                        {{
                            $t('misc.time_range', {
                                time1: calendarEvent.startsAt?.toShortTimeString(),
                                time2: calendarEvent.endsAt?.toShortTimeString(),
                            })
                        }}
                    </span>
                </h2>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.eventType')">
                    {{ calendarEvent.eventType.title() }}
                </InfoText>
                <InfoText :label="$t('form.labels.users')">
                    {{ calendarEvent.users?.isNotEmpty() ? calendarEvent.users?.map((user) => user.displayName).join(', ') : '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.address')">
                    {{ calendarEvent.address?.display ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.notes')">
                    {{ calendarEvent.notes ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.contact')">
                    {{ calendarEvent.contact?.displayName ?? '-' }}
                </InfoText>
            </div>
            <div :class="$theme('modal.right_panel.title.container')">
                <div
                    v-if="!calendarEvent.timeActivities || calendarEvent.timeActivities?.isEmpty()"
                    class="flex items-center px-8 py-3 space-x-4 bg-white border border-gray-200 rounded"
                >
                    <div>
                        <div class="h-[60px] w-[60px]">
                            <Lottie json="https://shared-assets.trustup.io/lottie/planning-time-activity.json" />
                        </div>
                    </div>
                    <p>
                        {{ $t('planning.calendar_event.modal.no_time_activity') }}
                    </p>
                </div>

                <div
                    v-if="calendarEvent.timeActivities && calendarEvent.timeActivities.isNotEmpty()"
                    class="flex flex-col space-y-2"
                >
                    <PlanningTimeActivityItem
                        v-for="timeActivity in calendarEvent.timeActivities"
                        :key="timeActivity.getInternalId()"
                        :time-activity="timeActivity"
                    />
                </div>
            </div>

            <!-- Messaging -->
            <div :class="$theme('modal.right_panel.section.container', 'flex-1 flex flex-col')">
                <h2 :class="$theme('modal.right_panel.section.title')">
                    {{ $t('messaging.comments_title') }}
                </h2>
                <Messaging :model="calendarEvent" />
            </div>
            <!-- <div
                v-if="calendarEvent.worksite"
                :class="$theme('modal.right_panel.title.container')"
            >
                <InfoText :label="$t('form.labels.worksite')">
                    {{ calendarEvent.worksite.title ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.address')">
                    {{ calendarEvent.worksite.address?.display ?? '-' }}
                </InfoText>
                <InfoText :label="$t('form.labels.worksitePhase')">
                    {{ calendarEvent.worksitePhase?.title ?? '-' }}
                </InfoText>
                <InfoText
                    v-if="calendarEvent.worksitePhase?.tasks?.isNotEmpty()"
                    :label="$t('form.labels.tasks')"
                >
                    <div class="flex flex-col mt-2 space-y-2">
                        <TaskTaskItem
                            v-for="task in calendarEvent.worksitePhase.tasks"
                            :key="task.getInternalId()"
                            :task="task"
                        />
                    </div>
                </InfoText>
            </div> -->
        </div>
    </ModelModal>
</template>
