<script setup lang="ts">
    import { useToasteo } from '@deegital/vue-trustup-io-toasteo';
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';
    import InvoiceForm from '~/app/invoicing/classes/InvoiceForm';
    import InvoiceEndpoint from '~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~/app/invoicing/models/Invoice';
    import InvoiceSchema from '~/app/invoicing/schemas/InvoiceSchema';
    import WorksiteCollection from '~/app/worksite/collections/WorksiteCollection';
    import Worksite from '~/app/worksite/models/Worksite';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const invoice = ref<Invoice | null>(null);
    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;

    const form = useForm<{
        worksite: Worksite | null;
    }>({
        worksite: null,
    });

    useModal(
        'invoicing:invoice:link-to-worksite',
        async (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
        },
        () => {
            invoice.value = null;
            form.reset();
        }
    );

    const submit = async () => {
        const schema = new InvoiceSchema({ id: invoice.value?.getId() });
        schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());

        const response = await form.loadUntil(endpoint.setInclude('contact,worksite').update(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        useToasteoSuccess();
        useEvent('invoicing:invoice:link-to-worksite:close');
    };
</script>

<template>
    <Modal
        name="invoicing:invoice:link-to-worksite"
        ref="modal"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('invoicing.link_to_worksite_modal.title') }}
            </h2>
        </div>
        <div :class="$theme('modal.padding')">
            <form @submit.prevent="submit">
                <div class="mb-8">
                    <FormWorksiteSelect
                        :form="form"
                        input-name="worksite"
                        with-create-button
                    />
                </div>

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('invoicing:invoice:link-to-worksite:close')"
                    >
                        {{ $t('actions.cancel') }}
                    </LoadingButton>
                    <LoadingButton
                        :class-name="form.containsEmptyFields() ? $theme('button.style.disabled') : $theme('button.style.default')"
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        :disabled="form.containsEmptyFields()"
                        type="submit"
                    >
                        {{ $t('actions.link_to_worksite') }}
                    </LoadingButton>
                </div>
            </form>
        </div>
    </Modal>
</template>
