import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ProductCategoryRelationships = {};

type ProductCategoryAttributes = {
    title: string;
};

type ProductCategoryMeta = {};

export default class ProductCategorySchema extends BaseSchema<ProductCategoryAttributes, ProductCategoryMeta, ProductCategoryRelationships> {
    protected type: ModelType = ModelType.PRODUCT_CATEGORIES;
}
