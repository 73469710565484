import Form from '@/classes/Form';
import moment from '~~/utils/moment';
import { computed } from 'vue';

export default function (form: Form, startDateField: string, endDateField: string) {
    const formattedDuration = computed(() => {
        return useDateDiff(moment(form.get(startDateField)), moment(form.get(endDateField))).toTimeString();
    });

    const date = computed({
        get() {
            return moment(form.get(startDateField)).toDate();
        },
        set(value) {
            form.set(startDateField, moment(value).hour(8).startOf('hour').toDateTimeString());
            form.set(endDateField, moment(value).hour(16).startOf('hour').toDateTimeString());
        },
    });
    const startTime = computed({
        get() {
            let date = moment(form.get(startDateField));
            return {
                hours: date.hours(),
                minutes: date.minutes(),
                seconds: date.seconds(),
            };
        },
        set({ hours, minutes }: { hours: number; minutes: number }) {
            form.set(startDateField, moment(form.get(startDateField)).hour(hours).minute(minutes).toDateTimeString());

            if (moment(form.get(startDateField)).isAfter(form.get(endDateField))) {
                form.set(endDateField, moment(form.get(startDateField)).add(1, 'hour').toDateTimeString());
            }
        },
    });
    const endTime = computed({
        get() {
            let date = moment(form.get(endDateField));
            return {
                hours: date.hours(),
                minutes: date.minutes(),
                seconds: date.seconds(),
            };
        },
        set({ hours, minutes }: { hours: number; minutes: number }) {
            form.set(endDateField, moment(form.get(endDateField)).hour(hours).minute(minutes).toDateTimeString());
        },
    });

    return {
        date,
        startTime,
        endTime,
        formattedDuration,
    };
}
