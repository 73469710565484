import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoicingPaymentRemindersSettingAttributes = {
    level: number;
    dueDays: number;
    enabled: boolean;
    emailCopy: boolean;
    emailSubject: TranslatedField;
    emailText: TranslatedField;
};

type InvoicingPaymentRemindersSettingMeta = {};
type InvoicingPaymentRemindersSettingRelationships = {};

export default class InvoicingPaymentRemindersSettingSchema extends BaseSchema<
    InvoicingPaymentRemindersSettingAttributes,
    InvoicingPaymentRemindersSettingMeta,
    InvoicingPaymentRemindersSettingRelationships
> {
    protected type: ModelType = ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS;
}
