<template>
    <div>
        <Modal
            ref="modal"
            name="worksite:worksite-progress-billing:setting-modal"
        >
            <div
                :class="$theme('modal.title.container')"
                class="space-y-6"
            >
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('worksite_progress_billing.modal.lines.setting.title') }}
                </h2>
                <div class="px-8 mb-8 py-3 text-sm text-center text-neutral-500 bg-neutral-100 rounded">
                    <p>{{ $t('worksite_progress_billing.modal.lines.setting.text') }}</p>
                </div>
            </div>

            <form
                class="mb-4"
                @submit.prevent="submit"
            >
                <div
                    :class="$theme('modal.padding')"
                    class="space-y-6"
                >
                    <div class="w-full flex justify-center items-center">
                        <div class="text-neutral-500 font-thin flex relative h-[50px]">
                            <div class="flex items-center">
                                <!-- <div class=" flex items-center border border-gray-200 rounded py-3 px-6">
                                    <input type="number" v-model="quantity" :max="form.getLinesLeftQuantity()" :min="0"
                                        :step="0.01" class="rounded-l    min-w-[60px] xl:w-[80px] text-right  w-[60px]"
                                        :class="'bg-white'">
                                </div> -->
                                <WorksiteWorksiteProgressBillingItemSettingInputNumber
                                    :model-value="quantity"
                                    :max-quantity="leftQuantity"
                                    @inc="inc()"
                                    @dec="dec()"
                                    @update="(e) => updateQuantity(e)"
                                />
                            </div>

                            <div class="flex items-center">
                                <WorksiteWorksiteProgressBillingItemSettingCheckboxe
                                    v-for="(item, index) in items"
                                    :key="index"
                                    :item="item"
                                    @checked="(e) => toggleDisable(e)"
                                />
                            </div>
                        </div>
                    </div>

                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('worksite:worksite-progress-billing:setting-modal:close')"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class="isActive ? '' : 'cursor-not-allowed disabled:opacity-75'"
                            type="submit"
                        >
                            {{ $t('actions.save') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { UnwrapNestedRefs } from 'vue';
    import { useCounter } from '@vueuse/core';
    import WorksiteProgressBillingForm from '../../../app/worksite/classes/WorksiteProgressBillingForm';
    import WorksiteProgressBillingLineForm from '../../../app/worksite/classes/WorksiteProgressBillingLineForm';

    export interface SettingItem {
        title: string;
        checked: boolean;
        disabled: boolean;
    }
    const form = ref<WorksiteProgressBillingForm | null>(null);

    const items = reactive<SettingItem[]>([
        { title: '%', checked: false, disabled: false },
        { title: 'Unit', checked: false, disabled: false },
    ]);

    useModal('worksite:worksite-progress-billing:setting-modal', (payload: WorksiteProgressBillingForm) => {
        form.value = payload;
    });

    const isPercentage = computed(() => {
        const current = items.find((el: SettingItem) => el.title === '%') as SettingItem;
        return current.checked;
    });

    const leftQuantity = computed(() => {
        if (isPercentage.value) return 100;
        return form?.value?.getLinesLeftQuantity();
    });

    const { dec, inc, count: quantity } = useCounter(0, { min: 0 });

    const addUpQuantityOnLines = () => {
        form.value?.lines.forEach((line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
            if (!isPercentage.value) {
                if (line.isPercentage) handlePercentageLineAsUnit(line);
                if (!line.isPercentage) handleUnitLineAsUnit(line);
            }
            if (isPercentage.value) {
                if (line.isPercentage) handlePercentageLineAsPercentage(line);
                if (!line.isPercentage) handleUnitLineAsPercentage(line);
            }
        });
    };
    const { t } = useTranslation();
    const notify = (line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
        return useToasteoBasic(
            line.invoiceLine.title as string,
            `${t('worksite_progress_billing.modal.lines.setting.toast.unit_remaining', {
                unit: line.getLeftQuantity().toFixed(2),
            })}`
        );
    };
    const updateQuantity = (e: number) => {
        quantity.value = e;
    };

    const handlePercentageLineAsPercentage = (line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
        if (line.getLeftQuantity() === 0) return;
        if (line.getLeftQuantity() - quantity.value < 0) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        if (quantity.value > line.getLeftQuantity()) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        line.quantity = quantity.value;
    };

    const handleUnitLineAsPercentage = (line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
        if (line.getLeftQuantity() === 0) return;
        const percentageToUnit = (quantity.value / 100) * line?.invoiceLine?.quantity;
        if (line.getLeftQuantity() - percentageToUnit < 0) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        if (percentageToUnit > line.getLeftQuantity()) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        line.quantity = percentageToUnit;
    };

    const handlePercentageLineAsUnit = (line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
        if (line.getLeftQuantity() === 0) return;
        const quantityToPercentage = (quantity.value / line?.invoiceLine?.quantity) * 100;
        if (line.getLeftQuantity() - quantityToPercentage < 0) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        if (quantityToPercentage > line?.getLeftQuantity()) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        line.quantity = quantityToPercentage;
    };

    const handleUnitLineAsUnit = (line: UnwrapNestedRefs<WorksiteProgressBillingLineForm>) => {
        if (line.getLeftQuantity() === 0) return;
        if (line.getLeftQuantity() - quantity.value < 0) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        if (quantity.value > line.getLeftQuantity()) {
            line.quantity = line.getLeftQuantity();
            return notify(line);
        }
        line.quantity = quantity.value;
    };

    const isActive = computed(() => !!items.find((el: SettingItem) => el.checked === true));

    const toggleDisable = (item: SettingItem) => {
        const current = items.find((el: SettingItem) => el.title !== item.title) as SettingItem;
        if (quantity.value > leftQuantity.value) quantity.value = 0;
        current.disabled = !current.disabled;
    };

    const submit = async () => {
        addUpQuantityOnLines();
        useEvent('worksite:worksite-progress-billing:setting-modal:close');
        // useToasteoSuccess()
    };
</script>
