<script setup lang="ts">
    import Form from '@/classes/Form';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoiceTemplateSection from '@/app/invoicing/models/InvoiceTemplateSection';
    import InvoiceTemplateSectionColumn from '@/app/invoicing/models/InvoiceTemplateSectionColumn';
    import InvoiceTemplateSectionColumnSchema from '@/app/invoicing/schemas/InvoiceTemplateSectionColumnSchema';

    interface Fields {
        title: string;
        content: string;
        style: object;
        invoiceTemplateSection: InvoiceTemplateSection | null;
    }

    const form = useForm<Fields>({
        title: '',
        content: '',
        style: {
            textAlign: 'left',
            width: null,
            backgroundColor: '#FFF',
            textColor: '#1f2937',
            paddingHorizontal: 0,
            paddingVertical: 0,
            marginBottom: 0,
            borderWidth: 0,
            borderStyle: null,
            borderColor: null,
            borderRadius: 0,
        },
        invoiceTemplateSection: null,
    });

    const { modalName, model } = useModelFormModal<InvoiceTemplateSectionColumn, Fields>(
        InvoiceTemplateSectionColumn,
        async (payload?: FormModalPayload<InvoiceTemplateSectionColumn, Fields>) => {
            console.log(payload);
            if (payload?.model) {
                form.fillWithModel(payload.model);
            }
            if (payload?.fields?.invoiceTemplateSection) {
                form.set('invoiceTemplateSection', payload.fields.invoiceTemplateSection);
            }
            if (typeof form.get('style') !== 'object') {
                console.info('Test', form.get('style'), form.originalFields);
                form.resetField('style');
            }
        },
        () => {
            //
        }
    );

    const submit = async () => {
        const schema = InvoiceTemplateSectionColumnSchema.make({
            attributes: form.dataExcept(['invoiceTemplateSection']),
            id: model.value?.getId(),
        });
        if (form.get('invoiceTemplateSection')) {
            schema.addToOneRelationship('invoiceTemplateSection', ModelType.INVOICE_TEMPLATE_SECTIONS, form.get('invoiceTemplateSection')?.getId());
        }
        await form.loadUntil(useStoreOrUpdateModel(ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS, schema));
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoiceTemplateSectionColumn"
        @before-close="form.reset()"
        modal-style="right_panel"
        scrollable
    >
        <form
            :class="$theme('modal.right_panel.content')"
            @submit.prevent="submit"
        >
            <div :class="$theme('modal.right_panel.section.container', 'flex-1')">
                <FormInput
                    :form="form"
                    input-name="title"
                />
                <FormEditor
                    :form="form"
                    input-name="content"
                    with-template-variables
                />

                <FormInput
                    :form="form"
                    input-name="style.width"
                    input-type="number"
                    :min="0"
                    :max="9999"
                />
                <FormInput
                    :form="form"
                    input-name="style.backgroundColor"
                />
                <FormInput
                    :form="form"
                    input-name="style.textColor"
                />
                <FormInput
                    :form="form"
                    input-name="style.paddingHorizontal"
                    input-type="number"
                    :min="0"
                    :max="1000"
                />
                <FormInput
                    :form="form"
                    input-name="style.paddingVertical"
                    input-type="number"
                    :min="0"
                    :max="1000"
                />
                <FormInput
                    :form="form"
                    input-name="style.marginBottom"
                    input-type="number"
                    :min="0"
                    :max="1000"
                />
                <FormInput
                    :form="form"
                    input-name="style.borderWidth"
                    input-type="number"
                    :min="0"
                    :max="10"
                />
                <!-- <FormInput :form="form" input-name="style.borderStyle" input-type="number" :min="0" :max="10" /> -->
                <FormInput
                    :form="form"
                    input-name="style.borderColor"
                />
                <FormInput
                    :form="form"
                    input-name="style.borderRadius"
                    input-type="number"
                    :min="0"
                    :max="50"
                />
                <FormSelect
                    :form="form"
                    input-name="style.textAlign"
                >
                    <option
                        v-for="align in ['left', 'right', 'center']"
                        :key="align"
                        :value="align"
                    >
                        {{ $t(`align.${align}`) }}
                    </option>
                </FormSelect>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                    >
                        {{ $t('actions.cancel') }}
                    </LoadingButton>
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                    >
                        {{ $t('actions.save') }}
                    </LoadingButton>
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
