<template>
    <div>
        <Modal
            name="worksite:worksite-progress-billing:invoice-modal"
            ref="modal"
        >
            <template v-if="success">
                <ModalLottie
                    :title="$t('worksite_progress_billing.modal.invoicing.title.success')"
                    :subtitle="$t('worksite_progress_billing.modal.invoicing.text.success')"
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('worksite:worksite-progress-billing:invoice-modal:close')"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.white_border')"
                            @clicked="goToInvoice()"
                            >{{ $t('actions.open_invoice') }}</LoadingButton
                        >
                    </div>
                </ModalLottie>
            </template>

            <div v-if="!success">
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">
                        {{ `${$t('actions.billing')} ${worksiteProgressBilling?.number}` }}
                    </h2>
                </div>

                <form
                    @submit.prevent="submit"
                    class="mb-4"
                >
                    <div :class="$theme('modal.padding')">
                        <div class="flex flex-wrap mb-6">
                            <div class="w-full">
                                <label
                                    for="invoicing_title"
                                    class="block mb-1 text-sm font-normal text-gray-500 cursor-pointer"
                                >
                                    {{ $t('worksite_progress_billing.modal.invoicing.title') }}
                                    <!----></label
                                >
                            </div>
                            <div class="w-full md:flex-auto">
                                <input
                                    v-model="form.fields.invoiceTitle"
                                    id="wpbrvfm_email"
                                    :placeholder="$t('worksite_progress_billing.modal.invoicing.title')"
                                    type="text"
                                    class="w-full px-3 py-2 text-sm font-thin text-black placeholder-gray-500 bg-white border border-gray-300 rounded outline-none hover:border-gray-600 focus:border-primary max-w-input"
                                />
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-6">
                            <div class="w-full">
                                <label
                                    for="wpbrvfm_description"
                                    class="block mb-1 text-sm font-normal text-gray-500 cursor-pointer"
                                >
                                    {{ $t('worksite_progress_billing.modal.invoicing.description') }}
                                    <!----></label
                                >
                            </div>
                            <div class="w-full md:flex-auto">
                                <textarea
                                    v-model="form.fields.invoiceDescription"
                                    id="wpbrvfm_text"
                                    type="text"
                                    :placeholder="$t('worksite_progress_billing.modal.invoicing.description')"
                                    class="w-full h-32 px-3 py-2 text-sm font-thin text-black placeholder-gray-500 bg-white border border-gray-300 rounded outline-none hover:border-gray-600 focus:border-primary max-w-input"
                                ></textarea>
                                <!---->
                                <!---->
                            </div>
                        </div>
                        <div
                            class="flex flex-wrap mb-6"
                            defaultheight="h-32"
                        >
                            <div class="w-full">
                                <label
                                    for="wpbrvfm_text"
                                    class="block mb-1 text-sm font-normal text-gray-500 cursor-pointer"
                                >
                                    {{ $t('worksite_progress_billing.modal.invoicing.line') }}
                                    <!----></label
                                >
                            </div>

                            <div class="w-full md:flex-auto">
                                <input
                                    v-model="form.fields.invoiceLineTitle"
                                    id="wpbrvfm_description"
                                    :placeholder="$t('worksite_progress_billing.modal.invoicing.line')"
                                    type="text"
                                    class="w-full px-3 py-2 text-sm font-thin text-black placeholder-gray-500 bg-white border border-gray-300 rounded outline-none hover:border-gray-600 focus:border-primary max-w-input"
                                />
                                <!---->
                                <!---->
                            </div>
                        </div>

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('worksite:worksite-progress-billing:invoice-modal:close')"
                                >{{ $t('actions.cancel') }}</LoadingButton
                            >
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :loading="form.isLoading"
                                type="submit"
                            >
                                <i class="mr-2 fal fa-check"></i>
                                {{ $t('actions.billing') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import WorksiteProgressBillingForm from '../../../app/worksite/classes/WorksiteProgressBillingForm';
    import EndpointFactory from '../../../app/factories/EndpointFactory';
    import { ModelType } from '../../../app/base/schemas/BaseSchema';
    import WorksiteProgressBillingEndpoint from '../../../app/worksite/endpoints/WorskiteProgressBillingEndpoint';
    import WorksiteProgressBilling from '../../../app/worksite/models/WorksiteProgressBilling';

    const worksiteProgressBilling = ref<WorksiteProgressBilling | null>(null);
    export interface progressBillingTransformToInvoiceForm {
        invoiceTitle: string;
        invoiceDescription: string;
        invoiceLineTitle: string;
    }
    const form = useForm<{
        invoiceTitle: string;
        invoiceDescription: string;
        invoiceLineTitle: string;
    }>({
        invoiceTitle: '',
        invoiceDescription: '',
        invoiceLineTitle: '',
    });

    const success = ref(false);
    const { t } = useTranslation();
    useModal('worksite:worksite-progress-billing:invoice-modal', (payload: WorksiteProgressBilling) => {
        worksiteProgressBilling.value = payload;

        form.set('invoiceTitle', t('worksite_progress_billing.modal.invoicing.fields.worksite', { title: worksiteProgressBilling?.value?.quote?.title }));
        form.set('invoiceDescription', t('worksite_progress_billing.modal.invoicing.fields.quote', { quote: worksiteProgressBilling?.value?.quote?.number }));
        form.set('invoiceLineTitle', t('worksite_progress_billing.modal.invoicing.fields.progress_billing_invoice', { progress_billing: worksiteProgressBilling.value?.number }));
    });

    const goToInvoice = () => {
        worksiteProgressBilling.value?.invoice?.edit();
        useEvent('worksite:worksite-progress-billing:invoice-modal:close');
    };

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROGRESS_BILLINGS) as WorksiteProgressBillingEndpoint;

    const submit = async () => {
        const response = await form.loadUntil(endpoint.transformToInvoice(worksiteProgressBilling.value?.getId() as string, form.fields));
        if (response.error) return useToasteoError();
        useEvent('worksite:worksite-progress-billing:updated', response.data);
        success.value = true;

        useToasteoSuccess();
    };
</script>
