<script setup lang="ts">
    import WorksiteProgressBillingEndpoint from '../../../app/worksite/endpoints/WorskiteProgressBillingEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksiteProgressBilling from '~/app/worksite/models/WorksiteProgressBilling';

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROGRESS_BILLINGS) as WorksiteProgressBillingEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);
    const downloadButton = ref(null);
    const validateButton = ref(null);

    const destroy = async (model: WorksiteProgressBilling) => {
        await deleteButton.value?.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };

    const { downloadPdf, validate, transformToInvoice, loading } = useWorksiteProgressBillingActions();

    const downloadFile = async (model: WorksiteProgressBilling) => {
        await downloadButton.value?.loadUntil(onPromise(downloadPdf(model)));
    };

    const validateProgressBilling = async (model: WorksiteProgressBilling) => {
        await validateButton.value?.loadUntil(onPromise(validate(model)));
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="WorksiteProgressBilling"
    >
        <template v-if="!slotProps.model?.model">
            <div class="border-b pb-2">
                <a
                    v-if="slotProps.model?.invoice"
                    href="#"
                    :class="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => slotProps.model.invoice?.edit())"
                >
                    <i
                        class="fa-file-invoice"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{
                        $t('actions.open_document_type', {
                            documentType: $t('invoice_document_type.invoice_pronouns'),
                        })
                    }}</span>
                </a>
                <a
                    v-if="slotProps.model?.quote"
                    href="#"
                    :class="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => slotProps.model.quote?.edit())"
                >
                    <i
                        class="fa-file-invoice"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{
                        $t('actions.open_document_type', {
                            documentType: $t('invoice_document_type.quote_pronouns'),
                        })
                    }}</span>
                </a>
                <LoadingButton
                    ref="downloadButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="downloadFile(slotProps?.model)"
                >
                    <i
                        class="fa-download"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.download') }}</span>
                </LoadingButton>
            </div>
            <div
                v-if="
                    (slotProps.model?.status !== 'validated' && slotProps.model?.status !== 'validation_requested') ||
                    slotProps.model?.status === 'validation_requested' ||
                    (!slotProps.model?.invoicedAt && slotProps.model?.status === 'validated')
                "
                class="py-2 border-b"
            >
                <LoadingButton
                    v-if="slotProps.model?.status !== 'validated' && slotProps.model?.status !== 'validation_requested'"
                    ref="validateButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => validate(slotProps.model))"
                >
                    <i
                        class="fa-file-check"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.validate') }}</span>
                </LoadingButton>
                <LoadingButton
                    v-if="slotProps.model?.status === 'validation_requested'"
                    ref="validateButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="validateProgressBilling(slotProps.model)"
                >
                    <i
                        class="fa-file-check"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.validate') }}</span>
                </LoadingButton>

                <LoadingButton
                    v-if="!slotProps.model?.invoicedAt && slotProps.model?.status === 'validated'"
                    ref="fileInvoice"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => transformToInvoice(slotProps.model))"
                >
                    <i
                        class="fa-file-invoice-dollar"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.billing') }}</span>
                </LoadingButton>
            </div>
            <div class="pt-2">
                <a
                    href="#"
                    :class="$theme('contextmenu.link')"
                    @click.prevent="onAction(() => slotProps.model?.edit(slotProps.model.worksite))"
                >
                    <i :class="[$theme('contextmenu.icon'), slotProps.model.status === 'draft' ? 'fa-pencil' : 'fa-eye']"></i>
                    <span v-if="slotProps.model.status === 'draft'">{{ $t('actions.edit') }}</span>
                    <span v-if="slotProps.model.status !== 'draft'">{{ $t('actions.preview') }}</span>
                </a>
                <LoadingButton
                    v-if="slotProps.model?.status === 'draft'"
                    ref="deleteButton"
                    :class-name="$theme('contextmenu.link')"
                    @click.prevent="destroy(slotProps.model)"
                >
                    <i
                        class="fa-trash"
                        :class="$theme('contextmenu.icon')"
                    ></i>
                    <span>{{ $t('actions.delete') }}</span>
                </LoadingButton>
            </div>
        </template>
    </ModelContextMenu>
</template>
