<script setup lang="ts">
    import Source from '../../../app/crm/models/Source';
    import SourceEndpoint from '../../../app/crm/endpoints/SourceEndpoint';
    import SourceCollection from '../../../app/crm/collections/SourceCollection';
    import moment from '~~/utils/moment';
    import Lead from '@/app/crm/models/Lead';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import LeadSchema from '~~/app/crm/schemas/LeadSchema';
    import User from '~~/app/auth/models/User';
    import { useEvent } from '@/composables/useEventBus';
    import LeadEndpoint from '~~/app/crm/endpoints/LeadEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Contact from '~~/app/contact/models/Contact';

    interface Fields {
        title: string;
        contact: Contact | null;
        user: User | null;
        address: Address | null;
        source: Source | null;
    }

    const { authed } = useAuthStore();

    const form = useForm<Fields>(
        {
            title: '',
            contact: null,
            address: null,
            user: null,
            source: null,
        },
        (field: string, value: any) => {
            if (field === 'contact' && form.get('contact') && form.get('contact').address && !form.get('address')) {
                form.set('address', form.get('contact').address);
            }
        }
    );

    const { modalName, model } = useModelFormModal<Lead, Fields>(
        Lead,
        (payload?: FormModalPayload<Lead, Fields>) => onOpened(payload),
        () => {
            form.reset();
            if (form.get('contact')) setTitleBasedOnContactName();
            useIgnore('contact:contact:created');
            useIgnore('crm:source:created');
        }
    );

    // const { collection, load } = useCollectionLoader<Source, SourceCollection>(
    //   ModelType.INVOICES,
    //   [],
    //   () => {
    //     return {};
    //   },
    // );
    const { t } = useTranslation();

    const setTitleBasedOnContactName = () => {
        if (form.get('title')) {
            return;
        }
        form.set(
            'title',
            t('crm.lead.opportunity_form.title', {
                clientName: `${form.fields?.contact?.displayName || '-'}`,
            })
        );
    };
    const loading = ref<boolean>(false);
    const onOpened = async (payload?: FormModalPayload<Lead, Fields>) => {
        loading.value = true;

        fillForm(payload);
        useListen('contact:contact:created', (contact) => {
            form.set('contact', contact);
            setTitleBasedOnContactName();
        });
        useListen('crm:source:created', (source) => {
            form.set('source', source);
        });
        loading.value = false;
    };

    const fillForm = (payload?: FormModalPayload<Lead, Fields>) => {
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
                contact: payload?.model.contact,
                address: payload?.model.address,
                user: payload?.model.user,
                source: payload?.model.source,
            });
        }

        if (payload?.fields) {
            if (!payload.fields.address && payload.fields.contact) {
                payload.fields.address = payload.fields.contact.address;
            }

            form.fill(payload.fields);

            if (payload.fields.contact) {
                setTitleBasedOnContactName();
            }
        }

        if (!form.get('user')) {
            form.set('user', authed as User);
        }
    };

    const submit = async () => {
        const schema = LeadSchema.make({
            attributes: form.dataExcept(['user', 'contact', 'source']),
        });
        if (form.get('user')) {
            schema.addToOneRelationship('user', ModelType.USERS, form.get('user').getId());
        }
        if (form.get('contact')) {
            schema.addToOneRelationship('contact', ModelType.CONTACTS, form.get('contact').getId());
        }
        if (form.get('source')) {
            schema.addToOneRelationship('source', ModelType.SOURCES, form.get('source').getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        } else {
            schema.attributes.addedAt = moment().toUtcDateTimeString();
        }

        const endpoint: LeadEndpoint = EndpointFactory.make(ModelType.LEADS) as LeadEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="Lead"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('crm.lead.edit_form.title') : $t('crm.lead.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormContactSelect
                    :form="form"
                    input-name="contact"
                    :label="$t('form.labels.client')"
                    with-create-button
                    contact-type="client"
                    @selected="(contact) => setTitleBasedOnContactName()"
                />
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <FormAddressAutocomplete
                    :form="form"
                    input-name="address"
                />
                <FormUserSelect
                    :form="form"
                    input-name="user"
                />
                <FormSourceSelect
                    :form="form"
                    :sources="sources"
                    input-name="source"
                />
            </div>

            <div :class="$theme('modal.footer.container')">
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
