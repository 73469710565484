<script setup lang="ts">
    import { useAuthStore } from '../../stores/authStore';
    import LoginQrCodeEndpoint from '~~/app/auth/endpoints/LoginQrCodeEndpoint';
    import LoginQrCode from '~~/app/auth/models/LoginQrCode';
    import LoginQrCodeSchema from '~~/app/auth/schemas/LoginQrCodeSchema';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';

    const { $websocket } = useNuxtApp();
    const { authed, tenant } = useAuthStore();

    $websocket.private(tenant!.websocketChannel).listen('.auth:login-qr-code:scanned', (e) => {
        if (e && e.loginQrCode && loginQrCode.value?.getId() == e.loginQrCode) {
            scanned();
        }
    });

    useModal(
        'auth:login-qrcode-modal',
        () => retrieve(),
        () => {
            success.value = false;
            loading.value = true;
        }
    );

    const loginQrCode = reactiveModel<LoginQrCode>(null);
    const success = ref(false);
    const loading = ref(true);
    const { isImpersonated } = useAuthStore();
    const retrieve = async () => {
        loading.value = true;

        const endpoint = EndpointFactory.make(ModelType.LOGIN_QR_CODES) as LoginQrCodeEndpoint;
        const schema = new LoginQrCodeSchema({
            attributes: { userId: parseInt(authed!.getId()), isImpersonated },
        });
        schema.addToOneRelationship('tenant', ModelType.TENANTS, tenant!.getId());
        const response = await endpoint.store(schema);
        loginQrCode.value = response.data;

        loading.value = false;
    };

    const scanned = async () => {
        success.value = true;

        await wait(10000);
        useEvent('auth:login-qrcode-modal:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="auth:login-qrcode-modal"
        :width="1000"
    >
        <div class="p-20">
            <ModalLoader v-if="loading" />
            <template v-if="!loading && loginQrCode">
                <div class="flex items-center space-x-12">
                    <div class="flex-shrink-0 w-[200px] relative h-[200px]">
                        <transition
                            :leave-active-class="$theme('modal.centered.transition.leave-active-class')"
                            :leave-from-class="$theme('modal.centered.transition.leave-from-class')"
                            :leave-to-class="$theme('modal.centered.transition.leave-to-class')"
                        >
                            <div
                                v-if="!success"
                                class="absolute inset-0 flex items-center justify-center"
                            >
                                <img
                                    :src="loginQrCode.qrCode"
                                    class="w-full"
                                />
                            </div>
                        </transition>
                        <div
                            v-if="success"
                            class="absolute inset-0 flex items-center justify-center"
                        >
                            <div class="relative">
                                <Lottie json="https://shared-assets.trustup.io/lottie/success2.json" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <template v-if="success">
                            <h1 class="mb-4 text-3xl text-gray-800">
                                {{ $t('login_qr_code_modal.success.title') }}
                            </h1>
                            <p class="text-lg text-gray-600">
                                {{ $t('login_qr_code_modal.success.text') }}
                            </p>
                        </template>
                        <template v-else>
                            <h1 class="mb-4 text-3xl text-gray-800">
                                {{ $t('login_qr_code_modal.title') }}
                            </h1>
                            <p class="text-lg text-gray-600">
                                {{ $t('login_qr_code_modal.text') }}
                            </p>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </Modal>
</template>
