import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WorksiteProfitabiltiyDocumentRelationships = {};

type WorksiteProfitabiltiyDocumentAttributes = {
    title: string;
    documentType: string;
    amount: number;
    status: string;
};

type WorksiteProfitabiltiyDocumentMeta = {};

export default class WorksiteProfitabiltiyDocumentSchema extends BaseSchema<WorksiteProfitabiltiyDocumentAttributes, WorksiteProfitabiltiyDocumentMeta, WorksiteProfitabiltiyDocumentRelationships> {
    protected type: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
}
