<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';
    import { InvoiceNotifyFormCreditsInterface } from '~/app/invoicing/endpoints/types';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const credits = ref<InvoiceNotifyFormCreditsInterface | null>(null);
    const loading = ref(true);
    const success = ref(false);
    const sending = ref(false);
    const error = ref(false);
    const form = useForm<{
        to: string;
        text: string;
    }>({
        to: '',
        text: '',
    });

    useModal(
        'invoicing:invoice:send-sms',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            form.set('to', invoice.value.contact?.phone);
            retrieveForm();
        },
        () => {
            form.reset();
            error.value = false;
            success.value = false;
            sending.value = false;
        }
    );

    const retrieveForm = async () => {
        loading.value = true;
        const formData = await endpoint.notifyForm(invoice.value!.getId(), 'sms');
        form.set('text', formData.text);
        credits.value = formData.credits;
        loading.value = false;
    };

    const disabled = computed(() => {
        if (!credits.value?.enough) {
            return true;
        }
        if (form.containsEmptyFields()) {
            return true;
        }
        if (textIsTooLong.value) {
            return true;
        }

        return false;
    });
    const textIsTooLong = computed(() => {
        return form.get('text').length > 140;
    });

    const submit = async () => {
        if (!credits.value?.enough) {
            throw new Error('Not enough credits to send the sms');
        }

        sending.value = true;

        const response = await form.loadUntil(
            endpoint.notify(invoice.value!.getId(), 'sms', {
                text: form.get('text'),
                to: form.get('to'),
            }),
            2000
        );
        const { $mixpanel } = useNuxtApp();

        $mixpanel.track(`invoicing_invoices_${invoice.value?.documentType}_sent`);
        sending.value = false;

        if (response.error) {
            if (response.validationErrors) {
                sending.value = false;
                return;
            }
            error.value = true;
            return;
        }

        useEvent('invoicing:invoice:notified', 'sms');
        success.value = true;
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:send-sms"
    >
        <div class="relative">
            <ModalLoader v-if="loading" />
            <template v-else>
                <template v-if="error">
                    <ModalLottie
                        :title="$t('invoicing.invoice.send_modal.error.title')"
                        :subtitle="$t('invoicing.invoice.send_modal.error.text')"
                        json="https://shared-assets.trustup.io/lottie/error.json"
                    >
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:send-sms:close')"
                            >
                                {{ $t('actions.close') }}
                            </LoadingButton>
                        </div>
                    </ModalLottie>
                </template>
                <template v-if="success">
                    <ModalLottie :title="$t('invoicing.invoice.send_modal_sms.success.title')">
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:send-sms:close')"
                            >
                                {{ $t('actions.close') }}
                            </LoadingButton>
                        </div>
                    </ModalLottie>
                </template>
                <template v-if="sending">
                    <ModalLottie
                        :title="$t('invoicing.invoice.send_modal.sending.title')"
                        json="https://shared-assets.trustup.io/lottie/send.json"
                    >
                    </ModalLottie>
                </template>
                <template v-if="!success && !sending && !error">
                    <form @submit.prevent="submit">
                        <div class="p-8">
                            <div class="flex items-center w-full py-2 space-x-4 border-b border-gray-200">
                                <label class="inline-block text-sm font-thin text-gray-800 whitespace-nowrap">
                                    {{ $t('form.labels.recipient') }}
                                </label>
                                <div class="flex-1 pr-10">
                                    <FormPhoneInput
                                        :form="form"
                                        input-name="to"
                                        :placeholder="$t('form.labels.recipient')"
                                        without-label
                                        element-class-name=" "
                                        class-name="w-full p-2 text-sm text-gray-600 outline-none focus:text-black"
                                    />
                                </div>
                            </div>
                            <div class="py-4">
                                <FormTextarea
                                    :form="form"
                                    element-class-name="w-full"
                                    input-name="text"
                                    class-name="w-full text-gray-600 text-sm font-thin focus:text-black outline-none h-[60px]"
                                    with-variables
                                >
                                    <template
                                        v-if="textIsTooLong"
                                        #after-input
                                    >
                                        <p class="-mt-1 text-xs text-pastel-red">
                                            {{ $t('misc.sms_text_too_long', { count: form.get('text').length }) }}
                                        </p>
                                    </template>
                                </FormTextarea>
                            </div>
                            <InvoicingInvoiceNotifyCreditsAlert
                                :credits="credits"
                                class="mb-8"
                            />
                            <div :class="$theme('modal.footer.button_container')">
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :class-name="$theme('button.style.cancel')"
                                    prevent-default
                                    @clicked="useEvent('invoicing:invoice:send-sms:close')"
                                    >{{ $t('actions.cancel') }}</LoadingButton
                                >
                                <LoadingButton
                                    :class-name="disabled ? $theme('button.style.disabled') : $theme('button.style.default')"
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :loading="form.isLoading"
                                    type="submit"
                                    :disabled="disabled"
                                >
                                    <i class="mr-2 fa-solid fa-paper-plane"></i>
                                    {{ $t('actions.send') }}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </template>
            </template>
        </div>
    </Modal>
</template>
