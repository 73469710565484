<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import Notification from '@/app/common/models/Notification';
    import NotificationCollection from '~/app/common/collections/NotificationCollection';

    const endpoint = EndpointFactory.make(ModelType.NOTIFICATIONS);
    const unreadCount = ref(0);
    const fetchCount = async () => {
        const count = await endpoint.getUnreadCount();
        if (!count) return;
        unreadCount.value = count;
    };
    const hasUnreadNotifications = computed(() => !!unreadCount.value);
    fetchCount();

    const submenu = ref<SubmenuInterface | null>();
    const { load, loading, collection } = useCollectionLoader<Notification, NotificationCollection>(ModelType.NOTIFICATIONS, [], () => ({ page: { number: 1, size: 50 } }));
    const open = async () => {
        collection.value = null;
        submenu.value?.show();
        await load();
        endpoint.markAsRead();
        unreadCount.value = 0;
    };

    const { getValue: getAssignedFilter, setValue: setAssignedFilter } = useLocalStorage<boolean>('notifications-assigned-filter');
    const assignedFilter = ref(getAssignedFilter() || false);
    watch(assignedFilter, (value) => {
        setAssignedFilter(value);
        load();
        fetchCount();
    });

    const { t } = useTranslation();
</script>

<template>
    <Submenu
        ref="submenu"
        position="right-0"
    >
        <template #default>
            <button
                class="relative flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full"
                @click="open"
            >
                <div v-if="hasUnreadNotifications">
                    <div class="absolute top-0 right-0 w-3 h-3 rounded-full opacity-50 bg-pastel-red animate-ping"></div>
                    <div class="absolute top-0 right-0 w-3 h-3 rounded-full bg-pastel-red"></div>
                </div>
                <i class="text-gray-600 fa-solid fa-bell"></i>
            </button>
        </template>
        <template #submenu>
            <div class="w-full sm:min-w-[400px]">
                <div
                    v-if="loading"
                    class="relative"
                >
                    <div class="py-16">
                        <Loader />
                    </div>
                </div>
                <div v-else>
                    <div class="flex justify-between py-2 pl-4 pr-2">
                        <p>
                            {{ $t('header_notifications.title') }}
                        </p>
                        <div
                            :data-tooltip="assignedFilter ? $t('header_notifications.display_system_notifications') : $t('header_notifications.display_user_notifications')"
                            data-tooltip-location="left"
                            class="text-gray-400 transition-all cursor-pointer hover:text-black"
                            @click.prevent="assignedFilter = !assignedFilter"
                        >
                            <i
                                class="fa-regular"
                                :class="assignedFilter ? 'fa-gear' : 'fa-user-check'"
                            ></i>
                        </div>
                    </div>
                    <div :class="$theme('submenu.items_container')">
                        <div :class="$theme('submenu.items')">
                            <SubmenuEmptyContent v-if="collection?.isEmpty()" />
                            <template v-else>
                                <LayoutHeaderNotificationsItem
                                    v-for="notification in collection?.get()"
                                    :key="notification.getInternalId()"
                                    :notification="notification"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
