<script setup lang="ts">
    const $emit = defineEmits(["before-open", "opened", "before-close", "closed"]);

    const props = defineProps({
        model: {
            required: true,
        },
        modalStyle: {
            type: String,
            default: 'centered',
        },
        scrollable: {
            required: false,
            type: Boolean,
            default: false,
        },
    });

    const modal = ref(null);
</script>

<template>
    <Modal
        ref="modal"
        :name="`${model.prefix}:form-modal`"
        :scrollable="scrollable"
        :modal-style="modalStyle"
        @before-open="$emit('before-open')"
        @opened="$emit('opened')"
        @before-close="$emit('before-close')"
        @closed="$emit('closed')"
    >
        <slot></slot>
    </Modal>
</template>
